import React from 'react';
import { makeStyles } from '@material-ui/styles';
import birdsOfParadiseHomeLogo from "../static/images/Birds_of_Paradise_home_logo.png"
import birdsOfParadiseHomeLogoText from "../static/images/Birds_of_Paradise_homo_logo_text.png"
import homeFirstImg from "../static/images/homeFirstImg.jpg"
import homeSecondImg from "../static/images/homePage_secondContent.jpg"
import { Grid, Container, Typography, Avatar, useMediaQuery, Card } from '@material-ui/core';
import Banner from './../components/Banner/Banner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  image: {
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
}))

function Home() {
  const classes = useStyles();
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation()
  return (
    <>
      <Container>
        <Grid
          container
          height={smDown && '90vh'}
          data-aos='fade'
        >
          <Grid item style={{ justifyContent: 'center', marginBottom: '20px' }}>
            <img
              alt='birds-of-paradise-home-logo'
              src={birdsOfParadiseHomeLogo}
              style={{ width: smDown ? '90%' : mdDown ? '50%' : '38%', height: 'auto' }}
              className={classes.image}
            />
          </Grid>
          <Grid item>
            <img
              alt='birds-of-paradise-home-logo-text'
              src={birdsOfParadiseHomeLogoText}
              style={{ width: smDown ? '90%' : mdDown ? '50%' : '45%', height: 'auto' }}
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          mt={{ lg: 30, sm: 20, xs: 10 }}
          display="flex"
          spacing={2}
          mb={30}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
            data-aos={!mdDown && 'fade-right'}
          >
            <Avatar
              src={homeFirstImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            data-aos={!mdDown && 'fade-left'}
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                Birds of Paradise
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('home1')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container>
        <Banner
          background='-webkit-linear-gradient(60deg, rgba(31,29,66,1) 0%, rgba(52,44,93,1) 30%, rgba(149,67,108,1) 70%, rgba(192,160,102,1) 100%)'
          marginBottom={10}
          style={{ height: '30vh' }}
        >
          <Container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display='flex'
              justifyContent="center"
              alignItems='center'
              marginBottom={0}>
              <Typography
                variant='h1'
                fontWeight='bold'
                color="#f6f2ef"
                textAlign="center"
              >
                See You on the Mat!
              </Typography>
            </Grid>
          </Container>
        </Banner>
      </Grid>
      <Container>
        <Grid item
          display='flex'
          justifyContent="center"
          alignItems='center'
        >
          <Card
            sx={{
              width: '100%',
              aspectRatio: '16/9',
              minHeight: '500px',
            }}
          >
            <iframe title="virtualTour"
              src="
          https://www.google.com/maps/embed?pb=!4v1713515116566!6m8!1m7!1sCAoSLEFGMVFpcE1wNk85MXZBc2VlSThWaG9la1JHTmJBY1pVU3I1QUNwZHlkd2lP!2m2!1d47.5075683!2d19.0289683!3f356.5486829431694!4f-0.9205160672646002!5f0.7820865974627469
          "
              width="100%"
              // height="800"
              style={{ border: 0, aspectRatio: '16/9', minHeight: '500px', }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </Card>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
          data-aos={!mdDown && 'fade-up'}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={homeSecondImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t('typesOfClasses')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('home2')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container >
    </>
  )
}

export default Home;