import React from 'react';
import { Grid, Typography, Avatar, Container, useMediaQuery } from '@material-ui/core';
import firstImg from "../static/images/firstImg.jpg";
import waterImg from "../static/images/waterImg.jpg";
import breakfast from "../static/images/breakfast.jpg";
import dressImg from "../static/images/dressImg.jpg";
import towel from "../static/images/towel.jpg";
import prepareImg from "../static/images/prepareImg.jpg";
import focusImg from "../static/images/focusImg.jpg";
import Birds_of_Paradise_design from '../static/images/Birds_of_Paradise_design.png';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  image: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: 'auto',
  },
}))

function Timesheet(props) {
  const { t } = useTranslation()
  const classes = useStyles();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <Container maxWidth="100%"
        sx={{
          background: '#f6f2ef',
          py: 3,
        }}>
        <Grid
          container
          // display="flex"
          // flexDirection="row"
          mt={5}
          data-aos='fade'
          alignItems="center"
        >
          <Grid
            item
            style={{ marginBottom: '50px' }}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              alt='birds-of-paradise-home-logo'
              src={Birds_of_Paradise_design}
              className={classes.image}
              style={{ width: lgDown ? '50%' : '60%', height: 'auto' }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
          // mt={37}
          // mt={{ lg: 5, md: 0 }}
          >
            <Typography
              variant="h2"
              color="#95436c"
            >
              {/* 7 napos próbabérlettel */}
              {t("intro1")}
            </Typography>
            <Typography variant="h6" style={{ marginTop: 35 }}>
              {t("intro2")}
              {/* Ha először jársz nálunk, hét napos próbabérletünkkel korlátlanul látogathatod stúdiónkat, kipróbálhatod jóga óráinkat, ismerkedhetsz oktatóinkkal. Nincs előre bejelentkezés, bármelyik órán részt vehetsz függetlenül attól, hogy jógáztál-e már vagy sem. Mert tényleg hisszük, hogy a jóga első látásra szerelem. */}
            </Typography>
            <Typography variant="h6" style={{ marginTop: 35 }}>
              {t("intro3")}
              {/* Próbabérletünk ára: 5000 Ft, hét napig érvényes. */}
            </Typography>
            <Typography variant="h6" style={{ marginTop: 35 }}>
              {t("intro4")}
              {/* Ezzel a lehetőséggel újrakezdéskor már nem tudsz élni. */}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          mt={{ lg: 30, sm: 20, xs: 10 }}
          display="flex"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={firstImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro5")}
              </Typography>
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
                style={{ marginTop: '15px' }}
              >
                {t("intro6")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t("intro7")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={waterImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro8")}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t("intro9")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={breakfast}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro10")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t("intro11")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={dressImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro12")}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t("intro13")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={towel}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro14")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t("intro15")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={prepareImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro16")}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t("intro17")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={focusImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t("intro18")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {t("intro19")}
              </Typography>
              <Typography variant="h6">
                {t("intro20")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Timesheet
