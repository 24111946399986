import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from "leaflet";

export default function Map() {
  return (
    <MapContainer
      center={[47.5076883, 19.0282858]}
      zoom={25}
      minZoom={3}
      scrollWheelZoom
      style={{
        minHeight: 300,
        height: '100%',
        width: '100%',
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
      // position={[47.5076320, 19.0285415]}
      position={[47.5076080, 19.0287146]}
      >
        <Popup>
          Birds of Paradise Kft.
        </Popup>
      </Marker>
    </MapContainer>
  );
};
