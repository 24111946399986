import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // minWidth: '100%',
    //border: '2px dashed red',
  },
}));

export default function Banner(props) {
  const classes = useStyles();


  return (
    <Box
      {...props}
      height={props.height} className={classes.banner}
      minWidth={props.minWidth ? props.minWidth : '100%'}
      sx={{
        background: props.background,
        height: props.bannerHeight,
        width: props.bannerWidth,
        //py: 20
      }}
    >
      {props.children}
    </Box>
  );
}
