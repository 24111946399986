import { Tooltip, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import theme from '../../theme/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledTableCell = withStyles({
  head: {
    // backgroundColor: theme.palette.default.light,
    // color: theme.palette.common.white,
    fontSize: 19,
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
  },
  body: {
    color: theme.palette.default.main,
    fontSize: 14,
    border: `1px solid ${theme.palette.default.dark}`,
  },
})(TableCell);

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: '#fbfbfb',
    },
  },
})(TableRow);

const useStyles = makeStyles({
  table: {
    //minWidth: 700,
  },
});

export default function BasicTable(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:900px)');
  const [maxLength, setMaxLength] = useState(0);

  return (
    <TableContainer
      // component={Paper}
      style={{ overflow: props.stickyHeader ? 'initial' : 'auto' }}
    >
      <Table
        className={classes.table}
        aria-label="sticky table"
        style={{ borderCollapse: 'inherit', }}
        stickyHeader={props.stickyHeader}
      //size="small"
      >
        <TableHead>
          <TableRow>
            {!matches && props.respMobile && props.stickyHeader ?
              <React.Fragment>
                {props && props.columns && props.columns.slice(1).map((column, index) => (
                  <StyledTableCell key={index} align={column.align} style={{ background: column.background }} ><Typography paddingTop='80px'>{column.title}</Typography></StyledTableCell>
                ))}
              </React.Fragment>
              :
              <React.Fragment>
                {props && props.columns && props.columns.map((column, index) => (
                  <StyledTableCell key={index} align={column.align} style={{ background: column.background }} ><Typography paddingTop='80px'>{column.title}</Typography></StyledTableCell>
                ))}
              </React.Fragment>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {props && props.rows && props.rows.map((row, index) => (
            <React.Fragment key={index}>
              {!matches && props.respMobile &&
                <React.Fragment>
                  <StyledTableRow>
                    {maxLength < row.details.length ? setMaxLength(row.details.length) : null}
                    <StyledTableCell
                      align="center"
                      // colSpan={maxLength / row.details.length}
                      colSpan={maxLength}
                    >
                      {row.name}
                    </StyledTableCell>
                  </StyledTableRow>
              </React.Fragment>
              }
              <StyledTableRow key={row.name}>
                {matches && props.respMobile &&
                  <React.Fragment>
                    {row.name && <Tooltip title={row.tooltip == null ? "" : row.tooltip} arrow placement="top">
                      <StyledTableCell
                        align="left"
                      >
                        <Typography color='black' variant='subtitle2'>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>}
                  </React.Fragment>
                }
                {row.name && !props.respMobile &&
                  <React.Fragment>
                    {row.name && <Tooltip title={row.tooltip == null ? "" : row.tooltip} arrow placement="top">
                      <StyledTableCell
                        align="left"
                      >
                        <Typography color='black' variant='subtitle2'>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>}
                  </React.Fragment>
                }
                {maxLength < row.details.length ? setMaxLength(row.details.length) : null}
                {row.details.map((detail, index) => (
                  <StyledTableCell
                    key={index}
                    align="center"
                    colSpan={maxLength / row.details.length}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {detail}
                    {detail === true && <CheckIcon />}
                    {detail === false && <RemoveIcon />}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}
