import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';
import BasicTable from '../../components/BasicTable/BasicTable';


const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '50px',
    fontWeight: 'bold',
    color: '#000'
  },
  subTitle: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '50px',
    fontWeight: 'bold',
  },
  subTitle2: {
    textAlign: 'left',
    marginTop: '40px',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'justify',
    marginBottom: '30px',
    marginTop: '30px'
  },
  button: {
    margin: '20px',
    textTransform: 'none',
    cursor: 'pointer'
  }
})

const definitionTableColumns = [
  { title: 'Fogalom meghatározása', variant: 'h7', align: 'left' },
  { title: 'Magyarázó és jellemző példa', variant: 'h7', align: 'right' }
]

const definitionTableRows = [
  {
    details: ['Adatkezelő: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja;', 'Lásd a 2. fejezetben meghatározottakat. Adatkezelő a (regisztráló) felhasználó és a már regisztrált felhasználók vonatkozásában az AOC System Zrt.']
  },
  {
    details: ['Felhasználó: az a természetes személy, aki az AOC System Zrt. valamely számítógépes vagy számítógép-hálózati szolgáltatását használja, de nem vált regisztrált felhasználóvá;', 'AOC System Zrt. valamely weboldalát böngésző internetező, elektronikus üzenetet küldő személy stb.']
  },
  {
    details: ['Regisztrált felhasználó: olyan felhasználó, aki bizonyítható módon elfogadta/tudomásul vette az AOC System Zrt. általános szerződési feltételeit, regisztráció során megjelenített adatvédelmi szabályzatát, adatfeldolgozási megállapodását, és adatait az AOC System rendszerbe történő regisztráció céljából az AOC System Zrt. által üzemeltetett weboldalon keresztül az AOC System Zrt. számára eljuttatta, és a regisztrációt határidőn belül megerősítette;', 'Pl. egy alkusz, aki az AOC System rendszerbe regisztrált']
  },
  {
    details: ['Érintett: bármely információ alapján azonosított vagy – közvetlenül vagy közvetve – azonosítható természetes személy;', 'Minden természetes személy, akinek adatát az AOC System Zrt. adatkezelőként vagy adatfeldolgozóként kezeli, pl. regisztrált felhasználók, Munkatársak. Az érintett fogalma minden egyes adatkezelés esetében meghatározásra került az adott adatkezelés leírásában.']
  },
  {
    details: ['Személyes adat fogalma: az érintettre vonatkozó bármely információ', 'Pl. név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező, így ezek személyes adat a telefonszám, e-mail cím, anyja neve, IP cím, bankszámlaszám stb. Az egyes adatkezelés során a kezelt személyes adatok körei (legalább hivatkozással) meghatározásra kerültek.']
  },
  {
    details: ['Különleges adat: a személyes adatok különleges kategóriáiba tartozó minden adat, azaz a faji vagy etnikai származásra, politikai véleményre, vallási vagy világnézeti meggyőződésre vagy szakszervezeti tagságra utaló személyes adatok, valamint a genetikai adatok, a természetes személyek egyedi azonosítását célzó biometrikus adatok, az egészségügyi adatok és a természetes személyek szexuális életére vagy szexuális irányultságára vonatkozó személyes adatok', 'Pl. egészségügyi adat']
  },
  {
    details: ['Iroda/Székhely:', 'A természetben a 1062 Budapest, Délibáb u. 8.  alatt található ingatlan.']
  },
  {
    details: ['Hozzájárulás: az érintett akaratának önkéntes, kifejezett és határozott kinyilvánítása, amely megfelelő tájékoztatáson alapul, és amellyel félreérthetetlen beleegyezését adja a rá vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő – kezeléséhez, így tehát a hozzájárulásnak 3 alapeleme van: az önkéntesség, a határozottság és a megfelelő tájékozottság', 'A gyakorlatban ilyen valamely adatkezeléshez hozzájáruló nyilatkozat']
  },
  {
    details: ['Adatkezelés: a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés.', 'Adatkezelési művelet vagy tevékenység, pl. adatok rögzítése az AOC System rendszerbe) egy másik adatkezeléstől a célja alapján válik el. Pl. egyszeri információkérés, adatrögzítés, -módosítás, törlés stb.']
  },
  {
    details: ['Profilalkotás: személyes adatok automatizált kezelésének bármely olyan formája, amelynek során a személyes adatokat valamely természetes személyhez fűződő bizonyos személyes jellemzők értékelésére, különösen a munkahelyi teljesítményhez, gazdasági helyzethez, egészségi állapothoz, személyes preferenciákhoz, érdeklődéshez, megbízhatósághoz, viselkedéshez, tartózkodási helyhez vagy mozgáshoz kapcsolódó jellemzők elemzésére vagy előrejelzésére használják', 'Ilyen adatkezelést az AOC System Zrt. nem végez!']
  },
  {
    details: ['Álnevesítés: a személyes adatok olyan módon történő kezelése, amelynek következtében további információk felhasználása nélkül többé már nem állapítható meg, hogy a személyes adat mely konkrét természetes személyre vonatkozik, feltéve, hogy az ilyen további információt külön tárolják, és technikai és szervezési intézkedések megtételével biztosított, hogy azonosított vagy azonosítható természetes személyekhez ezt a személyes adatot nem lehet kapcsolni', 'Azonosító kibocsátása és használata egy érintettel kapcsolatban, pl. ügyfélazonosító']
  },
  {
    details: ['Adattovábbítás vagy adatközlés: az adat meghatározott harmadik személy számára történő hozzáférhetővé tétele', 'Adattovábbítás Partner, hatóság stb. felé']
  },
  {
    details: ['Adatfeldolgozó fogalma: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel', 'Ilyen pl. egy függő ügynök, vagy az AOC System Zrt. a regisztrált felhasználók által rögzített, feltöltött adatok vonatkozásában. Lásd részletesen a 2. fejezetben']
  },
  {
    details: ['Adatfeldolgozás: olyan adatkezelés, amelyet egy adatfeldolgozó végez nem általa meghatározott célból, eszközökkel, hanem egy adatkezelő rendelkezése szerint;', 'Pl. egy regisztrált függő ügynök felhasználó által a pénzügyi intézmény utasítására végzett adatkezelés, vagy pl. az AOC System Zrt. által üzemeltetett AOC System rendszer funkciói']
  },
  {
    details: ['Adattörlés: az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé nem lehetséges', 'Elektronikusan tárolt adatok visszaállíthatatlan törlése']
  },
  {
    details: ['Adatmegsemmisítés: az adatokat tartalmazó adathordozó teljes fizikai megsemmisítése, így például az adatokat tartalmazó irat ledarálása', 'Pl. papír alapú dokumentumok fizikai megsemmisítése']
  },
  {
    details: ['Adatállomány: az egy nyilvántartásban kezelt adatok összessége', 'Egy-egy adatkezelésben kezelt adatok összessége']
  },
  {
    details: ['Nyilvántartási rendszer: a személyes adatok bármely módon – centralizált, decentralizált vagy funkcionális vagy földrajzi szempontok szerint – tagolt állománya, amely meghatározott ismérvek alapján hozzáférhető', 'Ilyen az AOC System rendszer']
  },
  {
    details: ['AOC System rendszer: az AOC System Zrt. üzemeltetésében levő, meghatározott weboldal biztonságos csatornáján keresztül elérhető nyilvántartási rendszer, amelybe felhasználók regisztrálhatnak, így regisztrált felhasználókká válhatnak, beléphetnek, abban adatokat rögzíthetnek és általában élvezhetik annak szolgáltatásait, előnyeit, funkcióit', 'Az AOC System rendszer jelenti a weboldalt és a weboldalon keresztül elérhető szolgáltatásokat is.']
  },
  {
    details: ['Harmadik személy: olyan természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely nem azonos az érintettel, az adatkezelővel vagy az adatfeldolgozóval, vagy azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak', 'Pl. egy pénzügyi intézmény']
  },
  {
    details: ['Adatvédelmi incidens fogalma a GDPR alkalmazását követően: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi', 'Pl. hackelés eredménye, zsarolóvírus, adatszivárgás, adatlopás']
  },
  {
    details: ['Partner: olyan harmadik személy, aki az AOC System Zrt. által az AOC System rendszeren keresztül nyújtott szolgáltatásainak teljesítéseit valamilyen módon elősegíti', 'Pl. jogi tanácsadást nyújtó személy, szervezet, adatbiztonsági tanácsadó stb.']
  },
  {
    details: ['Munkatárs: az AOC System Zrt. megbízási-, munka-, közalkalmazotti vagy egyéb jogviszonyban levő természetes személy, aki az Adatkezelő szolgáltatásainak ellátásnak, teljesítésének feladatával van bízva és adatkezelési vagy adatfeldolgozási feladatai során személyes adatokkal kapcsolatba kerül vagy kerülhet és akinek tevékenységével kapcsolatban az Adatkezelő teljes felelősséget vállal az érintettek személyi köre és harmadik személyek irányában', 'Munkavállaló, megbízott stb.']
  },
  {
    details: ['Weboldal', 'A https://www.aocsystem.com/ portál és minden aloldala, amelynek üzemeltetője az Adatkezelő']
  },
]

const registeredUsersTableRows = [
  {
    name: 'Adatfeldolgozás tárgya',
    details: ['AOC System rendszerben található „Naptár” funkció/menüpont', 'AOC System rendszerben található „Feladataim” funkció/menüpont (Feladatütemező)', 'AOC System rendszerben található „Ügyfeleim” funkció/menüpont (ügyfélkezelés)', 'AOC System rendszerben található „Dokumentumtár” funkció/menüpont', 'AOC System rendszerben található „Vállalkozásom” funkció/menüpont és alpontjai', 'AOC System rendszerben található „Csoportom” funkció/menüpont', 'AOC System rendszerben található „Tárgyalási jegyzőkönyv” létrehozása']
  },
  {
    name: 'Adatfeldolgozás célja',
    details: ['Regisztrált felhasználók számára naptár biztosítása', 'Regisztrált felhasználók számára feladatütemező biztosítása', 'Regisztrált felhasználók számára ügyfélnyilvántartó biztosítása', 'Regisztrált felhasználók számára tárhely biztosítása dokumentumok kezeléséhez', 'Regisztrált felhasználók számára tárhely biztosítása vállalkozás működésével, könyveléssel kapcsolatos dokumentumok kezeléséhez', 'Statisztikák készítése, tervezés elősegítése a regisztrált felhasználók számára', 'Regisztrált felhasználó és ügyfele között létrejött tárgyalás részleteinek rögzítése']
  },
  {
    name: 'Adatfeldolgozás jellege, módja',
    details: ['Tárolás, regisztrált felhasználó által történő módosítás, törlés biztosítása', 'Tárolás, regisztrált felhasználó által történő módosítás, törlés biztosítása', 'Tárolás, regisztrált felhasználó által történő módosítás, lekérdezés, leszűrés törlés biztosítása', 'Tárolás, exportálás, keresés, regisztrált felhasználó által történő törlés biztosítása', 'Tárolás, exportálás, keresés, regisztrált felhasználó által történő törlés biztosítása', 'Statisztikák és célok létrehozása bevitt adatokból, tárolás, lekérdezés, vizuális megjelenítés', 'Tárolás']
  },
  {
    name: 'Adatfeldolgozás időtartama',
    details: ['Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig', 'Regisztrált felhasználóval létrejött megállapodás megszűnéséig']
  },
  {
    name: 'Személyes adatok típusa, köre',
    details: ['Naptárba regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: Időpont, név, elérhetőségi adat, cím', 'Feladatütemezőbe a regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: dátum, ügyfél név, események, művelet, jegyzetben tárolt adatkörök', 'Az ügyfeleim menüpontban a regisztrált felhasználó által bevitt, rögzített adatok, tipikusan: név, cím, e-mail cím, telefonszám, megjegyzés, korábbi feladatok adatai, státusz', 'A regisztrált felhasználó által feltöltött dokumentumok adattartalma, regisztrált felhasználó megjegyzései', 'Anonim adatok a statisztikák kapcsán', 'Név, telefonszám, e-mail cím, statisztikai adatok a tervezés kapcsán', 'Regisztrált felhasználó által a tárgyalási jegyzőkönyvben rögzített adatok']
  },
  {
    name: 'Érintettek kategóriája',
    details: ['Regisztrált felhasználó által rögzített adatok alapján beazonosítható, beazonosított személyek, pl. a regisztrált felhasználó potenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített adatok alapszemélyek, pl. a regisztrált felhasználópotenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített adatok alapján beazonosítható, beazonosított személyek, pl. a regisztrált felhasználó potenciális ügyfelei vagy meglevő ügyfelei', 'Regisztrált felhasználó által rögzített dokumentumok adatai által beazonosítható, beazonosított személyek', 'Regisztrált felhasználó által rögzített dokumentumok adatai által beazonosítható, beazonosított személyek', 'Tervezés kapcsán regisztrált felhasználók', 'Regisztrált felhasználó által a tárgyalási jegyzőkönyv adatai alapján beazonosítható, beazonosított személyek (reg. felhasználó, ügyfele)']
  },
  {
    name: 'Adattovábbítás címzettjei az AOC System-en keresztül',
    details: ['Nincsen', 'Nincsen', 'Nincsen', 'Nincsen', 'Regisztrált felhasználó (könyvelő) felé az AOC System rendszerén belül', 'Nincsen', 'Nincsen']
  },
  {
    name: 'Adattovábbítás harmadik országokba az AOC System-en keresztül',
    details: ['Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik', 'Nem történik']
  },
]

const subcontractorTableColumns = [
  { title: 'Alvállalkozó neve', variant: 'h7', align: 'right' },
  { title: 'Elérhetőségi adatok / adatvédelmi tisztviselő', variant: 'h7', align: 'right' },
  { title: 'Ellátott feladat', variant: 'h7', align: 'right' },
  { title: 'Közölt adatok kategóriái', variant: 'h7', align: 'right' }
]

const subcontractorTableRows = [
  {
    details: ['IBM Cloud', 'IBM-Allee 1 D-71139 Ehningen, PA: 71137 Ehningen, tel: +49 800 225 5426', 'Tárhelyszolgáltatás', 'Regisztráció során megadott adatok, AOC System rendszer használata során kezelt adatok',]
  },
  {
    details: ['Forpsi – BlazeArts Kft.', 'Kunszentmiklós, Damjanich u. 36. 1/8., e-mail cím: dpo@forpsi.hu telefonszám: (+36) 1 610 5506', 'webtárhely', '',]
  }
]

const dataManagementTableColumns = [
  { title: 'Cél', variant: 'h7', align: 'left' },
  { title: 'Jogalap', variant: 'h7', align: 'right' },
  { title: 'Érintettek', variant: 'h7', align: 'right' },
  { title: 'Adatkategória', variant: 'h7', align: 'right' },
  { title: 'Időtartam', variant: 'h7', align: 'right' },
  { title: 'Forrás', variant: 'h7', align: 'right' },
]

const aocTableRows = [
  {
    details: ['az érintett adatainak rögzítése, jogosultságainak, kedvezményeinek, hozzáférésének megadása, érvényesítése, ellenőrzése, a szolgáltatások igénybevételének megkönnyítése, valamint a kapcsolattartás', 'önkéntes hozzájárulás, de értelmezhető úgy is, hogy az adatkezelés megállapodáson (GDPR 6. cikk (1) b) pont második fordulata) alapul', 'Minden természetes személy, aki az AOC System rendszerbe történő regisztráció során megadott adatokkal beazonosítható, beazonosított', 'Lásd a regisztráció adatait', 'Lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const logInTableRows = [
  {
    details: ['az AOC System rendszerbe bejelentkező érintettek azonosítása, jogosultságainak biztosítása, ellenőrzése', 'önkéntes hozzájárulás', 'Minden természetes személy, aki az AOC System rendszerbe történő bejelentkezés alapján beazonosítható, beazonosított', 'Lásd a bejelentkezés adatköreit', 'Törlési kérelemig, polgári elévülési időben, részletesen lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const accountTableRows = [
  {
    details: ['célja az, hogy az érintett megismerhesse a rá vonatkozó adatokat, szükség esetén helyesbítse azokat', 'Jogi kötelezettség teljesítése', 'Minden regisztrált felhasználó, aki az AOC System rendszerbe belép', 'Lásd részletesen az adatkezelés tájékoztatójában/leírásában', 'érintett kérésére törlésig, jogos érdek megszűnéséig, számviteli tv. szerinti 8 évig', 'Érintettek']
  }
]

const contactTalbeRows = [
  {
    details: ['az érintettel történő kapcsolattartás, felmerülő kérdések, kérések és egyebek megválaszolása, megoldása', 'Hozzájárulás, megállapodás, jogi köt. telj, jogos érdek, részletesen lásd az adatkezelés tájékoztatójában', 'Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az egyszeri információkérésen túl folyamatosan, vagy rendszeresen kapcsolatot tart az Adatkezelővel', 'név, telefonszám, levelezési/e-mail cím, egyéb az érintett által megadott adat', 'Függ a jogalaptól, céltól, de tipikusan cél megvalósulása, törlési kérelem, polgári jogi elévülési idő, jogos érdek megszűnése, részletesen lásd az adatkezelés tájékoztatójában', 'Érintettek']
  }
]

const invoiceOutTableRows = [
  {
    details: ['a számlakibocsátással kapcsolatos jogszabályi kötelezettségek maradéktalan teljesítése', 'jogi kötelezettség teljesítése a 2007. évi CXXVII. törvény, valamint az e törvény felhatalmazása alapján kiadott rendeletek szerint', 'minden természetes személy, ideértve az egyéni vállalkozót is, akinek adata az Adatkezelő által kibocsátott számla (vagy azzal egy tekintet alá eső számviteli bizonylat) alapján azonosítható, azonosított', '2007. évi CXXVII. tv. 169-170. §-ban és 176.§-ban megfogalmazott adatkategóriák', '2000. évi C. törvény 169. §-a alapján az üzleti év plusz 8 év', 'közvetlenül az érintettől, ritkán közhiteles nyilvántartásból']
  }
]

const lawTableColumns = [
  { title: '', variant: 'h7', align: 'left' },
  { title: 'Előzetes tájékozódáshoz való jog', variant: 'h7', align: 'right' },
  { title: 'Hozzáférés joga', variant: 'h7', align: 'right' },
  { title: 'Helyesbítés joga', variant: 'h7', align: 'right' },
  { title: 'Törlés joga', variant: 'h7', align: 'right' },
  { title: 'Korlátozás', variant: 'h7', align: 'right' },
  { title: 'Adat-hordozhatóság', variant: 'h7', align: 'right' },
  { title: 'Tiltakozás', variant: 'h7', align: 'right' },
  { title: 'Hozzájárulás visszavonása', variant: 'h7', align: 'right' },
]

const lawTableRows = [
  {
    name: 'Hozzájárulás',
    details: [true, true, true, true, true, true, false, true]
  },
  {
    name: 'Megállapodás',
    details: [true, true, true, true, true, true, false, false]
  },
  {
    name: 'Jogi kötelezettség',
    details: [true, true, true, false, true, false, false, false]
  },
  {
    name: 'Létfontosságú érdek',
    details: [true, true, true, true, true, false, false, false]
  },
  {
    name: 'Közfeladat, közhatalmi jog.',
    details: [true, true, true, false, true, false, true, false]
  },
  {
    name: 'Jogos érdek',
    details: [true, true, true, true, true, false, true, false]
  },
]

const dataManagerTalbeColumns = [
  { title: 'Adatfeldolgozó neve', variant: 'h7', align: 'left' },
  { title: 'Adatfeldolgozó elérhetősége', variant: 'h7', align: 'right' },
  { title: 'Feladat', variant: 'h7', align: 'right' }
]

const dataManagerTableRows = [
  {
    details: ['IBM Cloud', 'IBM-Allee 1 D-71139 Ehningen, PA: 71137 Ehningen, tel: +49 800 225 5426', 'Tárhelyszolgáltatás',]
  }
]

const dataPurposeTableColumns = [
  { title: 'Adatkör', variant: 'h7', align: 'left' },
  { title: 'Cél', variant: 'h7', align: 'right' },
  { title: 'Jogalap', variant: 'h7', align: 'right' },
  { title: 'Tárolási idő', variant: 'h7', align: 'right' }
]

const dataPurposeTableRows = [
  {
    details: ['Felhasználónév / e-mail cím*', 'azonosítás, bejelentkezés', 'Önkéntes hozzájárulás', 'Nyilvántartási rendszerben: Törlési kérelemig, belépési jogosultság megszűntetéséig Naplófile-ban: polgári elévülési időben',]
  },
  {
    details: ['Jelszó*', 'azonosítás, bejelentkezés', 'Önkéntes hozzájárulás', 'Nyilvántartási rendszerben: Törlési kérelemig, belépési jogosultság megszűntetéséig Naplófile-ban: polgári elévülési időben',]
  },
  {
    details: ['bejelentkezés időpontja*', 'azonosítás, bizonyítás', 'Adatkezelő jogos érdeke, amely a későbbi bizonyíthatóságban ölt testet', 'Naplófile-ban: polgári elévülési időben',]
  },
  {
    details: ['bejelentkezés során használt IP cím*', 'későbbi bizonyítás', 'Adatkezelő jogos érdeke, amely a későbbi bizonyíthatóságban ölt testet', 'Naplófile-ban: polgári elévülési időben',]
  }
]

const dataHandlingTalbeColumns = [
  { title: 'Adatkör', varinat: 'h7', align: 'left' },
  { title: 'Cél', varinat: 'h7', align: 'left' },
  { title: 'Jogalap', varinat: 'h7', align: 'left' },
  { title: 'Tárolási idő', varinat: 'h7', align: 'left' },
]


const dataHandlingTalbeRows = [
  {
    details: ['név* e-mail cím / levelezési cím / telefonszám* kérdés, kérés tartalma tájékoztatás adatkörei', 'Azonosítás, megszólítás Azonosítás, kapcsolattartás Azonosítás, kapcsolattartás válaszadás, tájékoztatás válaszadás, tájékoztatás', 'Önkéntes hozzájárulás, ha az adatokat az érintett önkéntesen bocsátja az Adatkezelő rendelkezésére (pl. érintett kérelmével kapcsolatos levelezés során)', 'cél megvalósulásáig, vagy törlési kérelemig',]
  },
  {
    details: ['név* e-mail cím / levelezési cím / telefonszám* kérdés, kérés tartalma tájékoztatás adatkörei', 'Azonosítás, megszólítás Azonosítás, kapcsolattartás Azonosítás, kapcsolattartás válaszadás, tájékoztatás válaszadás, tájékoztatás', 'megállapodás, ha az Adatkezelő és az érintett megállapodást kötnek egymással például az Adatkezelő valamely szolgáltatásának igénybevételéről', 'polgári jogi elévülési időben',]
  },
  {
    details: ['név* e-mail cím / levelezési cím / telefonszám* kérdés, kérés tartalma tájékoztatás adatkörei', 'Azonosítás, megszólítás Azonosítás, kapcsolattartás Azonosítás, kapcsolattartás válaszadás, tájékoztatás válaszadás, tájékoztatás', 'jogszabályi kötelezettség teljesítése, amennyiben ilyen kapcsolatfelvételi, tájékoztatási kötelezettséget jogszabály előír akár az Adatkezelő, akár az érintett számára', 'jogszabályban meghatározott határidőben. Megjegyzés: Egy levelezés számviteli bizonylat alapjául is szolgálhat, tehát megőrizendő 8 évig a számviteli bizonylat alátámasztása céljából.',]
  },
  {
    details: ['név* e-mail cím / levelezési cím / telefonszám* kérdés, kérés tartalma tájékoztatás adatkörei', 'Azonosítás, megszólítás Azonosítás, kapcsolattartás Azonosítás, kapcsolattartás válaszadás, tájékoztatás válaszadás, tájékoztatás', 'adatok kezelése alapulhat harmadik személy, vagy az Adatkezelő jogos érdekén is, ilyen esetben a kapcsolatfelvétel során erről külön tájékoztatást ad az Adatkezelő, pl. a nem természetes személy ügyfél / partner kapcsolattartója adatainak kezelése', '(jogos) érdek megszűnéséig. Megjegyzés: egy levelezés bizonyítékául szolgálhat egy ellenőrzés során, ezért az Adatkezelő kezelheti azt elévülési időben, amelynek lejárta a jogos érdek megszűnésének időpontja lesz.',]
  },
  {
    details: ['név* e-mail cím / levelezési cím / telefonszám* kérdés, kérés tartalma tájékoztatás adatkörei', 'Azonosítás, megszólítás Azonosítás, kapcsolattartás Azonosítás, kapcsolattartás válaszadás, tájékoztatás válaszadás, tájékoztatás', 'érintett, vagy másik személy létfontosságú érdekeinek védelme miatt szükséges az adatkezelés', '(jogos) érdek megszűnéséig. Megjegyzés: egy levelezés bizonyítékául szolgálhat egy ellenőrzés során, ezért az Adatkezelő kezelheti azt elévülési időben, amelynek lejárta a jogos érdek megszűnésének időpontja lesz.',]
  },
]




function PrivacyPolicy() {
  const matches = useMediaQuery('(min-width:430px)');
  const { t, } = useTranslation();

  document.title = t('Title.main') + " | " + t('Title.subTitle.privacyPolicy')

  const classes = useStyles()
  return (
    <React.Fragment>
      <Paper>
      <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
            <Container>
              <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>Adatvédelmi szabályzat és tájékoztató</Typography>
              <Typography textAlign='left' color='#f7f7f7'>Az AOC System rendszerbe történő regisztrációhoz</Typography>
            </Container>
          </Box>
        <Box
          sx={{
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Grid
            container>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <Container>
                <Typography fontWeight='bold' marginBottom='50px'>Tartalomjegyzék</Typography>
                <Grid
                  container>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    sm={10}
                    xs={10}
                    textAlign='left'
                  >
                    <div>
                      <ScrollLink to='introduction' smooth={true} offset={-100} >
                        <Typography color='#000' className={classes.button}>Bevezetés</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='firstChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>1. Fejezet     Fogalommeghatározás</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='secondChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>2. Fejezet     Az adatkezelő és adatfeldolgozó személye</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='thirdChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>3. Fejezet     A szabályzat célja</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='fourthChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>4. Fejezet     Szabályzat hatálya</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='fifthChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>5. Fejezet     AOC System Zrt., mint adatfeldolgozó által regisztrált felhasználók számára végzett adatkezelések (adatfeldolgozások)</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='sixthChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>6. Fejezet     Az AOC System Zrt., mint adatkezelő által végzet adatkezelések</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='seventhChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>7. Fejezet     AOC System Zrt., mint adatkezelő nyilatkozatai</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='eighthChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>8. Fejezet     Adatkezelésre és adatfeldolgozásra vonatkozó adatbiztonsági rendelkezések általánosságban</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='ninethChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>9. Fejezet     Felhasználók, regisztrált felhasználók kötelezettségei</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='tenthChapter' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>10. Fejezet  Egyéb rendelkezések</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='dataInformation' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Részletes adatkezelési tájékoztató (https://www.aocsystem.com/ oldalon) az AOC System Zrt. által az AOC System rendszer kapcsán végzett adatkezelésekről</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='registration' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Az AOC System rendszerbe (https://www.aocsystem.com/ oldalra) történő regisztráció során megadott adatokkal kapcsolatos adatkezelés</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='signIn' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Bejelentkezés az AOC System rendszerbe (https://www.aocsystem.com/ oldalra</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='accManagement' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Fiókkezelés az AOC System rendszerben</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='contact' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatokhoz kapcsolódó adatkezelés tájékoztatója</Typography>
                      </ScrollLink>
                    </div>
                    <div>
                      <ScrollLink to='invoice' smooth={true} offset={-100}>
                        <Typography color='#000' className={classes.button}>Számlakibocsátás során kezelt adatokkal kapcsolatos adatkezelés</Typography>
                      </ScrollLink>
                    </div>
                  </Grid>
                </Grid>
                <Typography className={classes.title} variant='h5' color='#000' activeClass='true' id='introduction'>Bevezetés</Typography>
                <ol>
                  <li><Typography className={classes.text}>Az AOC System Zrt., mint a https://www.aocsystem.com/ üzemeltetője elkötelezett az adatvédelem és adatbiztonság mellett, és ezúton tájékoztatja a felhasználót, hogy tiszteletben tartja az érintettek jogait, az adatvédelem alapelveit és szabályait, ezért a végzett adatkezelései során a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről szóló, az Európai Parlament és a Tanács (EU) 2016/679. sz. általános adatvédelmi rendelete (GDPR), továbbá az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (Infotv), a működésre vonatkozó más jogszabályok, és az ezekkel összhangban levő adatvédelmi szabályozási portfólió, így különösen, de nem kizárólagosan a jelen Adatvédelmi Szabályzat és Tájékoztató (a továbbiakban: Szabályzat) rendelkezései alapján jár el.</Typography></li>
                  <li><Typography className={classes.text}>Ugyanakkor az AOC System Zrt. hangsúlyozottan felhívja a felhasználó figyelmét e jogszabályok előírásainak felhasználó által történő betartásának kötelezettségére is.</Typography></li>
                  <li className={classes.text}><Typography >Az AOC System Zrt. felhívja továbbá arra a figyelmet, hogy</Typography>
                    <ul>
                      <li style={{ textAlign: 'justify' }}>adatkezelőként jár el az általa üzemeltetett weboldalak felhasználói, valamint a weboldalak valamelyikén keresztül az AOC System rendszerébe regisztrált felhasználók adatai vonatkozásában, és</li>
                      <li style={{ textAlign: 'justify' }}>adatfeldolgozóként, vagy al-adatfeldolgozóként jár el a regisztrált felhasználók által az AOC System rendszerébe feltöltött, rögzített harmadik személyek személyes (és különleges) adatai vonatkozásában tekintettel arra, hogy utóbbi esetben az AOC System Zrt. egy rendszert (felületet, tárhelyet, funkciókat) biztosít ahhoz, hogy a regisztrált felhasználók az általuk bevitt adatokat kezeljék, de az AOC System Zrt. ezen adatokat nem kezeli saját céljai érdekében.</li>
                    </ul>
                  </li>
                  <li className={classes.text}><Typography>Fentiek alapján a jelen Szabályzat külön fejezetben rendezi az adatkezelői, és külön fejezetben az adatfeldolgozói státuszból származó adatkezeléseket, jogokat és kötelezettségeket a következő, az átláthatóság elvét (GDPR 5. cikk (1) a) pont) kielégítő módon:</Typography>
                    <ul>
                      <li style={{ textAlign: 'justify' }}>Szabályzat fejezetei általános rendelkezéseket tartalmaznak minden végzett adatkezelésre vonatkozóan, </li>
                      <li style={{ textAlign: 'justify' }}>az egyes végzett adatkezelési tevékenységek leírásai a Szabályzat mellékletében kerültek meghatározásra;</li>
                      <li style={{ textAlign: 'justify' }}>az adatkezelések összefoglalója táblázatos formátumú és a mellékletben meghatározott egyes adatkezelési tevékenységek részletes leírásai/tájékoztatói tartalmaznak táblázato(ka)t, valamint kérdés-felelet formátumban jöttek létre, a gyors és könnyű áttekinthetőség miatt, továbbá a jobb olvashatóság érdekében a kérdések vastag betűvel szedettek, a dőlt betűvel jelölt részek pedig értelmező magyarázatokat tartalmaznak</li>
                    </ul>
                  </li>
                  <li><Typography className={classes.text}>Az AOC System Zrt. törekszik arra, hogy minél pontosabban betartsa a Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlásait, így különösen az előzetes tájékoztatás adatvédelmi követelményeiről szóló 2015. szeptember hó 29. napján kiadott ajánlását, és ezért a lehető legérthetőbben fejezi ki az általa alkalmazott adatvédelmi szabályokat, szükség esetén példákkal magyarázva azokat, valamint részletesen bemutatja az egyes adatkezelési és adatfeldolgozási tevékenységeket, hogy az érintett megismerhesse azokat.</Typography></li>
                  <li><Typography className={classes.text}>Az AOC System Zrt. a Szabályzat és az e Szabályzaton túl létrehozott belső adatvédelmi szabályozási portfólió bármelyik tagjának az időközben módosulandó jogszabályi háttérrel, módosuló működéssel történő összehangolása miatti megváltoztatására a jogot fenntartja.</Typography></li>

                  <li>
                    {matches ?
                      <Typography className={classes.text}>A Szabályzat mindenkor hatályos változata folyamatosan elérhető a https://www.aocsystem.com/adatvedelem oldalon. A Szabályzat módosulásáról és annak mibenlétéről az AOC System Zrt. előzetesen – elektronikus úton – tájékoztatja a regisztrált felhasználókat.</Typography>
                      :
                      <Typography className={classes.text}>A Szabályzat mindenkor hatályos változata folyamatosan elérhető a https://www.aocsystem.com/<br />adatvedelem oldalon. A Szabályzat módosulásáról és annak mibenlétéről az AOC System Zrt. előzetesen – elektronikus úton – tájékoztatja a regisztrált felhasználókat.</Typography>
                    }
                  </li>
                  <li>
                    {matches ?
                      <Typography className={classes.text}>Az adatfeldolgozási megállapodás mindenkor hatályos változata folyamatosan elérhető https://www.aocsystem.com/adatvedelem oldalon. Az adatfeldolgozási megállapodás módosulásáról és annak mibenlétéről az AOC System Zrt. előzetesen – elektronikus úton – tájékoztatja a regisztrált felhasználókat.</Typography>
                      :
                      <Typography className={classes.text}>Az adatfeldolgozási megállapodás mindenkor hatályos változata folyamatosan elérhető https://www.aocsystem.com/<br />adatvedelem oldalon. Az adatfeldolgozási megállapodás módosulásáról és annak mibenlétéről az AOC System Zrt. előzetesen – elektronikus úton – tájékoztatja a regisztrált felhasználókat.</Typography>
                    }
                  </li>



                  <li><Typography className={classes.text}>Mindezek alapján fent nevezett jogszabályok, a mindenkori Szabályzat és a belső szabályozási portfólió rendelkezéseit az AOC System Zrt. magára nézve kötelezőnek tekinti, és működése során ezek értelmében jár el.</Typography></li>
                  <li className={classes.text}><Typography>Felhasználó akkor válik az AOC System rendszer regisztrált felhasználójává, amennyiben – többek között – bizonyítható módon, vonatkozó jelölőnégyzetbe tett jelöléssel:</Typography>
                    <ul>
                      <li style={{ textAlign: 'justify' }}>tudomásul veszi a Szabályzat rendelkezéseit, továbbá</li>
                      <li style={{ textAlign: 'justify' }}>elfogadja az adatfeldolgozási megállapodást.</li>
                    </ul>
                  </li>
                </ol>
                <Typography className={classes.title} variant='h5' id='firstChapter' >Fogalommeghatározás</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt. a következő fogalmakat használja a jelen Szabályzatban (és adatfeldolgozási megállapodásban) ezért javasolja a fogalmak és vonatkozó jellemző példák részletes áttekintését.</li>
                </ol>


                <BasicTable
                  columns={definitionTableColumns}
                  rows={definitionTableRows}
                >
                </BasicTable>

                <Typography className={classes.title} variant='h5' id='secondChapter'>Az adatkezelő és adatfeldolgozó személye</Typography>
                <ol className={classes.text}>
                  <li>Az AOC System Zrt. üzemeltetésében levő weboldalak felhasználói, valamint az AOC System rendszerbe regisztrált felhasználók adatainak kezelése vonatkozásában adatkezelő:</li>

                  <Typography className={classes.subTitle}>AOC System Zártkörűen Működő Részvénytársaság</Typography>
                  <Typography className={classes.text}>azonosító és elérhetőségi adatai a következők:</Typography>
                  <ul>
                    <li>székhely: 1062 Budapest, Délibáb u. 8.</li>
                    <li>cégjegyzékszám: 01-10-048930</li>
                    <li>adószám: 25723258-2-42</li>
                    <li>internetes elérhetősége (és a vonatkozó tényleges adatkezelések webhelye): https://www.aocsystem.com/</li>
                    <li>telefonszám: +36 30 3611 1911</li>
                    <li>e-mail: office@aocsystem.com</li>
                    <li>képviseli: Fodor Zsolt vezérigazgató</li>
                    <li>adatvédelmi tisztviselő neve és elérhetősége: Dr. Bölcskei Krisztián, postai úton elérhető az Adatkezelő székhelyén, e-mail-ben az dpo@aocsystem.com címre küldött e-mail útján, telefonon a + 36 31 781 2012 számon</li>
                  </ul>
                  <li className={classes.text}>A regisztrált felhasználók által az AOC System Zrt. üzemeltetésében levő AOC System rendszerbe bevitt harmadik személyek vonatkozásában adatkezelő (vagy adatfeldolgozó):</li>
                  <Typography textAlign='center' marginBottom='20px'>az adatot berögzítő, feltöltő regisztrált felhasználó</Typography>
                  <Typography textAlign='center' marginBottom='50px'>(pl. kiemelt közvetítő, stb.).</Typography>
                  <li className={classes.text}>Hivatkozva a 2. pontra, az AOC System Zrt. ezúton tájékoztatja arról a felhasználókat, ahogy függően attól, hogy a felhasználó biztosítóval, pénzügyi intézménnyel, pénzforgalmi intézménnyel, vagy elektronikuspénz-kibocsátó intézménnyel vagy más harmadik személlyel milyen (függelmi) kapcsolatban áll, a felhasználó lehet önálló adatkezelő, végezhet közös adatkezelést harmadik személlyel és lehet harmadik személy adatfeldolgozója is.</li>
                  <li className={classes.text}>Az AOC System Zrt. felhívja arra a figyelmet, hogy többek között, nem kizárólagos felsorolással élve, tipikusan
                    <ul>
                      <li className={classes.text}>adatfeldolgozó a függő közvetítő, így a
                        <ul>
                          <li>kiemelt közvetítő (Hpt. 10. § (1) bekezdés a) pontjának aa) alpontja)</li>
                          <li>függő ügynök (Hpt. 10. § (1) bekezdés a) pontjának ab) alpontja)</li>
                          <li>függő (pénzforgalmi) közvetítő ((Hpt. 10. § (1) bekezdés a) pontjának ac) alpontja)</li>
                        </ul>
                      </li>
                      <li className={classes.text}>
                        függő (pénzforgalmi) közvetítő ((Hpt. 10. § (1) bekezdés a) pontjának ac) alpontja)
                        <ul>
                          <li>többes kiemelt közvetítő (Hpt. 10. § (1) bekezdés b) pontjának ba) alpontja)</li>
                          <li>többes ügynök (Hpt. 10. § (1) bekezdés b) pontjának bb) alpontja)</li>
                        </ul>
                      </li>
                      <li className={classes.text}>
                        alkusz Hpt. 10. § (1) bekezdés b) pontjának bc) alpontja)
                      </li>
                    </ul>
                  </li>
                  <li className={classes.text}>A felhasználónak áttekintve a jogszabályi rendelkezéseket, vonatkozó szerződését és más, a jogviszonyát meghatározó releváns dokumentumokat magának kell meghatároznia azt, hogy adatkezelő, közös adatkezelő vagy adatfeldolgozó státuszban végzi-e a feladatait és kezeli az adatokat, és ennek tudatában kell, hogy az AOC System rendszert használja, a vonatkozó adatfeldolgozási megállapodást elfogadja, és oda harmadik személyek adatait rögzítse, feltöltse, vagy más módon kezelje.</li>
                  <li className={classes.text}>Az AOC System Zrt. kizárja mindennemű felelősségét, amely a felhasználó adatkezelői vagy adatfeldolgozói státuszának helytelen megállapításából fakad.</li>
                  <li className={classes.text}>Az AOC System Zrt. felhívja arra a figyelmet, hogy amennyiben a regisztrált felhasználó adatkezelőnek vagy közös adatkezelőnek minősül, úgy a GDPR, az Infotv és a vonatkozó egyéb jogszabályok előírásait ennek szellemében kell betartania és javasolt az adatkezelési tevékenységei vonatkozásában arányos belső adatvédelmi és adatbiztonsági szabályozást létrehoznia, bevezetnie és működtetnie.</li>
                  <li className={classes.text}>Az AOC System Zrt. kijelenti, a regisztrált felhasználó tudomásul veszi, hogy amennyiben a regisztrált felhasználó adatkezelőnek vagy közös adatkezelőnek minősül, úgy a regisztrált felhasználó által az AOC System rendszerébe rögzített, feltöltött harmadik személyek személyes (és különleges) adatai vonatkozásában az AOC System Zrt. adatfeldolgozóként működik közre.</li>
                  <li className={classes.text}>Az AOC System Zrt. kijelenti, a regisztrált felhasználó tudomásul veszi, hogy amennyiben a regisztrált felhasználó adatfeldolgozónak, vagy al-adatfeldolgozónak minősül, úgy
                    <ul>
                      <li>a regisztrált felhasználó által az AOC System rendszerébe rögzített, feltöltött harmadik személyek személyes (és különleges) adatai vonatkozásában az AOC System Zrt. al-adatfeldolgozóként, vagy al-al-adatfeldolgozóként működik közre,</li>
                      <li>az AOC System Zrt. a regisztrált felhasználó által rögzített, feltöltött adatok valóságtartalmát nem vizsgálja.</li>
                    </ul>
                  </li>
                  <li className={classes.text}>Azokban az esetekben, amikor az AOC System Zrt. (al-)adatfeldolgozóként kezel adatokat, akkor lényegében egy rendszert (felületet, tárhelyet és funkciókat) biztosít az adatkezelő vagy adatfeldolgozó regisztrált felhasználó számára a felhasználóval kötött adatfeldolgozási megállapodás rendelkezései szerint.</li>
                  <li className={classes.text}>Az AOC System Zrt. felhívja arra a felhasználó figyelmét, hogy a jelen Szabályzat e fejezet szerinti rendelkezéseit, valamint a felhasználót, regisztrált felhasználót terhelő GDPR szerinti kötelezettségeket a Felhasználói Kézikönyv GDPR-ral kapcsolatos fejezete részletesebben kifejti, továbbá kéri a felhasználót arra, hogy ismerkedjen meg a GDPR és a 2011. évi CXII. törvény, valamint a felhasználó működésére vonatkozó jogszabályok adatvédelemmel, adatkezeléssel, adatbiztonsággal kapcsolatos rendelkezéseivel, továbbá az adatvédelmi hatóság állásfoglalásaival, iránymutatásaival, határozataival.</li>
                </ol>
                <Typography className={classes.title} variant='h5' id='thirdChapter'>A Szabályzat célja</Typography>
                <ol>
                  <li className={classes.text}>A Szabályzat célja, hogy meghatározza az AOC System Zrt., mint adatkezelő és mint adatfeldolgozó által betartott alapvető rendező elveket és szabályokat az AOC System rendszerrel kapcsolatban, hogy az érintettek magánszférája és jogai védelemben részesüljenek a vonatkozó mindenkori jogszabályi előírásoknak és hatósági állásfoglalásoknak megfelelően, és ezekről a regisztrálni kívánó felhasználókat tájékoztassa, hogy megfelelő tájékoztatás mellett megalapozottan dönthessék el, hogy igénybe kívánják-e venni az AOC System Zrt. AOC System rendszerének szolgáltatásait.</li>
                  <li className={classes.text}>A Szabályzat az AOC System Zrt. belső adatvédelmi szabályozási portfóliójának részét képezi és mint ilyen, további célja annak biztosítása, hogy az AOC System Zrt. mindenben megfeleljen a hatályos jogszabályok adatvédelemmel kapcsolatos rendelkezéseinek, így különösen, de nem kizárólagosan
                    <ul>
                      <li>az Európai Parlament és a Tanács (EU) 2016/679 Rendelete (GDPR),</li>
                      <li>az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (Infotv) rendelkezéseinek.</li>
                    </ul>
                  </li>
                </ol>



                <Typography className={classes.title} variant='h5' id='fourthChapter'>Szabályzat hatálya</Typography>
                <ol>
                  <li className={classes.text}>Időbeli hatály: Jelen Szabályzat 2020. május hó 31. napjától további módosító rendelkezésig vagy visszavonásig hatályos.</li>
                  <li className={classes.text}>Személyi hatály kiterjed az AOC System Zrt.-re Munkatársaira, különösen az AOC System rendszer üzemeltetésében részt vevő Munkatársakra, Partnerekre, továbbá a (regisztráló) felhasználókra és a már regisztrált felhasználókra.</li>
                  <li className={classes.text}>Tárgyi hatály: A Szabályzat tárgyi hatálya kizárólag
                    <ul>
                      <li>az AOC System Zrt. üzemeltetésében levő, AOC System rendszert kiszolgáló weboldal adatkezeléseire és ezzel összefüggésben levő adatokra,</li>
                      <li>az AOC System rendszer üzemeltetésével, működtetésével, funkcióival kapcsolatos, az AOC System Zrt. által végzett adatkezelésekre, valamint ezekkel összefüggésben levő adatokra, továbbá</li>
                      <li>az adatfeldolgozási megállapodás szerint, az AOC System Zrt. által a regisztrált felhasználók számára nyújtott adatfeldolgozásokra és ezekkel összefüggésben levő adatokra, valamint</li>
                      <li>a regisztrált felhasználók számára az AOC System rendszerében tiltott adatkezelésekre (adatfeldolgozásokra) terjed ki.</li>
                    </ul>
                  </li>
                  <li className={classes.text}>Jelen szabályzat tárgyi hatálya nem terjed ki az AOC System Zrt. nem AOC System rendszerrel kapcsolatos tevékenysége során történő adatkezelések és adatok.</li>
                  <li className={classes.text}>Területi hatály: Jelen Szabályzat területi hatálya az AOC System rendszert kiszolgáló weboldalra terjed ki a tárgyi hatályra tekintettel.</li>
                </ol>
                <Typography className={classes.title} variant='h5' id='fifthChapter'>AOC System Zrt., mint adatfeldolgozó által regisztrált felhasználók számára végzett adatkezelések (adatfeldolgozások)</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt. az AOC System rendszerbe regisztrált felhasználók által az AOC System rendszerébe rögzített, feltöltött harmadik személyek személyes (és különleges) adatai vonatkozásában al-adatfeldolgozóként, vagy al-al-adatfeldolgozóként működik közre függően attól, hogy a regisztrált felhasználó adatkezelőnek, adatfeldolgozónak, vagy al-adatfeldolgozónak minősül (Részletesen lásd a 2. fejezetben).</li>
                  <li className={classes.text}>Az AOC System Zrt. kijelenti, a (regisztrált) felhasználó tudomásul veszi, hogy az AOC System rendszerben elérhető funkciók és szolgáltatások, ha azok nem az AOC System Zrt., mint adatkezelő adatkezeléseit valósítják meg (lásd később), adatfeldolgozásnak minősülnek.</li>
                  <li className={classes.text}>Fentiek alapján adatfeldolgozásnak minősülnek többek között, de nem kizárólag a következő funkciók/szolgáltatások, és az adatfeldolgozás adatköreinek minősülnek az ezekkel összefüggésben az AOC System rendszerben tárolt adatok:</li>


                  <BasicTable
                    rows={registeredUsersTableRows}>

                  </BasicTable>

                  <Typography className={classes.subTitle2} color='#000'>Az (al-)adatfeldolgozó alvállalkozói (al-)al-adatfeldolgozói)</Typography>

                  <BasicTable
                    columns={subcontractorTableColumns}
                    rows={subcontractorTableRows}
                  ></BasicTable>


                  <li className={classes.text}>Az AOC System Zrt. és a felhasználó a GDPR 28. cikke és az Infotv 25/D. §-a alapján kötelesek adatfeldolgozási megállapodást kötni egymással, ezért felhasználó kizárólag akkor válhat regisztrált felhasználóval, ha a Szabályzattól formailag eltérő dokumentumban megfogalmazott szerződést bizonyítható módon – így pl. a regisztráció során tett nyilatkozattal – elfogadja.</li>
                  <li className={classes.text}>Az AOC System Zrt. kijelenti, felhasználó tudomásul veszi, hogy megfelelve az Infotv 25/E § (2) bekezdésének és a GDPR 30. cikk (2) bekezdésének, adatfeldolgozói nyilvántartást vezet.</li>
                </ol>
                <Typography className={classes.title} variant='h5' id='sixthChapter'>Az AOC System Zrt., mint adatkezelő által végzet adatkezelések</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt. bizonyos esetekben a felhasználók, továbbá az AOC System rendszerbe regisztrált felhasználók adatainak kezelése vonatkozásában adatkezelő.</li>
                  <li className={classes.text}>Adatkezelő kijelenti, a (regisztrált) felhasználó tudomásul veszi, hogy:</li>
                </ol>
                <ul>
                  <li className={classes.text}>az érintettek (így a felhasználók) jogaikkal (a hozzáférés joga, a helyesbítés joga, a törléshez való jog és az „elfeledtetéshez” való jog, az adatok zároláshoz/korlátozáshoz való jog, tiltakozáshoz való jog, az adathordozhatósághoz való jog, a hozzájárulás visszavonásának joga) az <a href="mailto:office@aocsystem.com" rel="noreferrer" target='_blank' style={{ textDecoration: 'none', color: '#000' }}>office@aocsystem.com</a> e-mail címre, vagy Adatkezelő más elérhetőségre küldött nyilatkozattal tehetik meg, továbbá jogaik megsértése esetén fordulhatnak panasszal a hatósághoz (mindenkori elérhetőséget lásd: NAIH, <a href='https://www.naih.hu/' style={{ textDecoration: 'none', color: '#000' }}>www.naih.hu</a>) vagy a lakóhely szerint illetékes bírósághoz és többek között sérelemdíjat követelhetnek. Adatkezelő felhívja az érintettek figyelmét, hogy joggyakorlásuknak lehetnek feltételei, valamint korlátai egy-egy adatkezeléssel kapcsolatban, amely tényezőket az Adatkezelő vizsgálni köteles az érintettek joggyakorlása esetén. Abban az esetben, ha egy jogával az érintett adott adatkezelés kapcsán nem élhet, úgy a joggyakorlást kizáró/korlátozó ténybeli és/vagy jogi indokokról az Adatkezelő írásban (ideértve az elektronikus utat is) tájékoztatja az érintettet és arról nyilvántartást vezet. Adatkezelő kijelenti, hogy az érintettek jogérvényesítését a belső adatvédelmi szabályozási portfólió részét képező munkautasításokkal is elősegíti.</li>
                  <li className={classes.text}>az egyes adatkezelések részletesebb magyarázatai az adott adatkezelés megnevezésére kattintva találhatók meg.</li>
                  <li className={classes.text}>profilalkotás egyik adatkezelés vonatkozásában sem történik.</li>
                  <li className={classes.text}>adatközlés harmadik személy felé adott adatkezelés vonatkozásában történhet, amelyet az adott adatkezelés részletes tájékoztatója fogalmaz meg.</li>
                  <li className={classes.text}>az adatokat az Adatkezelő jogosultsággal rendelkező Munkatársai, kizárólag a feladataik elvégzéséhez elengedhetetlenül szükséges mértékben kezelik az Adatkezelő belső adatvédelmi szabályozási portfóliója alapján.</li>
                  <li className={classes.text}>az alábbi összefoglaló tehát kizárólag az Adatkezelő olyan adatkezelési tevékenységeit foglalja össze, amelyek az AOC System rendszer üzemeltetésével, működtetésével, funkcióival kapcsolatos adatkezelések és alanya, érintettje a felhasználó, vagy az AOC System rendszerbe (már) regisztrált felhasználó.</li>

                  <Typography className={classes.subTitle2} color='#000'>Az AOC System rendszerbe (https://www.aocsystem.com oldalra) történő regisztráció során megadott adatokkal kapcsolatos adatkezelés</Typography>
                  <BasicTable
                    columns={dataManagementTableColumns}
                    rows={aocTableRows}
                  ></BasicTable>
                  <Typography className={classes.subTitle2} color='#000'>Bejelentkezés az AOC System rendszerbe (https://www.aocsystem.com/ oldalra)</Typography>
                  <BasicTable
                    columns={dataManagementTableColumns}
                    rows={logInTableRows}
                  ></BasicTable>
                  <Typography className={classes.subTitle2} color='#000'>Fiókkezelés az AOC System rendszerben</Typography>
                  <BasicTable
                    columns={dataManagementTableColumns}
                    rows={accountTableRows}
                  ></BasicTable>
                  <Typography className={classes.subTitle2} color='#000'>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatokhoz kapcsolódó adatkezelés összefoglaló táblázata</Typography>
                  <BasicTable
                    columns={dataManagementTableColumns}
                    rows={contactTalbeRows}
                  ></BasicTable>
                  <Typography className={classes.subTitle2} color='#000'>Számlakibocsátás során kezelt adatokkal kapcsolatos adatkezelés összefoglaló táblázata</Typography>
                  <BasicTable
                    columns={dataManagementTableColumns}
                    rows={invoiceOutTableRows}
                  ></BasicTable>
                  <li className={classes.text}>A feldolgozott személyes adatok kategóriái:
                    <ul>
                      <li>megőrzendő dokumentum személyes adat tartalma, például: név, lakcím/szállítási cím, bankszámlaszám, függően a dokumentum céljától</li>
                    </ul>
                  </li>
                  <li className={classes.text}>A személyes adatok vonatkozásában az érintettek kategóriái az alábbiak:
                    <ul>
                      <li>megbízó Partner maga, ha egyéni vállalkozó vagy adószámmal rendelkező magánszemély,</li>
                      <li>megbízó Partner képviselője,</li>
                      <li>megbízó Partner munkavállalója,</li>
                      <li>bárki, akinek adatát az irat tartalmazza</li>
                    </ul>
                  </li>
                  <li>Az adatfeldolgozás módja: papír alapon, manuálisan történik.</li>
                  <li>Adatok forrása: a megbízó Partnertől, mint adatkezelőtől.</li>
                  <li>Adatátadás módja az Adatkezelő felé: elektronikusan és/vagy papír alapon.</li>
                  <li>Adatközlés: nem történik.</li>
                </ul>
                <Typography className={classes.title} variant='h5' id='seventhChapter'>AOC System Zrt., mint adatkezelő nyilatkozatai</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt., mint adatkezelő kijelenti, hogy
                    <ul>
                      <li>az adatkezelés során a GDPR, továbbá a 2011. évi CXII. törvény, valamint a vonatkozó más jogszabályok rendelkezéseinek megfelelően jár el.</li>
                      <li>gondoskodik arról, hogy a Szabályzat a mindenkor hatályos jogszabályokkal összhangban legyen és azt az érintettek megismerhessék.</li>
                      <li>biztosítja, hogy a Szabályzat rendelkezése, valamint az Adatkezelő gyakorlata nem lehet ellentétes az adatkezelési elvekkel.</li>
                      <li>az adatkezelés során az Adatkezelő tudomására jutott személyes adatokat kizárólag azok a Munkatársak ismerhetik meg, akiknek az adott adatkezeléssel kapcsolatban feladatuk van.</li>
                      <li>az adatkezelés időtartama minden adatkezelési tevékenység esetében meghatározásra került az adatkezelés leírásában, azonban, ha az valamilyen hiba, hiányosság miatt nem alkalmazható, úgy a következő szabályokat kell alkalmazni:
                        <ul>
                          <li>a cél megvalósulásáig és a személyes adatainak törléséig, vagy</li>
                          <li>adatainak kezelésére vonatkozó engedélye visszavonásáig, vagy adatainak kezelési igényének visszavonásáig, és így személyes adatainak törléséig,</li>
                          <li>jogszabályban meghatározott határidő lejártáig,</li>
                          <li>bíróság vagy hatóság törlésre vonatkozó döntésének végrehajtásáig,</li>
                          <li>jogos érdek megszűnéséig,</li>
                          <li>jogi igény érvényesíthetőségéig tart (A hatályos Ptk. 6:22 § alapján az általános elévülési idő 5 év).</li>
                        </ul>
                      </li>
                      <li>a weboldal, ideértve az AOC System rendszert is, a látogatók személyes adatait bizalmasan, a hatályos jogszabályi előírásokkal összhangban kezeli, gondoskodik azok biztonságáról, technikai és szervezési intézkedéseket tesz, valamint az adatvédelem elveinek maradéktalan betartása érdekében eljárási szabályokat alakít ki.</li>
                      <li>a neki megadott személyes adatokat nem ellenőrzi, azok helytállóságáért felelősségét kizárja, kivéve, ha e feladatot jogszabály kötelezővé teszi.</li>
                      <li>a személyes adatokat harmadik személy részére csak kivételesen és abban az esetben továbbítja, valamint az általa kezelt adatbázist más adatkezelővel csak abban az esetben kapcsolja össze, ha annak jogszabályi feltételei fennállnak.</li>
                      <li>az adattovábbításokról nyilvántartást vezet, amely tartalmazza az Adatkezelő által kezelt személyes adatok továbbításának időpontját, az adattovábbítás jogalapját és címzettjét, a továbbított személyes adatok körének meghatározását, valamint az adatkezelést előíró jogszabályban meghatározott egyéb adatokat, ha jogszabály ilyeneket előír.</li>
                      <li>az adatvédelmi incidenssel kapcsolatos intézkedések ellenőrzése, valamint az érintett tájékoztatása céljából nyilvántartást vezet, amely tartalmazza az érintett személyes adatok körét, az adatvédelmi incidenssel érintettek körét és számát, az adatvédelmi incidens időpontját, körülményeit, hatásait és az elhárítására megtett intézkedéseket, valamint az adatkezelést előíró jogszabályban meghatározott egyéb adatokat, ha jogszabály ilyeneket előír.</li>
                      <li>a GDPR 33. cikke alapján a személyes adat jogellenes kezelése vagy feldolgozása esetén bejelentési kötelezettsége keletkezik a felügyelő hatóság, a Nemzeti Adatvédelmi és Információszabadság Hatóság felé. Az Adatkezelő indokolatlan késedelem nélkül – ha lehetséges, legkésőbb 72 órával azután, hogy az adatvédelmi incidens a tudomására jutott – megteszi a bejelentést a felügyeleti hatóságnak kivéve, ha az adatvédelmi incidens valószínűsíthetően nem jár kockázattal a természetes személyek jogaira és szabadságaira nézve.</li>
                    </ul>
                  </li>
                </ol>
                <Typography className={classes.title} variant='h5' id='eighthChapter'>Adatkezelésre és adatfeldolgozásra vonatkozó adatbiztonsági rendelkezések általánosságban</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt. mint adatkezelő és mint adatfeldolgozó is gondoskodik arról, hogy a vonatkozó jogszabályokban előírt adatbiztonsági szabályok érvényesüljenek.</li>
                  <li className={classes.text}>Az AOC System Zrt. és adatfeldolgozói biztosítják:
                    <ul>
                      <li className={classes.text}>Az adatkezeléshez használt eszközök (a továbbiakban: adatkezelő rendszer) jogosulatlan személyek általi hozzáférésének megtagadását a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozása</li>
                          <li>A munkaállomások kezelése</li>
                          <li>Adminisztratív intézkedések</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Az adathordozók jogosulatlan olvasásának, másolásának, módosításának vagy eltávolításának megakadályozását a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Nyomon követhetőség (naplózás)</li>
                          <li>Rosszindulatú software-ek kiszűrése</li>
                          <li>A munkaállomások kezelése</li>
                          <li>Fizikai hozzáférés védelem</li>
                          <li>Hálózati tevékenységek megfigyelése</li>
                          <li>Adminisztratív intézkedések</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Az adatkezelő rendszerbe a személyes adatok jogosulatlan bevitelének, valamint az abban tárolt személyes adatok jogosulatlan megismerésének, módosításának vagy törlésének megakadályozását a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Nyomon követhetőség (naplózás)</li>
                          <li>Rosszindulatú software-ek kiszűrése</li>
                          <li>A munkaállomások kezelése</li>
                          <li>Fizikai hozzáférés védelem</li>
                          <li>Hálózati tevékenységek megfigyelése</li>
                          <li>Adminisztratív intézkedések</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Az adatkezelő rendszerek jogosulatlan személyek általi, adatátviteli berendezés útján történő használatának megakadályozását a következő intézkedésekkel:
                        <ul>
                          <li>A munkaállomások kezelése</li>
                          <li>Karbantartás</li>
                          <li>Webhely biztonság</li>
                          <li>Hálózatbiztonság</li>
                          <li>Fizikai hozzáférés védelem</li>
                          <li>Hálózati tevékenységek megfigyelése</li>
                          <li>Hardware biztonság</li>
                          <li>Adminisztratív intézkedések</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Azt, hogy az adatkezelő rendszer használatára jogosult személyek kizárólag a hozzáférési engedélyben meghatározott személyes adatokhoz férjenek hozzá a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Nyomon követhetőség (naplózás)</li>
                          <li>Fizikai hozzáférés védelem</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Azt, hogy ellenőrizhető és megállapítható legyen, hogy a személyes adatokat adatátviteli berendezés útján mely címzettnek továbbították vagy továbbíthatják, illetve bocsátották vagy bocsáthatják rendelkezésére a következő intézkedésekkel,
                        <ul>
                          <li>Nyomon követhetőség (naplózás)</li>
                          <li>Nyilvántartások vezetése</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Azt, hogy utólag ellenőrizhető és megállapítható legyen, hogy mely személyes adatokat, mely időpontban, ki vitt be az adatkezelő rendszerbe a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Nyomon követhetőség (naplózás)</li>
                        </ul>
                      </li>
                      <li className={classes.text}>A személyes adatoknak azok továbbítása során vagy az adathordozó szállítása közben történő jogosulatlan megismerésének, másolásának, módosításának vagy törlésének megakadályozását a következő intézkedésekkel:
                        <ul>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Nyomon követhetőség (naplózás)</li>
                          <li>Fizikai hozzáférés védelem</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Azt, hogy üzemzavar esetén az adatkezelő rendszer helyreállítható legyen a következő intézkedésekkel
                        <ul>
                          <li>Rendszeres biztonsági mentések, tükrözés</li>
                        </ul>
                      </li>
                      <li className={classes.text}>Azt, hogy az adatkezelő rendszer működőképes legyen, a működése során fellépő hibákról jelentés készüljön, továbbá a tárolt személyes adatokat a rendszer hibás működtetésével sem lehessen megváltoztatni:
                        <ul>
                          <li>Rendszeres biztonsági mentések, tükrözés</li>
                          <li>Logikai hozzáférés szabályozás</li>
                          <li>Hálózatbiztonság</li>
                          <li>Hálózati tevékenységek megfigyelése</li>
                          <li>Adminisztratív intézkedések</li>
                          <li>A munkaállomások kezelése</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className={classes.text}>Az adatbiztonság szabályainak érvényesüléséről az AOC System Zrt. az adatvédelmi szabályozási portfólió más részeiben, az adatbiztonsági szabályozási portfólió egyes dokumentumaiban is gondoskodik azzal, hogy a fenti és más dokumentumokban található intézkedések meghatározásai az alábbiak:</li>

                  <Typography className={classes.subTitle2} >Titkosítás</Typography>
                  <Typography>Adatbázisban, fájl szinten, illetve biztonsági mentésként tárolt adatok bizalmasságának megőrzése érdekében használt eljárás. (VPN, TLS stb.).</Typography>
                  <Typography className={classes.subTitle2} >Anonimizálás</Typography>
                  <Typography>Olyan technika, amelynek eredményeként többé nem állítható létre a kapcsolat az adat és az érintett személye között, tehát az érintettel az adattal tovább már nem azonosítható.</Typography>
                  <Typography className={classes.subTitle2}>Az adatok különválasztása</Typography>
                  <Typography>A különböző adatok elválasztva kezelése, amellyel csökken annak az esélye, hogy a személyes adatok egymással összefüggésbe hozhatók legyenek egy esetleges adatvédelmi incidens bekövetkezése esetén.</Typography>
                  <Typography className={classes.subTitle2}>Logikai hozzáférés szabályozás</Typography>
                  <Typography>A felhasználók profiljának definiálása, kezelése. Hitelesítési eljárás, jelszóhasználati szabályok.</Typography>
                  <Typography className={classes.subTitle2} >Nyomon követhetőség (naplózás)</Typography>
                  <Typography>Hozzáférés, módosítás, törlés stb. nyomon követhetősége és naplózása az integritás irányelvének szem előtt tartásával.</Typography>
                  <Typography className={classes.subTitle2} >Rosszindulatú software-ek kiszűrése</Typography>
                  <Typography>A munkaállomásokra és a szerverekre telepített, a rosszindulatú software-ekkel szemben védő eszközök.</Typography>
                  <Typography className={classes.subTitle2} >A munkaállomások kezelése</Typography>
                  <Typography>Munkaállomásokra telepített védelmi eszközök (automatikus zárás, rendszeres frissítés, konfiguráció, fizikai biztonság stb.), amely csökkenti a software tulajdonságainak (operációs rendszerek, üzleti applikációk stb.) a személyes adatokra ártalmas kihasználhatóságát.</Typography>
                  <Typography className={classes.subTitle2}>Webhely biztonság</Typography>
                  <Typography>Webhely biztonsági ajánlások (HTTPS protokoll stb.) alkalmazása.</Typography>
                  <Typography className={classes.subTitle2}>Biztonsági mentés</Typography>
                  <Typography>A személyes adatok rendelkezésre állását és/vagy sértetlenségét és egyben bizalmas kezelésüket biztosító eljárások és eszközök.</Typography>
                  <Typography className={classes.subTitle2}>Karbantartás</Typography>
                  <Typography>A hardware karbantartásának eljárás</Typography>
                  <Typography className={classes.subTitle2}>Hálózatbiztonság</Typography>
                  <Typography>Az adatkezelést szolgáló hálózat tűzfala, behatolás érzékelése, illetve egyéb aktív vagy passzív rendszer.</Typography>
                  <Typography className={classes.subTitle2}>Fizikai hozzáférés védelem</Typography>
                  <Typography>Fizikai biztonsági intézkedések (az épület szakaszolása, vendégek kísérete, belépők viselése, ajtók zárása stb.)</Typography>
                  <Typography className={classes.subTitle2}>Hálózati tevékenységek megfigyelése</Typography>
                  <Typography>A behatolás észlelő és -megelőző rendszerek által végzett ellenőrzés révén a hálózati (vezetékes hálózatok, Wi-Fi, rádióhullám, száloptika) forgalom elemzése és a kibertámadás-gyanús tevékenységek észlelése.</Typography>
                  <Typography className={classes.subTitle2}>Hardware biztonság</Typography>
                  <Typography>Fizikai biztonságot befolyásoló védelmi intézkedések (biztonságos tárolás, biztonsági kábelek, titkossági szűrők, biztonsági törlés szemétbe dobás előtt stb.).</Typography>
                  <Typography className={classes.subTitle2}>Szervezet</Typography>
                  <Typography>Tudatosságnövelő adatvédelmi oktatások, valamint a mintegy ellenőrző mechanizmusként működő adatvédelmi tisztviselő ide sorolandó.</Typography>
                  <Typography className={classes.subTitle2}>Adminisztratív intézkedések</Typography>
                  <Typography>Különböző adatvédelmi szabályok, mint a jelen Szabályzat, belső adatvédelmi szabályzat, adatbiztonsági szabályzat, más belső utasítások, és az adatvédelmi szabályozási portfólió további elemei.</Typography>
                  <Typography className={classes.subTitle2}>Adatvédelmi kockázatok kezelése</Typography>
                  <Typography>Azok a rendelkezések, amelyek megállapítják az Adatkezelő által végzett, az adatkezelésre és az érintettek magánszférájára kockázatot jelentő adatkezelési műveletek megvizsgálására irányuló eljárásrendeket (kockázatok feltérképezése stb.).</Typography>
                  <Typography className={classes.subTitle2}>Álnevesítés</Typography>
                  <Typography marginBottom='20px'>Adatkezelő a tudomány és technológia állása és a megvalósítás költségei, továbbá az egyes adatkezelések jellege, hatóköre, körülményei és céljai, valamint a természetes személyek jogaira és szabadságaira, a változó valószínűségű és súlyosságú kockázat figyelembevételével olyan intézkedéseket hozhat, amelyek célja egyrészt az adatvédelmi elvek, másrészt a törvényi követelmények és érintettek jogainak védelméhez szükséges garanciák beépítése az adatkezelés folyamatába. Ennek körében Adatkezelő álnevesítést hajthat végre, ha azt törvény nem zárja ki és az adatkezelés jogalapjával és céljával összhangban van.</Typography>
                  <Typography marginBottom='20px'>A személyes adatok álnevesítése csökkentheti az érintettek számára a kockázatokat, valamint segíthet az Adatkezelőnek (és az adatfeldolgozó(i)nak, ha igénybe vesz adatfeldolgozót) abban, hogy az adatvédelmi kötelezettségeiknek megfeleljen(ek). Amennyiben Adatkezelő álnevesítést használ, akkor annak feltételeit külön szabályzatban állapítja meg és teszi közzé.</Typography>
                  <Typography marginBottom='20px'>Az álnevesített személyes adatokat, amelyeket további információ felhasználásával valamely természetes személlyel kapcsolatba lehet hozni, azonosítható természetes személyre vonatkozó adatnak kell tekinteni.</Typography>
                  <il className={classes.text}>Az AOC System Zrt. az adatok biztonságát szolgáló intézkedések meghatározásakor és alkalmazásakor tekintettel van a technika mindenkori fejlettségére és több lehetséges adatkezelési megoldás közül azt választja, amely a személyes adatok magasabb szintű védelmét biztosítja, kivéve, ha az aránytalan nehézséget jelentene.</il>
                  <il className={classes.text}>AOC System Zrt. az adatbiztonsági intézkedéseket évente felülvizsgálja.</il>
                  <il className={classes.text}>Tekintettel az AOC System rendszer céljára, az általa történő tevékenység igen bizalmas jellegére, az AOC System Zrt. az adatbiztonsági intézkedések általános leírásán túl részletes tájékoztatást kizárólag az arra jogosult Munkatársaknak, továbbá hivatalos megkeresés esetén hatóság, bíróság számára nyújt.</il>
                </ol>
                <Typography className={classes.title} variant='h5' id='ninethChapter'>Felhasználók, regisztrált felhasználók kötelezettségei</Typography>
                <ol>
                  <li className={classes.text}>A felhasználónak meg kell ismerniük, hogy mint adatkezelők, közös adatkezelők vagy mint adatfeldolgozók veszik igénybe az AOC System rendszert.</li>
                  <li className={classes.text}>A regisztrált felhasználó meg kell, hogy győződjön, hogy
                    <ul>
                      <li>harmadik személyek adatainak AOC System rendszerben történő rögzítéséhez megfelelő jogalappal rendelkezik;</li>
                      <li>harmadik személyek adatai AOC System rendszerben történő kezelésének megvan a jogszerű célja;</li>
                      <li>harmadik személyek AOC System rendszerben rögzítendő adatai a valóságnak megfelelőek, pontosak;</li>
                      <li>az adatok megszerzése, rögzítése, azokkal végzett más művelet jogszerűen, tisztességesen történik;</li>
                      <li>harmadik személyek számára az adatok AOC System rendszerben történő kezelése átláthatóan történik;</li>
                      <li>kizárólag a célhoz szükséges legkevesebb, legszükségesebb adatok felvétele, rögzítése történt meg az AOC System rendszerben;</li>
                      <li>a rögzített adatok kizárólag a legszükségesebb ideig kerülnek letárolásra az AOC System rendszerben;</li>
                      <li>harmadik személyek adatainak AOC System rendszerben történő rögzítése és további kezelése során betartásra kerül a bizalmasság alapelve;</li>
                      <li>harmadik személyek adatainak AOC System rendszerben történő rögzítése és további kezelése során az adatbiztonság követelménye betartásra kerül;</li>
                      <li>a GDPR, az Infotv és a regisztrált felhasználóra vonatkozó más jogszabályok előírásait folyamatosan betartja és szükség esetén megfelelő lépéseket, műveleteket tesz meg az adatvédelmi, adatbiztonsági szempontból helyes működés érdekében.</li>
                    </ul>
                  </li>
                  <li className={classes.text}>Az AOC System Zrt. nem vállal felelősséget semmilyen közvetlen, közvetett, véletlen, különleges vagy bármilyen más kárért, bírságért, sérelemdíjért, amely a felhasználók vagy regisztrált felhasználók olyan magatartásából vagy mulasztásából fakad, amely során a jelen Szabályzat és/vagy a GDPR, az Infotv, vagy más uniós vagy nemzeti jogszabályok adatvédelemre, adatkezelésre, adatbiztonságra vonatkozó rendelkezései nem kerültek maradéktalanul betartásra.</li>
                  <li className={classes.text}>A felhasználónak, regisztrált felhasználónak tilos
                    <ul>
                      <li>a weboldal, illetve az AOC System rendszerét az adatkezelés, valamint adatfeldolgozás eredeti céljával össze nem egyeztethető módon használnia;</li>
                      <li>olyan tevékenységet, működést, magatartást tanúsítania, amely veszélyezteti a weboldal, illetve az AOC System rendszer adatbiztonságát.</li>
                    </ul>
                  </li>
                  <li className={classes.text}>Abban az esetben, ha a felhasználó, regisztrált felhasználó az előző pontban meghatározott tiltást megszegi, úgy az AOC System Zrt. jogosult a regisztráció folyamatát vagy a regisztrált felhasználó hozzáférési jogosultságát azonnali hatállyal felfüggeszteni, továbbá vele szemben polgári jogi lépéseket vagy büntetőjogi feljelentést tenni.</li>
                </ol>
                <Typography className={classes.title} variant='h5' id='tenthChapter'>Egyéb rendelkezések</Typography>
                <ol>
                  <li className={classes.text}>Az AOC System Zrt. felhívja a figyelmet, hogy a jelen Szabályzat és Adatkezelési Tájékoztató (továbbiakban együttesen: Szabályzat) az AOC System Zrt. adatvédelmi szabályozásának része, nem használható sablonként, mintaként. Minden adatkezelőnek, adatfeldolgozónak a saját működése szerinti szabályozásra van szüksége.</li>
                  <li className={classes.text}>A Szabályzat szerzői jogi műnek minősül, tilos a Szabályzat egészének vagy részének, részletének másolása, többszörözése, nyilvánossághoz történő közvetítése, a mű mindenfajta eltorzítása, megcsonkítása, egészben vagy részben történő használata, feldolgozása, értékesítése (együtt: felhasználása) a szerző írásos hozzájárulása nélkül. A Szabályzat szerzője az AOC System Zrt. képviselője.</li>
                  <li className={classes.text}>A Szabályzat olvasója, felhasználója tudomásul veszi, hogy a felhasználási engedély nélküli felhasználás esetén a szerzőt kötbér illeti meg. A kötbér összege oldalanként bruttó 50.000.- Ft. A Szabályzat olvasója tudomásul veszi, hogy ezen kötbérkikötés nem túlzó, és ennek tudatában használja a Szabályzatot. Szerzői jogi jogsértés esetén az AOC System Zrt. közjegyzői ténytanúsítást alkalmaz, melynek összegét szintén a jogsértő felhasználóra hárítja.</li>
                </ol>

                <Typography>Kelt: 2020. május hó 19.</Typography>
                <Typography textAlign='center' marginBottom='20px' marginTop='20px'>Fodor Zsolt vezérigazgató</Typography>
                <Typography textAlign='center'>AOC System Zrt.</Typography>
                <Typography className={classes.title} variant='h5' id='dataInformation'>Részletes adatkezelési tájékoztató (https://www.aocsystem.com/ oldalon) az AOC System Zrt. által az AOC System rendszer kapcsán végzett adatkezelésekről</Typography>
                <Typography fontStyle='italic'>Jelen tájékoztató kizárólag az AOC System rendszer kapcsán végzett adatkezeléseket tartalmazza, és nem tartalmazza más tevékenységgel kapcsolatos adatkezelések leírásait.</Typography>
                <Typography>-</Typography>
                <Typography style={{ textDecoration: 'underline' }}>Milyen jogok illetik meg az érintetteket?</Typography>
                <Typography className={classes.text}>Az érintett jogai és jogalapok viszonyát a következő táblázat mutatja be, hogy az érintett számára egyértelmű legyen, hogy az alkalmazott jogalap esetén milyen jogaival élhet.</Typography>

                <BasicTable
                  columns={lawTableColumns}
                  rows={lawTableRows}
                ></BasicTable>

                <Typography className={classes.subTitle2}>Előzetes tájékozódáshoz való jog (Infotv 14. § a), GDPR 13-14. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy az adatkezeléssel összefüggő tényekről az adatkezelés megkezdését megelőzően tájékoztatást kapjon. E jog kapcsán az Adatkezelőt terheli a tájékoztatás kötelezettsége, amelyet – többek között – a jelen dokumentummal is teljesít.</Typography>
                <Typography className={classes.subTitle2}>Hozzáféréshez való jog (GDPR 15. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és az adatkezelés körülményeivel kapcsolatos információkhoz hozzáférést kapjon. Ha személyes adatoknak harmadik országba vagy nemzetközi szervezet részére történő továbbítására kerül sor, az érintett jogosult arra, hogy tájékoztatást kapjon a továbbításra vonatkozóan a 46. cikk szerinti megfelelő garanciákról. Az Adatkezelő az adatkezelés tárgyát képező személyes adatok másolatát az érintett rendelkezésére bocsátja, ha azokat érintett kéri.</Typography>
                <Typography className={classes.subTitle2}>Hozzájárulás visszavonásának joga (GDPR 7. cikk)</Typography>
                <Typography textAlign='justify'>Érintett jogosult arra, hogy a hozzájárulását bármikor visszavonja. A hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a visszavonás előtti adatkezelés jogszerűségét.</Typography>
                <Typography className={classes.subTitle2}>Helyesbítéshez való jog (GDPR 16. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat.</Typography>
                <Typography className={classes.subTitle2}>Tiltakozáshoz való jog (GDPR 21. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak a GDPR 6. cikk (1) bek. e) vagy f) pontján alapuló kezelése ellen.</Typography>
                <Typography>Ebben az esetben az Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha bizonyítja, hogy az adatkezelést olyan jogos okok indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és szabadságaival szemben.</Typography>
                <Typography className={classes.subTitle2}>Adatkezelés korlátozásához való jog (GDPR 18. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy kérésére az Adatkezelő korlátozza az adatkezelést, ha a GDPR-ban meghatározott feltételek bármelyike megvalósul, és ebben az esetben az Adatkezelő a tároláson kívül más műveletet az adattal ne végezzen.</Typography>
                <Typography>Ha az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.</Typography>
                <Typography className={classes.subTitle2}>Törléshez (elfeledtetéshez) való jog (GDPR 17. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, ha az adatkezelésnek nincs célja, hozzájárulását visszavonta és nincsen egyéb jogalapja, tiltakozás esetén nincs elsőbbséget élvező jogszerű ok az adatkezelésre, vagy ha az adatokat eleve jogellenesen kezelték, továbbá az adatokat jogi kötelezettség teljesítéséhez törölni kell. Ha az Adatkezelő nyilvánosságra hozta a személyes adatot, és azt törölni köteles, az elérhető technológia és a megvalósítás költségeinek figyelembevételével megteszi az ésszerűen elvárható lépéseket – ideértve technikai intézkedéseket – annak érdekében, hogy tájékoztassa az adatokat kezelő adatkezelőket, hogy az érintett kérelmezte tőlük a szóban forgó személyes adatokra mutató linkek vagy e személyes adatok másolatának, illetve másodpéldányának törlését.</Typography>
                <Typography className={classes.subTitle2}>Adathordozhatósághoz való jog (GDPR 20. cikk)</Typography>
                <Typography textAlign='justify'>Az érintett jogosult arra, hogy a rá vonatkozó, általa az Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes adatokat a rendelkezésére bocsátotta, ha jogszabályi feltételek (automatizált adatkezelés és hozzájárulás vagy megállapodás jogalap) fennállnak.</Typography>
                <Typography className={classes.subTitle2}>Érintett hol és hogyan kérhet részletes tájékoztatást az adatok kezeléséről, továbbításáról, valamint hol és hogyan élhet jogaival?</Typography>
                <Typography marginBottom='20px' textAlign='justify'>Adatkezelő felhívja az érintettek figyelmét, hogy az érintettek tájékoztatás kérésüket, hozzáférési jogukat, valamint egyéb jogaik gyakorlását Adatkezelő postai (1062 Budapest, Délibáb u. 8.) vagy e-mail-es elérhetőségre (office@aocsystem.com) küldött nyilatkozattal tehetik meg. Az Adatkezelő a nyilatkozatot a beérkezéstől számított legrövidebb időn belül megvizsgálja és megválaszolja, valamint megteszi a szükséges lépéseket a nyilatkozatban, a Belső Adatvédelmi Szabályzatban, valamint jogszabályban foglaltak alapján.</Typography>
                <Typography marginBottom='20px'>A hatóság elérhetősége panasz (GDPR 77. cikk) esetén:</Typography>
                <Typography marginBottom='20px'>Nemzeti Adatvédelmi és Információszabadság Hatóság</Typography>
                <Typography marginBottom='20px'>Cím:              1055 Budapest, Falk Miksa utca 9-11.</Typography>
                <Typography marginBottom='20px'>Telefon:        +36 (1) 391-1400</Typography>
                <Typography marginBottom='20px'>Fax:                +36 (1) 391-1410</Typography>
                <Typography marginBottom='20px'>web:                          <a href='https://www.naih.hu/' style={{ textDecoration: 'none', color: '#000' }}>www.naih.hu</a></Typography>
                <Typography marginBottom='20px'>e-mail:                      ugyfelszolgalat@naih.hu</Typography>
                <Typography marginBottom='20px'>Jogaival és a hatósághoz benyújtandó panasz részleteivel kapcsolatos további információért keresse fel az alábbi honlapot: <a href='http://naih.hu/panaszuegyintezes-rendje.html' style={{ textDecoration: 'none', color: '#000' }}>http://naih.hu/panaszuegyintezes-rendje.html</a></Typography>
                <Typography marginBottom='20px'>Az érintett a jogainak megsértése esetén a lakóhelye szerint illetékes bírósághoz is fordulhat és többek között sérelemdíjat követelhet.</Typography>
                <Typography marginBottom='20px'>A lakóhelye szerint illetékes bíróságot itt tudja megkeresni: <a href='https://birosag.hu/birosag-kereso' style={{ textDecoration: 'none', color: '#000' }}>https://birosag.hu/birosag-kereso</a></Typography>
                <Typography marginBottom='20px'>Az egyes adatkezelések önálló oldalón kezdődően kaptak helyet a jelen dokumentumban.</Typography>
                <Typography className={classes.title} id='registration'>Az AOC System rendszerbe (https://www.aocsystem.com/ oldalra) történő regisztráció során megadott adatokkal kapcsolatos adatkezelés</Typography>
                <Typography fontStyle='italic'>A regisztráció adatkezelése független attól, hogy meghívóval, vagy anélkül történik.</Typography>


                <Typography className={classes.subTitle2} color='#000'>Az AOC System rendszerbe (https://www.aocsystem.com oldalra) történő regisztráció során megadott adatokkal kapcsolatos adatkezelés</Typography>
                <BasicTable
                  columns={dataManagementTableColumns}
                  rows={aocTableRows}
                ></BasicTable>

                <ol>
                  <li className={classes.text}>Adatkezelő lehetővé teszi az érintettek számára, hogy weboldalára regisztráljanak (meghívóval vagy anélkül), hogy regisztráció után a weboldalon, mint AOC System rendszeren keresztül számukra elérhetőek legyenek a funkciók, szolgáltatások. A regisztráció egy több lépcsőből egymásra épülő folyamat.</li>
                  <Typography className={classes.text} fontWeight='bold'>Melyek a kezelt adatok körei és kezelésük céljai?</Typography>
                  <li className={classes.text}>A kezelt adatok köre és célja a regisztráció 1. fázisában:</li>
                  <Typography className={classes.text}>titulus:                                    azonosítás/megszólítás</Typography>
                  <Typography className={classes.text}>név*:                                        azonosítás/megszólítás</Typography>
                  <Typography className={classes.text}>e-mail cím*:                           azonosítás, kapcsolattartás</Typography>
                  <Typography className={classes.text}>jelszó*:                                     azonosítás/későbbi belépéshez szükséges</Typography>
                  {matches ?
                    <Typography className={classes.text}>ÁSZF/adatvédelmiszabályzat/adatfeldolgozási megállapodás/jogi nyilatkozat elfogadásának jelzése*: későbbi bizonyítás  </Typography>
                    :
                    <Typography className={classes.text}>ÁSZF/adatvédelmiszabályzat/<br />adatfeldolgozási megállapodás/jogi nyilatkozat elfogadásának jelzése*: későbbi bizonyítás  </Typography>
                  }
                  <Typography className={classes.text}>elfogadás jelzésének ideje*, IP címe*: azonosítás/későbbi bizonyítás</Typography>
                  <Typography className={classes.text}>AOC azonosító: a regisztráció során létrejövő álnevesített adat az adatállományban történő hivatkozáshoz</Typography>
                  <Typography className={classes.text} fontWeight='bold'>A regisztráció 2. fázisában kezelt adatok körei:</Typography>
                  <Typography className={classes.text}>Titulus:                                  megszólítás</Typography>
                  <Typography className={classes.text}>Előnév*:                               azonosítás, megszólítás</Typography>
                  <Typography className={classes.text}>Utónév*:                              azonosítás, megszólítás</Typography>
                  <Typography className={classes.text}>Születéskori név:               azonosítás</Typography>
                  <Typography className={classes.text}>Születési idő*:                     azonosítás</Typography>
                  <Typography className={classes.text}>Születési hely*:                   azonosítás</Typography>
                  <Typography className={classes.text}>Anyja születéskori neve*:</Typography>
                  <Typography className={classes.text}>Állampolgárság*:               azonosítás</Typography>
                  <Typography className={classes.text}>Állandó lakcím:                  azonosítás, hivatalos kapcsolattartás</Typography>
                  <Typography className={classes.text}>Mobiltelefonszám*:           kapcsolattartás</Typography>
                  <Typography className={classes.text}>E-mail cím*:                       kapcsolattartás</Typography>
                  <Typography className={classes.text}>Felhasználói csoport: azonosítás, jogosultságok kiosztása</Typography>
                  <Typography className={classes.text} fontWeight='bold'>A regisztráció 3. fázisában kezelt adatok körei (amennyiben van ilyen fázis):</Typography>
                  <Typography className={classes.text}>fizetési mód</Typography>
                  <Typography className={classes.text}>fizetés teljesítése, nyomonkövetése</Typography>
                  <Typography className={classes.text}>előfizetői időszak jogosultságok biztosítása</Typography>
                  <Typography className={classes.text}>számlázási típus   számlakiállítás</Typography>
                  <Typography className={classes.text}>számlázási adatok díjbekérő/számlakiállítás</Typography>
                  <Typography className={classes.text} fontWeight='bold'>A regisztráció 4. fázisa (amennyiben van ilyen fázis):</Typography>
                  <Typography className={classes.text}>sikeres vagy</Typography>
                  <Typography className={classes.text}>sikertelen fizetés* bizonyítás, további folyamathoz szükséges</Typography>
                  <Typography className={classes.subTitle2}>Mi az adatok kezelésének jogalapja?</Typography>
                  <li className={classes.text}>Az adatkezelés jogalapja az 1-2 fázisban kezelt személyes adathoz kapcsolódóan:
                    <ul>
                      <li>önkéntes hozzájáruláson (GDPR 6. cikk (1) a) pontja) alapul.</li>
                      <li>Értelmezhető úgy is, hogy az adatkezelés megállapodáson (GDPR 6. cikk (1) b) pont második fordulata) alapul, mert a regisztráció az érintett kérésére történő jogviszony létrehozását megelőző lépés, amely jogviszony részleteit a vonatkozó ÁSZF tartalmazza.</li>
                    </ul>
                  </li>
                  <li className={classes.text}>Az adatkezelés jogalapja az 3-4 fázisban kezelt személyes adathoz kapcsolódóan:
                    <ul>
                      <li>Adatkezelő jogos érdeke (GDPR 6. cikk (1) f) pontja), amely az előfizetés lehetővé tételében és végrehajtásában ölt testet.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Kik az érintettek?</Typography>
                  <li className={classes.text}>Az érintettek köre: Minden természetes személy, aki az AOC System rendszerbe történő regisztráció során megadott adatokkal beazonosítható, beazonosított.</li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés fő célja?</Typography>
                  <li className={classes.text}>Az adatok kezelésének fő célja az érintett adatainak rögzítése, jogosultságainak, kedvezményeinek, hozzáférésének megadása, érvényesítése, ellenőrzése, a szolgáltatások igénybevételének megkönnyítése, valamint a kapcsolattartás.</li>
                  <Typography className={classes.subTitle2}>Hogyan történik az adatkezelés?</Typography>
                  <li className={classes.text}>Az adatkezeléssel érintett tevékenység és folyamat:
                    <ul>
                      <li>Érintett a https://www.aocsystem.com/ weboldal egy meghatározott felületén keresztül a fent meghatározott adatait betáplálja, jelölőnégyzetben jelöli az általános szerződési feltételek, a Szabályzat és a jogi nyilatkozat elolvasását, megértését, tudomásul vételét, valamint jelölőnégyzetben jelöli az adatfeldolgozási megállapodás elfogadását, és az adatokat (kattintással) eljuttathatja az Adatkezelő részére.</li>
                      <li>A megadott adatok a weboldalt kiszolgáló szerverre titkosított csatornán keresztül, https protokollt használva jutnak el.</li>
                      <li>A rendszer a megadott e-mail címre megerősítő e-mailt küld ki.</li>
                      <li>Amennyiben felhasználó a megerősítő e-mail-ben szereplő linkre a levélben meghatározott határidőn belül rákattint, úgy a regisztráció folytatódik a következő fázisokkal, míg végül a felhasználóból regisztrált felhasználó lesz és az AOC System rendszerbe jogosult lesz belépni.</li>
                      <li>Amennyiben a felhasználó az e-mail-ben szereplő megerősítő linkre a meghatározott határidőn belül nem kattint rá, nem erősíti meg a regisztrációját, úgy a regisztráció első fázisában megadott adatokat a rendszer automatikusan törli a határidő lejártát követően.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Meddig tart az adatkezelés?</Typography>
                  <li className={classes.text}>Adatkezelés időtartama a legkorábbi feltétel beálltáig tart:
                    <ul>
                      <li>érintett kérésére törlésig, ha az érintett felé számla nem került kiállításra, vagy</li>
                      <li>ha számviteli bizonylat került kiállításra, úgy a regisztráció során megadott adatokat az Adatkezelő 5 évig őrzi meg (bizonyítási célból, törlési kérelem esetén azokat zárolja);</li>
                      <li>jogos érdek megszűnéséig (pl. ha Adatkezelő jogos érdeke a regisztráció adatainak megőrzése annak ellenére, hogy érintett kéri az adatok törlését például várható jogvita miatt). Ekkor Adatkezelő a jogos érdekét érdekmérlegelési teszttel köteles alátámasztani.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Honnan vannak az adatok?</Typography>
                  <li className={classes.text}>Adatok forrása: közvetlenül az érintettől.</li>
                  <Typography className={classes.subTitle2}>Történik adatközlés (hozzáférés nyújtás, átadás, továbbítás) harmadik fél számára?</Typography>
                  <li className={classes.text}>Adatokat az Adatkezelő informatikai Partnere, mint adatfeldolgozó kezeli tárhelyszolgáltatás és rendszer-karbantartás céljából, egyébként adatok harmadik fél számára akkor kerülnek közlésre, ha azt érintett kifejezetten kéri, vagy ha az jogszabályi kötelezettségen alapul.</li>

                  <BasicTable
                    columns={dataManagerTalbeColumns}
                    rows={dataManagerTableRows}
                  ></BasicTable>

                  <Typography className={classes.subTitle2}>Hogyan biztosítja az adatok védelmét az Adatkezelő?</Typography>
                  <li className={classes.text}>Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: tudomány és technika állásának megfelelő https protokoll használata, egyebeket lásd külön fejezetben.</li>
                  <Typography className={classes.subTitle2}>Történik automatizált döntéshozatal, profilalkotás?</Typography>
                  <li className={classes.text}>Automatizált döntéshozatal, profilalkotás: amennyiben automatizált döntéshozatalnak tekintendő az, hogy amennyiben a megerősítés nem történik meg a meghatározott határidőben és így a regisztráció során megadott adatokat a nyilvántartási rendszer automatikusan törli, úgy történik, de más automatizált döntéshozatal, vagy profilalkotás nem történik.</li>
                  <Typography className={classes.subTitle2}>Egyéb</Typography>
                  <li className={classes.text}>A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja, így az érintett az AOC System rendszerébe nem tud regisztrálni.</li>
                </ol>
                <Typography textAlign='center' color='#000' style={{ textDecoration: 'underline' }} id='signIn'><a href='/' style={{ textDecoration: 'none', color: '#000' }}>Bejelentkezés az AOC System rendszerbe (https://www.aocsystem.com/ oldalra)</a> </Typography>

                <BasicTable
                  columns={dataManagementTableColumns}
                  rows={logInTableRows}
                ></BasicTable>

                <ol>
                  <li className={classes.text}>Regisztrált felhasználó (mint érintett) az AOC System rendszerbe bejelentkezhet.</li>
                  <Typography className={classes.subTitle2}>Melyek a kezelt adatok körei, kezelésük céljai, jogalapja, időtartama?</Typography>
                  <li className={classes.text}>A kezelt adatok köre, célja, jogalapja, az adatkezelés időtartama a következő:</li>

                  <BasicTable
                    columns={dataPurposeTableColumns}
                    rows={dataPurposeTableRows}
                  ></BasicTable>

                  <Typography className={classes.subTitle2}>Kik az érintettek?</Typography>
                  <li className={classes.text}>Az érintettek köre: Minden természetes személy, aki az AOC System rendszerbe történő bejelentkezés alapján beazonosítható, beazonosított.</li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés fő célja?</Typography>
                  <li className={classes.text}>Az adatok kezelésének fő célja az AOC System rendszerbe bejelentkező érintettek azonosítása, jogosultságainak biztosítása, ellenőrzése.</li>
                  <Typography className={classes.subTitle2}>Hogyan történik az adatkezelés?</Typography>
                  <li className={classes.text}>Az adatkezeléssel érintett tevékenység és folyamat:
                    <ul>
                      <li>Érintett a weboldal egy meghatározott felületén keresztül a fent meghatározott adatokat megadhatja és azokat (kattintással) eljuttathatja az Adatkezelő részére.</li>
                      <li>A megadott adatok a weboldalt kiszolgáló szerverre titkosított csatornán keresztül, https protokoll használatával jutnak el.</li>
                      <li>Amennyiben az e-mail cím és jelszó páros létezik, úgy a szerver a felhasználót beengedi az oldalra.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Honnan vannak az adatok?</Typography>
                  <li className={classes.text}>Adatok forrása: közvetlenül az érintettől.</li>
                  <Typography className={classes.subTitle2}>Történik adatközlés (hozzáférés nyújtás, átadás, továbbítás) harmadik fél számára?</Typography>
                  <li className={classes.text}>Adatokat az Adatkezelő informatikai Partnere, mint adatfeldolgozó kezeli tárhelyszolgáltatás és rendszer-karbantartás céljából, egyébként adatok harmadik fél számára akkor kerülnek közlésre, ha azt érintett kifejezetten kéri, vagy ha az jogszabályi kötelezettségen alapul.</li>

                  <BasicTable
                    columns={dataManagerTalbeColumns}
                    rows={dataManagerTableRows}
                  ></BasicTable>

                  <Typography className={classes.subTitle2}>Történik automatizált döntéshozatal, profilalkotás?</Typography>
                  <li className={classes.text}>Automatizált döntéshozatal, profilalkotás: amennyiben automatizált döntéshozatalnak tekintendő az, hogy az e-mail cím és jelszó párosának ellenőrzése automatizáltan megtörténik a belépés engedélyezéséhez, úgy történik, de más automatizált döntéshozatal, vagy profilalkotás nem történik.</li>
                  <Typography className={classes.subTitle2}>Egyéb</Typography>
                  <li className={classes.text}>A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatás nyújtását (adatkezelést) megtagadja, így az érintett az AOC System rendszerbe belépni nem tud.</li>
                </ol>
                <Typography className={classes.title} variant='h5' id='accManagement'>Fiókkezelés az AOC System rendszerben</Typography>
                <Typography className={classes.subTitle2} color='#000'>Fiókkezelés az AOC System rendszerben</Typography>
                <BasicTable
                  columns={dataManagementTableColumns}
                  rows={accountTableRows}
                ></BasicTable>

                <ol>
                  <li className={classes.text}>Adatkezelő lehetővé teszi, hogy az AOC System rendszerbe bejelentkezett regisztrált felhasználó fiókjának adatait módosítsa, helyesbítse.</li>
                  <Typography className={classes.subTitle2}>Melyek az adatkezelés során kezelt adatok körei?</Typography>
                  <li className={classes.text}>A kezelt adatok köre:
                    <ul>
                      <li>az AOC System rendszer/fiókom/személyes adatok menüpontban meghatározott adatkörök (pl. születési adatok, elérhetőségi adatok)</li>
                      <li>az AOC System rendszer/fiókom/jelszóváltoztatás adatok menüpontban meghatározott adatkörök (így új jelszó)</li>
                      <li>az AOC System rendszer/fiókom/előfizetésem menüpontban meghatározott adatkörök (pl. előfizetés kapcsán: kezdete, befizetés módja, számla adatai, előfizetési csomag adatai, befizetett összeg, művelet, előfizetés lejárta; időbélyeg kapcsán: időszak kezdete, vége, vásárolt, felhasznált időbélyegek száma, vásárolt időbélyegek adatai, túlhasználat adatai, státusz, extra tárhely vásárlása kapcsán: időpont, mennyiség, ár, fizetési mód adatai, számla adatai)</li>
                      <li>amennyiben érintett adatot módosít, helyesbít, úgy letárolásra (naplózásra) kerülnek a következők: a belépés időpontja, AOC azonosító, IP cím, adatkör módosításának időpontja.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés jogalapja?</Typography>
                  <li className={classes.text}>A jogalap: jogi kötelezettség teljesítése (GDPR 6. cikk (1) c)) tekintettel arra, hogy az érintett számára lehetővé kell tenni, hogy jogaival, így többek között a hozzáférés (megismerés), helyesbítés (GDPR 16. cikk) jogával élhessen, amely kötelezettséget az Adatkezelő a fiókkezeléssel tesz lehetővé.</li>
                  <Typography className={classes.subTitle2}>Kik az érintettek?</Typography>
                  <li className={classes.text}>Az érintettek köre: Minden regisztrált felhasználó, aki az AOC System rendszerbe belép.</li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés célja?</Typography>
                  <li className={classes.text}>Az adatkezelés célja az, hogy az érintett megismerhesse a rá vonatkozó adatokat, szükség esetén helyesbítse azokat.</li>
                  <Typography className={classes.subTitle2}>Hogyan történik az adatkezelés?</Typography>
                  <li className={classes.text}>Az adatkezeléssel érintett tevékenység és folyamat:
                    <ul>
                      <li>Érintett az AOC System rendszer egy meghatározott felületén keresztül elérheti a fent meghatározott menüpontokban az adatköröket, azokat megismerheti, és bizonyos adatkörök kapcsán egyszerűen kezdeményezheti az adatok módosítását, helyesbítését.</li>
                      <li>A helyesbített adatok az AOC System rendszert kiszolgáló szerverre titkosított csatornán keresztül, https protokoll használatával jutnak el és ott letárolásra kerülnek.</li>
                      <li>A módosítás időpontja és módosítójának fenti adatai letárolásra kerülnek.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Meddig tart az adatkezelés?</Typography>
                  <li className={classes.text}>Adatkezelés időtartama
                    <ul>
                      <li>érintett által kért fióktörlésig, vagy</li>
                      <li>jogos érdek megszűnéséig (pl. ha Adatkezelő jogos érdeke az adatok megőrzése annak ellenére, hogy érintett kéri az adatok törlését például várható jogvita miatt).</li>
                      <li>olyan adatok vonatkozásában, amelyek számviteli bizonylatra kerültek, vagy számviteli bizonylatot támasztanak alá, a számviteli tv. szerint 8 évig.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Honnan vannak az adatok?</Typography>
                  <li className={classes.text}>Adatok forrása: közvetlenül az érintettől.</li>
                  <Typography className={classes.subTitle2}>Történik adatközlés harmadik fél számára?</Typography>
                  <li className={classes.text}>Adatokat az Adatkezelő informatikai Partnere, mint adatfeldolgozó kezeli tárhelyszolgáltatás és rendszer-karbantartás céljából, egyébként adatok harmadik fél számára akkor kerülnek közlésre, ha azt érintett kifejezetten kéri, vagy ha az jogszabályi kötelezettségen alapul.</li>
                </ol>

                <BasicTable
                  columns={dataManagerTalbeColumns}
                  rows={dataManagerTableRows}
                ></BasicTable>

                <Typography className={classes.title} variant='h5' id='contact'>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatokhoz kapcsolódó adatkezelés tájékoztatója</Typography>
                <Typography marginBottom='50px' fontStyle='italic'>Példálózó felsorolással élve ilyen az elektronikus alapú kapcsolattartás, mint az e-mail, vagy a postai úton vagy telefonon történő kapcsolattartás stb.<span fontStyle='italic' style={{ textDecoration: 'underline' }}>
                  A kapcsolattartás ebben az adatkezelésben azt jelenti, hogy nem egyszeri alkalomról van szó.
                </span></Typography>
                <Typography fontStyle='italic' marginBottom='50px'>Ide tartozik a kapcsolatápolás ügyfelekkel, és az aktív ügyfelek telefonos megkeresése is.</Typography>

                <Typography className={classes.subTitle2} color='#000'>Érintettel történő folyamatos, rendszeres kapcsolattartás során kezelt adatokhoz kapcsolódó adatkezelés összefoglaló táblázata</Typography>
                <BasicTable
                  columns={dataManagementTableColumns}
                  rows={contactTalbeRows}
                ></BasicTable>

                <ol>
                  <li className={classes.text}>Adatkezelő biztosítja, hogy az érintett vele különböző módokon és fórumokon folyamatosan, vagy rendszeresen kapcsolatot tartson. Példálózó felsorolással élve ilyen az elektronikus alapú kapcsolattartás, mint az e-mail, vagy a postai úton vagy telefonon történő kapcsolattartás stb. (Például érintettel folyatott levelezés.)</li>
                  <Typography className={classes.subTitle2}>Melyek a kezelt adatok körei, kezelésük céljai, jogalapja és időtartama?</Typography>
                  <li className={classes.text}>A kezelt adatok köre, célja, jogalapja és tárolási ideje:</li>

                  <BasicTable
                    columns={dataHandlingTalbeColumns}
                    rows={dataHandlingTalbeRows}>

                  </BasicTable>

                  <Typography className={classes.subTitle2}>Kik az érintettek?</Typography>
                  <li className={classes.text}>Az érintettek köre: Minden természetes személy, ideértve szervezet nevében, képviseletében eljáró természetes személyt is, aki az egyszeri információkérésen túl folyamatosan, vagy rendszeresen kapcsolatot tart az Adatkezelővel.</li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés fő célja?</Typography>
                  <li className={classes.text}>Az adatok kezelésének fő célja az érintettel történő kapcsolattartás, felmerülő kérdések, kérések és egyebek megválaszolása, megoldása.</li>
                  <Typography className={classes.subTitle2}>Hogyan történik az adatkezelés?</Typography>
                  <li className={classes.text}>Az adatkezeléssel érintett tevékenység és folyamat tipikusan, de nem kizárólagosan a következő:
                    <ul>
                      <li>Az érintett az Adatkezelő által biztosított, számára elérhető úton keresztül vagy módon, példálózó felsorolással élve szóban (személyesen, telefonon) vagy írásban (postai úton, elektronikus levélben) felveszi a kapcsolatot, vagy kapcsolatot tart az Adatkezelővel és kérdést, kérést vagy egyebet intéz az Adatkezelő felé.</li>
                      <li>Kapcsolatot az Adatkezelő is felveheti az érintettel kérdése megválaszolása, vagy az Adatkezelő jogos érdeke alapján.</li>
                      <li>A kérdés, kérés, az adott tájékoztatás tartalma és a jogszabályok, belső szabályzatok alapján az Adatkezelő megteszi a szükséges lépéseket.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Honnan vannak az adatok?</Typography>
                  <li className={classes.text}>Adatok forrása: közvetlenül az érintettől.</li>
                  <Typography className={classes.subTitle2}>Történik adatközlés (hozzáférés nyújtás, átadás, továbbítás) harmadik fél számára?</Typography>
                  <li className={classes.text}>Adatok harmadik fél számára nem kerülnek közlésre, kivéve, ha azt érintett kifejezetten kéri, vagy ha jogszabályi kötelezettségen alapul.</li>
                  <Typography className={classes.subTitle2}>Hogyan biztosítja az adatok védelmét az Adatkezelő?</Typography>
                  <li className={classes.text}>Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: részletesen lásd külön fejezetben.</li>
                  <Typography className={classes.subTitle2}>Történik automatizált döntéshozatal, profilalkotás?</Typography>
                  <li className={classes.text}>Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.</li>
                  <Typography className={classes.subTitle2}>Egyéb</Typography>
                  <li className={classes.text}>A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy amennyiben az érintett azokat az Adatkezelő számára nem szolgáltatja, úgy az Adatkezelő a szolgáltatást (adatkezelést) nem tudja nyújtani, elvégezni.</li>
                </ol>
                <Typography marginTop='50px' marginBottom='50px'>-</Typography>

                <Typography className={classes.title} variant='h5' id='invoice'>Számlakibocsátás során kezelt adatokkal kapcsolatos adatkezelés</Typography>

                <Typography marginBottom='30px' fontStyle='italic'>Ide tartozik az e-számla és hagyományos számla kibocsátása is, valamint minden, a számviteli bizonylattal egy tekintet alá eső dokumentum kibocsátása, ha a dokumentum személyes adatokat tartalmaz.</Typography>


                <Typography className={classes.subTitle2} color='#000'> Számlakibocsátás során kezelt adatokkal kapcsolatos adatkezelés összefoglaló táblázata</Typography>
                <BasicTable
                  columns={dataManagementTableColumns}
                  rows={invoiceOutTableRows}
                ></BasicTable>

                <ol>
                  <li className={classes.text}>Adatkezelő jogszabályi kötelezettségek teljesítése miatt számlakibocsátásra kötelezett.</li>
                  <Typography className={classes.subTitle2}>Melyek a kezelt adatok körei és kezelésük céljai?</Typography>
                  <li className={classes.text}>Kezelt adatok köre és célja: A 2007. évi CXXVII. tv. 169-170. §-ban és 176.§-ban megfogalmazott adatkategóriák, különösen, de nem kizárólagosan a vevő (mint érintett) neve és címe. Az adatok kezelésének célja a jogszabályi előírások betartása.</li>
                  <Typography className={classes.subTitle2}>Mi az adatok kezelésének jogalapja? </Typography>
                  <li className={classes.text}>Az adatkezelés jogalapja bármelyik személyes adathoz kapcsolódóan jogi kötelezettség teljesítése a 2007. évi CXXVII. törvény, valamint az e törvény felhatalmazása alapján kiadott rendeletek szerint.</li>
                  <Typography className={classes.subTitle2}>Kik az érintettek?</Typography>
                  <li className={classes.text}>Az érintettek köre: minden természetes személy, ideértve az egyéni vállalkozót is, akinek adata az Adatkezelő által kibocsátott számla (vagy azzal egy tekintet alá eső számviteli bizonylat) alapján azonosítható, azonosított.</li>
                  <Typography className={classes.subTitle2}>Mi az adatkezelés fő célja?</Typography>
                  <li className={classes.text}>Az adatok kezelésének fő célja a számlakibocsátással kapcsolatos jogszabályi kötelezettségek maradéktalan teljesítése.</li>
                  <Typography className={classes.subTitle2}>Hogyan történik az adatkezelés?</Typography>
                  <li className={classes.text}>Az adatkezeléssel érintett tevékenység és folyamat különösen, de nem kizárólagosan a következő:
                    <ul>
                      <li>Adatkezelő az érintett felé teljesít, az érintett pedig az Adatkezelő felé pénzügyi teljesítést indít meg. A teljesítés a számla kibocsátás alapja.</li>
                      <li>Érintett az Adatkezelő számára adatszolgáltatást hajt végre a számla kibocsátásához, a számla kötelező tartalmi elemeinek rögzítéséhez. Amennyiben az érintett nem szolgáltat adatot, vagy valóságnak nem megfelelő adatot szolgáltat, úgy Adatkezelő nyugtát vagy más, személyes adatot nem tartalmazó, de számvitelileg megfelelő dokumentumot bocsát ki. Amennyiben az Adatkezelő a teljesítését megelőzően már tudomással bír arról, hogy az érintett nem bocsát vagy nem valós adatokat bocsát a rendelkezésére, úgy köteles a teljesítést visszatartani, érintett számára nem szolgáltatni.</li>
                      <li>Adatkezelő az adatokat feldolgozza, a számlát kiállítja és adatfeldolgozó könyvelő Partnere felé továbbítja azokat, ha ilyen Partner szolgáltatásait igénybe veszi.</li>
                      <li>Adatkezelő, vagy Adatkezelő adatfeldolgozója a számlák adattartalmát az illetékes adó- és vámhivatal felé továbbítja.</li>
                      <li>Adatkezelő a számlát a vonatkozó időtartamban papír alapon, vagy ha e-számláról van szó, elektronikus úton megőrzi.</li>
                    </ul>
                  </li>
                  <Typography className={classes.subTitle2}>Meddig tart az adatkezelés?</Typography>
                  <li className={classes.text}>Adatkezelés időtartama a legkorábbi feltétel beálltáig tart: évi C. törvény 169. §-a alapján az üzleti év plusz 8 év.</li>
                  <Typography className={classes.subTitle2}>Honnan vannak az adatok?</Typography>
                  <li className={classes.text}>Adatok forrása: közvetlenül az érintettől, ritkán közhiteles nyilvántartásból.</li>
                  <Typography className={classes.subTitle2}>Történik adatközlés (hozzáférés nyújtás, átadás, továbbítás) harmadik fél számára?</Typography>
                  <li className={classes.text}>Adatközlés: könyvelő adatfeldolgozó, valamint az adó- és vámhatóság felé. Adatközlés történhet még könyvvizsgáló felé, hatósági és bírósági ügyben hatóság, bíróság felé.</li>
                  <Typography className={classes.subTitle2}>Hogyan biztosítja az adatok védelmét az Adatkezelő?</Typography>
                  <li className={classes.text}>Szervezési és technikai intézkedések a kezelt adatok védelme érdekében: lásd külön fejezetben.</li>
                  <Typography className={classes.subTitle2}>Történik automatizált döntéshozatal, profilalkotás?</Typography>
                  <li className={classes.text}>Automatizált döntéshozatal, profilalkotás: az adatkezelés kapcsán ilyen nem történik.</li>
                </ol>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default PrivacyPolicy
