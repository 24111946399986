// import typography from './typography';

const font =  "'Montserrat', sans-serif";
//const font =  "'Quicksand', sans-serif";

const typography = {
    fontFamily: font,
    h1: {
      //fontWeight: 500,
      fontSize: 75,
      //letterSpacing: '-0.24px'
    },
    // h2: {
    //   fontWeight: 500,
    //   fontSize: 29,
    //   letterSpacing: '-0.24px'
    // },
    h3: {
      fontWeight: 500,
      fontSize: 32,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      //letterSpacing: '-0.06px'
    },
    // h5: {
    //   fontWeight: 500,
    //   fontSize: 16,
    //   letterSpacing: '-0.05px'
    // },
    // h6: {
    //   fontWeight: 500,
    //   fontSize: 14,
    //   letterSpacing: '-0.05px'
    // },
    // overline: {
    //   fontWeight: 500
    // }
  };
  
  export default typography;