import React from 'react'
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import LightboxGallery from '../components/LightboxGallery';
import IMG_8534 from "../static/images/gallery/IMG_8534.JPG";
import IMG_8547 from "../static/images/gallery/IMG_8547.JPG";
import IMG_8556 from "../static/images/gallery/IMG_8556.JPG";
import IMG_8592 from "../static/images/gallery/IMG_8592.JPG";
import IMG_8597 from "../static/images/gallery/IMG_8597.JPG";
import IMG_8601 from "../static/images/gallery/IMG_8601.JPG";
import IMG_8642 from "../static/images/gallery/IMG_8642.JPG";
import IMG_8646 from "../static/images/gallery/IMG_8646.JPG";
import IMG_8676 from "../static/images/gallery/IMG_8676.JPG";
import IMG_8679 from "../static/images/gallery/IMG_8679.JPG";
import IMG_8683 from "../static/images/gallery/IMG_8683.JPG";
import IMG_8690 from "../static/images/gallery/IMG_8690.JPG";
import IMG_9352 from "../static/images/gallery/IMG_9352.jpg";
import IMG_9373 from "../static/images/gallery/IMG_9373.JPG";
import IMG_9392 from "../static/images/gallery/IMG_9392.JPG";
import IMG_9413 from "../static/images/gallery/IMG_9413.JPG";
import Untitled1 from "../static/images/gallery/Untitled1.jpg";
import Untitled2 from "../static/images/gallery/Untitled2.jpg";
import Untitled3 from "../static/images/gallery/Untitled3.jpg";
import Untitled4 from "../static/images/gallery/Untitled4.jpg";
import birdOfParadiseLogoImg from '../static/images/Birds_of_Paradise_logo_timesheet.png';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  heroImage: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: 'auto',
  },
  images: {
    width: '220px',
    cursor: 'pointer',
    '&:hover': {
      padding: 1
    }
  }
}))

const bigRoom = [
  {
    name: 'img_8534',
    description: 'Nagy terem',
    size: '2784-1856',
    main: IMG_8534,
    thumb: IMG_8534
  },
  {
    name: 'IMG_8547',
    description: 'Nagy terem',
    size: '2784-1856',
    main: IMG_8547,
    thumb: IMG_8547
  },
]

const smallRoom = [
  {
    name: 'Untitled1',
    description: 'Kis terem',
    size: '2784-1856',
    main: Untitled1,
    thumb: Untitled1
  },
]

const images = [
  {
    title: 'img_8534',
    caption: 'Nagy terem',
    size: '2784-1856',
    main: IMG_8534,
    thumb: IMG_8534
  },
  {
    title: 'IMG_8547',
    caption: 'Nagy terem',
    size: '2784-1856',
    main: IMG_8547,
    thumb: IMG_8547
  },
  {
    title: 'IMG_8556',
    caption: '',
    size: '1856-2784',
    main: IMG_8556,
    thumb: IMG_8556
  },
  {
    title: 'IMG_8592',
    caption: '',
    size: '1856-2784',
    main: IMG_8592,
    thumb: IMG_8592
  },
  {
    title: 'IMG_8597',
    caption: '',
    size: '1856-2784',
    main: IMG_8597,
    thumb: IMG_8597
  },
  {
    title: 'IMG_8601',
    caption: '',
    size: '1856-2784',
    main: IMG_8601,
    thumb: IMG_8601
  },
  {
    title: 'IMG_8642',
    caption: '',
    size: '1856-2784',
    main: IMG_8642,
    thumb: IMG_8642
  },
  {
    title: 'IMG_8646',
    caption: '',
    size: '1856-2784',
    main: IMG_8646,
    thumb: IMG_8646
  },
  {
    title: 'IMG_8676',
    caption: '',
    size: '1856-2784',
    main: IMG_8676,
    thumb: IMG_8676
  },
  {
    title: 'IMG_8679',
    caption: '',
    size: '2784-1856',
    main: IMG_8679,
    thumb: IMG_8679
  },
  {
    title: 'IMG_8683',
    caption: '',
    size: '2784-1856',
    main: IMG_8683,
    thumb: IMG_8683
  },
  {
    title: 'IMG_8690',
    caption: '',
    size: '1856-2784',
    main: IMG_8690,
    thumb: IMG_8690
  },
  {
    title: 'IMG_9352',
    caption: '',
    size: '1856-2784',
    main: IMG_9352,
    thumb: IMG_9352
  },
  {
    title: 'IMG_9373',
    caption: '',
    size: '1856-2784',
    main: IMG_9373,
    thumb: IMG_9373
  },
  {
    title: 'IMG_9392',
    caption: '',
    size: '1856-2784',
    main: IMG_9392,
    thumb: IMG_9392
  },
  {
    title: 'IMG_9413',
    caption: '',
    size: '1856-2784',
    main: IMG_9413,
    thumb: IMG_9413
  },
  {
    title: 'Untitled1',
    caption: 'Kis Terem',
    size: '2784-1856',
    main: Untitled1,
    thumb: Untitled1
  },
  {
    title: 'Untitled2',
    caption: '',
    size: '2784-1856',
    main: Untitled2,
    thumb: Untitled2
  },
  {
    title: 'Untitled3',
    caption: '',
    size: '1856-2784',
    main: Untitled3,
    thumb: Untitled3
  },
  {
    title: 'Untitled4',
    caption: '',
    size: '1856-2784',
    main: Untitled4,
    thumb: Untitled4
  },
]

function Gallery() {
  const classes = useStyles();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const {t} = useTranslation();
  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          mt={5}
          data-aos='fade'
        >
          <Grid
            item
            style={{ marginBottom: '50px' }}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              alt='birds-of-paradise-home-logo'
              src={birdOfParadiseLogoImg}
              className={classes.heroImage}
              style={{ width: lgDown ? '50%' : '100%', height: 'auto' }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            // mt={37}
            mt={{ lg: 20, md: 0 }}
          >
            <Typography
              variant="h1"
              color="#95436c"
            >
              {t("Gallery")}
              {/* Galéria */}
            </Typography>
            {/* <Typography variant="h6">
              Hiszünk a sokszínűségben, abban, hogy mindenki kipróbálhatja, és megtalálhatja a neki leginkább megfelelő jóga típust. Legyen szó dinamikusabb, magasabb intenzitású vagy lágyabb, relaxáló típusú óráról.
              A rendszeres gyakorlás segítségével elengedheted fizikális, és mentális feszültségedet, erősödsz, formálod tested és lelked.
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          mt={20}
          mb={20}
          spacing={3}
        >
          <Grid
            item
            md={6}
          >
            <Typography
              variant="h3"
              mb={2}
              sx={{
                position: 'relative',
                '&:after': {
                  backgroundColor: 'primary.main',
                  bottom: '-8px',
                  content: '" "',
                  height: '3px',
                  left: 0,
                  position: 'absolute',
                  width: '48px'
                }
              }}
            >
              {t("gallery1")}
              {/* Nagy terem */}
            </Typography>
            <Grid container>
              <Grid item>
                <LightboxGallery propertyImages={bigRoom} width={180} height={250} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
          >
            <Typography
              variant="h3"
              mb={2}
              sx={{
                position: 'relative',
                '&:after': {
                  backgroundColor: 'primary.main',
                  bottom: '-8px',
                  content: '" "',
                  height: '3px',
                  left: 0,
                  position: 'absolute',
                  width: '48px'
                }
              }}
            >
              {/* Kis terem */}
              {t("gallery2")}
            </Typography>
            <Grid container>
              <Grid item>
                <LightboxGallery propertyImages={smallRoom} width={180} height={250} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          mb={10}
        >
          <Typography
            variant="h3" mb={2}
            sx={{
              position: 'relative',
              '&:after': {
                backgroundColor: 'primary.main',
                bottom: '-8px',
                content: '" "',
                height: '3px',
                left: 0,
                position: 'absolute',
                width: '48px'
              }
            }}
          >
            {/* Összes kép */}
            {t("gallery3")}
          </Typography>
          <Grid item>
            <LightboxGallery propertyImages={images} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Gallery