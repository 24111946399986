import { Box, Container, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({

  text: {
    textAlign: 'justify',
    marginTop: '30px'
  }
})

function AboutUs() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:900px)');

  document.title = t('Title.main') + " | " + t('Title.subTitle.aboutUs')

  return (
    <React.Fragment>
      <Paper>
        <Box
          className='main'
          minHeight='100vh'
          sx={{
            background: 'white',
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Container >
            <Grid
              className='main'
              spacing={3}
              container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography marginBottom={10} variant='h2' fontWeight='bold' style={{ fontSize: matches ? '3em' : '2.5em' }} textAlign='center' color='primary'>Rólunk</Typography>
                <Typography className={classes.text}>{t('company.aboutUs.description1')}</Typography>
                <Typography className={classes.text}>{t('company.aboutUs.description2')}</Typography>
                <Typography className={classes.text}>{t('company.aboutUs.description3')}</Typography>
                {/* <Typography className={classes.text}>{t('company.aboutUs.description4')}</Typography>
                <Typography className={classes.text}>{t('company.aboutUs.description5')}</Typography> */}
              </Grid>
              {/* <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-end'
                aligntItems='flex-end'
              >
                <Typography className={classes.text}>{t('company.aboutUs.greetings')}</Typography>

              </Grid> */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-end'
              >
                <Typography className={classes.text} variant='h5' color='primary'>{t('company.aboutUs.aocTeam')}</Typography>

              </Grid>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default AboutUs
