import { Box, Container, Grid, Paper, Typography, Avatar } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import InstructorDialog from "./InstructorDialog";
// import Birds_of_Paradise_logo_bg from '../static/images/Birds_of_Paradise_logo_bg.png'
import AndiImg from "../static/images/Andi.jpg";
// import KatiImg from '../static/images/Kati.png'
import OrsiImg from "../static/images/Orsi.jpg";
import TamasImg from "../static/images/Tamas.jpg";
import wandaImg from "../static/images/wanda.JPG";
import ZsuzsiImg from "../static/images/ZsuzsiImg.jpg";
import BarbiImg from "../static/images/BarbiImg.jpg";
import VargaMarika from "../static/images/VargaMarikaImg.jpg";

function Instructors() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});

  const handleClickOpen = (userInfo) => {
    setUserInfo(userInfo);
    if (userInfo.description) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  document.title = t("Title.main") + " | " + t("Title.subTitle.instructors");

  const users = [
    {
      name: "Andi Szalai",
      description:
        "andiintro",
      // "A sport mindig nagy szerepet játszott az életemben. 2010-ben kezdtem jógázni, mert a futás mellett úgy gondoltam nagyon jó kiegészítés lesz. A Bikram jóga mellett döntöttem, izgalmasan hangzott a 40 fokos teremben 40%-os párában mozogni. Az első óra nagyon nehéz volt, de mivel nem vagyok egy feladós típus kitartóan gyakoroltam. Néhány hét után éreztem, hogy megérkeztem. Teljesen kicserélődtem. \n 2012-ben nagy változást akartam az életemben, így eldöntöttem, hogy megcsinálom a Bikram jóga tréninget. Elutaztam L.A., megkaptam a változást. Életem legszebb és legnehezebb 9 hete volt, de megérte. Mikor haza jöttem azonnal tanítani kezdtem, pedig nem is ez volt a célom. Már több mint 3000 órát tartottam az elmúlt 10 évben és minden pillanatát élveztem. 2017-ben elvégeztem egy vinyasza flow oktatói tréninget is. 2018-ban újra Bikram tréningen találtam magam Mexikóban, hiszen a folyamatos fejlődés nagyon fontos. 2020-ban Brightonban végeztem az Inferno Hot Pilates tréninget, ami nagyon intenzív volt. Pont olyan, mint egy Inferno óra. Nagyon szeretem látni a vendégeken a folyamatos fejlődést, ahogy inspirálják egymást és amikor feltöltődve mosolyognak az óra végén. Remélem minél több emberrel sikerül megszerettetnem akár a jógát akár az IHP-t, és hamarosan találkozunk az óráimon.",
      src: AndiImg,
    },
    {
      name: "Barbi Hauser",
      description:
        "barbiintro",
      // "Sziasztok! Hosszas bemutatkozás helyett inkább azt javaslom, gyere el az órámra, próbáld ki milyen és ismerj meg. Nem baj, ha van humorérzéked és segít, ha magadon is tudsz nevetni.",
      src: BarbiImg,
    },
    // {
    //   name: "Réti Kati",
    //   description: "18 éves koromban voltam először jógázni (1981 Baross utca). Akkor még a jóga nem volt ennyire elterjedt, népszerű, de tudtam, hogy 80 éves koromig (ha megélem) az életem része marad. \n\r\n Hosszú szünet után a jóga 2006-ban jött vissza az életembe, akkor már a Bikram jógával. Sportos múltamból kiindulva gondoltam simán menni fog. Az első órán azt hittem meghalok, utána 3 órát aludtam, de éreztem, hogy ez kell nekem. \n\r\n 2011-ben elvégeztem Los Angelesben az oktatói tréninget. Azóta oktatom, már 2000 órához közel járok. Mosolygós, elégedett arcokat látni az órám végén nagy töltődés. Úgy gondolom, hogy jógázni mindenkinek kellene, akkor is, ha más aktív sportot űz, vagy ha még soha nem csinált semmit. \n\r\n A jóga egy mosógép mindenki számára: testi, lelki, szellemi felfrissülés, töltődés. Egy öttusázó vendégem szavaival élve: “A jóga 3 az 1-ben. Olyan, mintha elmennél futni, szaunáznál, és megmasszíroznának.” Szeretném, ha minél több ember érezné azt, amit a jógázóink már igen. \n\r\n Az első lépést kell megtenni a célok megvalósításához, a többi jön magától!",
    //   src: KatiImg,
    // },
    {
      name: "Orsi Jakab",
      description:
        "orsiintro",
      // "Tizenöt éve kezdtem el Bikram jógázni, azóta ez a mindennapjaim szerves része, én ezt már Szerelemnek hívom:) Hiszen tényleg egymás nélkül nehezen bírjuk, legalábbis én biztos:) Sokáig versenysportoltam, szinkron úsztam, és versenyszerűen úsztam is. Miután megszületett a két gyermekem továbbra is kerestem azt, hogy mi lenne az a mozgásforma, amit szívesen csinálnék, és egyben kihívás is lenne. Ekkor toppant be az életembe a Bikram Jóga. Azóta minden gyakorlás egy újabb tapasztalás, kihívás és élmény. Tisztítja az elmét, tudatosabbá tesz, méregtelenít, feltölti a testet és a lelket. Fizikálisan pedig a különböző betegségek gyógyításában és a prevencióban is nagymertékben segít. \n A Bikram mellett a Yin jógát is szeretettel gyakorlom és tanítom is, ami az előbbi nagyszerű kiegészítője. Itt hosszan kitartott nyújtásokban vagyunk, ezzel a meditáció élményét is megtapasztalhatja a gyakorló.",
      quote:
        t("colheoq"),
      // '"Mindent nyomtalanul elfúj a szél, ami megtörténhetett volna, de nem történt meg. (...) Úgy alakul az élet, ahogy mi alakítjuk."',
      quoteAuthor: "Paulo Coelho",
      src: OrsiImg,
    },
    {
      name: "Tamás Vályi",
      description:
        "tamasintro",
      // "2002-ben jártam először jógaórán a floridai Fort Lauderdale-ben. Előtte semmit nem tudtam a jógáról és még csak hajlékony sem voltam. Mindezt azért írom le, hátha sikerül a kételkedőket megnyugtatnom, hogy nincs szükség előképzettségre vagy hajlékonyságra a jógához. \n\r\n Két év jógázás után 2004-ben elvégeztem a Bikram jóga oktatói tanfolyamot Los Angelesben. 2005-től a budapesti Bikram stúdiókban tartottam órákat. \n\r\n És hogy miért a Bikram Yoga? 20 év tapasztalattal a hátam mögött továbbra is azt gondolom, hogy nincs még egy ilyen egyszerű és hatékony módszer a testi, lelki egészség megőrzésére, adott esetben visszaállítására.",
      src: TamasImg,
    },
    // {
    //   name: "Zsuzsi Sas",
    //   description:
    //     "zsuzsiintro",
    //   // "Igazán boldognak és szerencsésnek érzem magam, hogy olyan munkám, hivatásom van, amit szeretettel, és lelkesedéssel tudok végezni. Gyerekkoromtól kezdve érdeklődtem a különböző mozgásformák, mozgásművészetek iránt, felnőttként már leginkább ezek emberekre gyakorolt hatása, terápiás része vonzott. A jógában mindezeket együtt megtaláltam. A Bikram jóga számomra megunhatatlan, és úgy érzem, mindig van hová fejlődnöm benne. Motivált, hogy ezt az élményt továbbadjam másoknak is, így 2013 tavaszán Los Angelesben elvégeztem a Bikram jóga oktatói képzést, majd később a gerinc- és a yin- és női jóga oktatói képzést. Mindegyik irányzatot szeretem gyakorolni, továbbadni és oktatni. Fontos számomra a biztonságos, sérülésmentes, tudatos gyakorlás. Namaste",
    //   src: ZsuzsiImg,
    // },
    {
      name: "Wanda Huszlicska",
      description:
        "wandaintro",
      // "Négy és tizennégy éves korom között csak a balett érdekelt, sehogy máshogy nem tudtam magam elképzelni, csak balerinaként. Az elképzelésem az azt követő évek során formálódott, de a mozgás mindig az életem része maradt. A táncos-művészi mozgásformák mellett úsztam, kosárlabdáztam, majd tizenöt évvel ezelőtt, épp egy nehéz élethelyzet közepén, jött szembe velem a jóga, hatha formájában. Nagyon sok segítséget kaptam tőle, és azt éreztem, hogy minél több fajtáját szeretném kipróbálni. Így következett rohamos gyorsasággal a Bikram, Vinyasa Flow, és a Yin. Nálam mindegyik szerelem volt első mozdulatra, és pár alkalom után rádöbbentem, hogy mindhármat képes vagyok fanatikusként űzni. Idő kellett, hogy rájöjjek, számomra a jógában a legnagyobb kihívás az egyensúly megtalálása (fizikális, és szellemi síkon is). Hiszek abban, hogy ászanázás közben a test mellett a személyiséget is nagyszerűen lehet formálni, stresszt, félelmeket, elakadásokat, és mentális blokkokat oldani.",
      src: wandaImg,
    },
    {
      name: "Marika Varga",
      description:
        "marikaintro",
      // "A mozgás kicsi koromtól nagyon fontos az életemben. A ritmikus sportgimnasztika az alapokat és a mozgás szeretetét már gyermekkorban megadta, illetve megtanított küzdeni és kitartani. Közgazdászként végeztem a Corvinus egyetemen, a pénzügyi terület mellett a mozgás előtérben maradt.  A tánc és egyéb mozgásformák után a jóga következett, közben pedagógiai tanulmányokat is végeztem. Gyermekeim nevelése mellett a jógával 16 éve foglalkozom, gyakorlom, tanulom, oktatom. Szerencsésnek érzem magam, mert Japánban ismerkedhettem meg a jóga filozófiájával, ami nagy hatást gyakorolt rám, meghatározza az életem. A hatha-vinyasa típusú gyakorlással kezdtem, rögtön egy oktatóképzéssel Tokióban, majd jött a Yin és a gyerekjóga Szingapúrban, a Hot és az Inferno Pilates már Magyarországon. Azóta is folyamatosan mélyítem egyéni gyakorlásom és tudásom, kívánom, hogy mindenki érezze azt a külső és belső harmóniát, amit egy jóga óra, illetve a jógázás es a rendszeres, tudatos mozgás nyújthat.",
      src: VargaMarika,
    },
  ];

  return (
    <React.Fragment>
      <Paper>
        <Box

          className="main"
          minHeight="80vh"
          sx={{
            py: 10,
          }}
        >
          <Container>
            <Typography
              marginBottom={15}
              textAlign="center"
              variant="h2"
              color="primary"
              fontWeight="bold"
            >
              {/* Ismerd meg oktatóinkat */}
              {t("trainerintro")}
            </Typography>
            {/* <Typography marginBottom={15} textAlign='center' variant='h3'>Ismerd meg őket:</Typography> */}
            <Grid
              spacing={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              container
              marginBottom={10}
            >
              {users.map((user) => (
                <Grid item lg={4} md={4} xl={4} sm={6} xs={10} key={user.name}>
                  <div
                    style={{
                      // cursor: 'pointer',
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleClickOpen(user)}
                  >
                    <Avatar
                      alt={user.name}
                      src={user.src}
                      sx={{
                        width: 150,
                        height: 150,
                        marginBottom: 1,
                      }}
                    />
                    <Typography variant="h5">{t(user.name)}</Typography>
                    <Typography variant="subtitle1">
                      {t("introduction")}
                      {/* Bemutatkozás */}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Paper>
      <InstructorDialog open={open} user={userInfo} handleClose={handleClose} />
    </React.Fragment>
  );
}

export default Instructors;
