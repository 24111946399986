export default function Logo(props) {
  const {logo, ...rest} = props;

  return (
    <img
      alt={'bop-logo'}
      src={logo}
      {...rest}
    />
  )
}