import { Box, Container, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  text: {
    textAlign: 'justify',
    marginTop: '30px',
    fontWeight: 'bold'
  },
  description: {
    textAlign: 'justify',
    marginTop: '10px',
    whiteSpace: 'pre-line'
  }
})

function Rules() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:900px)');

  const rulesText = [
    {
      text: t('company.rules.subTitle1'), children: {
        text: t('company.rules.subTitle1Description')
      },
    },
    {
      text: t('company.rules.subTitle2'), children: {
        text: t('company.rules.subTitle2Description')
      }
    },
    {
      text: t('company.rules.subTitle3'), children: {
        text: t('company.rules.subTitle3Description')
      }
    },
    {
      text: t('company.rules.subTitle4'), children: {
        text: t('company.rules.subTitle4Description')
      }
    },
    {
      text: t('company.rules.subTitle5'), children: {
        text: t('company.rules.subTitle5Description')
      }
    },
    {
      text: t('company.rules.subTitle6'), children: {
        text: t('company.rules.subTitle6Description')
      }
    },
    {
      text: t('company.rules.subTitle7'), children: {
        text: t('company.rules.subTitle7Description')
      }
    },
    {
      text: t('company.rules.subTitle8'), children: {
        text: t('company.rules.subTitle8Description')
      }
    },
    {
      text: t('company.rules.subTitle9'), children: {
        text: t('company.rules.subTitle9Description')
      }
    },
    {
      text: t('company.rules.subTitle10'), children: {
        text: t('company.rules.subTitle10Description')
      }
    },
    {
      text: t('company.rules.subTitle11'), children: {
        text: t('company.rules.subTitle11Description')
      }
    },
    {
      text: t('company.rules.subTitle12'), children: {
        text: t('company.rules.subTitle12Description')
      }
    },
  ]

  document.title = t('Title.main') + " | " + t('Title.subTitle.houseRule');

  return (
    <React.Fragment>
      <Paper>
        <Box
          className='main'
          minHeight='100vh'
          sx={{
            background: 'white',
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Container >
            <Grid
              className='main'
              spacing={3}
              container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography
                  marginBottom={10}
                  variant='h2'
                  fontWeight='bold'
                  style={{ fontSize: matches ? '3em' : '2.5em' }}
                  textAlign='center'
                  color='primary'
                >
                  {t('company.rules.mainTitle')}
                </Typography>
                <Typography
                  variant="h4"
                  marginBottom="80px"
                >
                  {t('company.rules.mainTitleDescription')}
                </Typography>
              </Grid>
              <Grid container>
                <Grid item>
                  <ol>
                    {rulesText.map(({ text, children }) => (
                      <>
                        <li>
                          <Typography className={classes.text}>
                            {text}
                          </Typography>
                        </li>
                        <Typography className={classes.description}>
                          {children.text}
                        </Typography>
                      </>
                    ))}
                    {/* <li>cím2</li>
                    <Typography>Szöveg2</Typography> */}
                  </ol>
                </Grid>
              </Grid>
              {/* <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent='flex-end'
              >
                <Typography className={classes.text} variant='h5' color='primary'>{t('company.aboutUs.aocTeam')}</Typography>

              </Grid> */}
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default Rules
