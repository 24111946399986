/* eslint-disable */
//NOTE: disable eslint only until deploy

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home'
import Instructors from './pages/Instructors'
import Timesheet from './pages/Timesheet'
import Introduction from './pages/Introduction'
import Prices from './pages/Prices'
import NotFound from './pages/NotFound'
import Privacy from './pages/Privacy'
import Impressum from './pages/company/Impressum';
import AboutUs from './pages/company/AboutUs'
import Rules from './pages/company/Rules'
import Gallery from './pages/Gallery'
import TermsAndConditions from './pages/Information/TermsAndConditions';
import Contact from './pages/company/Contact';
import PrivacyPolicy from './pages/Information/PrivacyPolicy';
import DataManagementInformation from './pages/Information/DataManagementInformation';
import Discount from './pages/Discount';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/introduction' component={Introduction} />
      <Route exact path='/instructors' component={Instructors} />
      <Route exact path='/timesheet' component={Timesheet} />
      <Route exact path='/gallery' component={Gallery} />
      <Route exact path='/prices' component={Prices} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/about' component={AboutUs} />

      <Route exact from="/" component={Home} />
      <Route exact path='/discount' component={Discount} />
      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/imprint' component={Impressum} />
      <Route exact path='/rules' component={Rules} />
      <Route exact path='/terms-and-conditions' component={TermsAndConditions} />

      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/data-management-information' component={DataManagementInformation} />

      <Route path="*" to="/404" component={NotFound} />
    </Switch>
  )
}

export default Routes;
