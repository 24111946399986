import { Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import Footer from "../src/components/Footer/Footer";
import Navigation from "../src/components/Navigation/Navigation";
import "./App.css";
import Cookie from "./components/Cookie/Cookie";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Routes from "./routes";
import ScrollToTop from "./scrollToTop";
import theme from "./theme";
import DiscountDialog from "./components/DiscountDialog";
// import Snowfall from 'react-snowfall'

function App() {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: "ease-in-out",
  });

  const endDateOfDiscount = "2024-09-16"; // NOTE: the last day of the discount plus one, so it will not be open on that day
  const isThereDiscount = true;

  const isDiscountOpen = (endDate) => {
    if (isThereDiscount) {

      const today = new Date();
      const endDateOfDiscountDate = new Date(endDate);
      return today < endDateOfDiscountDate;
    }
    return false
  }


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper>
        {isDiscountOpen(endDateOfDiscount) && <DiscountDialog />}
        <Router>
          <ScrollToTop />
          <Navigation />
          <Routes />
          <Footer />
          <ScrollToTopButton />
          <Cookie />
        </Router>
        {/* <Snowfall
          style={{
            // position: 'fixed',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            pointerEvents: 'none'
          }}
          // color="#5191f0"
          // speed={[5, 10]}
          // radius={[1, 4]}
          // wind={[1, 3]}
          snowflakeCount={100}
        /> */}
      </Paper>
    </ThemeProvider>
  );
}

export default App;
