import * as React from "react";
import {
  Container,
  Divider,
  // Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import birdOfParadiseLogoDiscountImg from '../static/images/Birds_of_Paradise_home_logo.png';
// import { makeStyles } from '@material-ui/styles';

// const useStyles = makeStyles(theme => ({
//   image: {
//     textAlign: 'right',
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'flex-end',
//     margin: 'auto',
//   },
// }))

const Discount = () => {
  // const classes = useStyles();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Grid
        container
        mb={10}
        mt={10}
      >
        {/* <Grid item xs={12}>
          <Stack>
            <Typography
              mt={5}
              variant="h3"
              color="primary"
              fontWeight="bold"
              textAlign="center"
            >
              Szuper őszi akció!
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              fontWeight="bold"
              mb={5}
              textAlign="center"
            >
              Szeptember 20. és 25. között 30%-os kedvezménnyel vásárolhatod meg
            </Typography>
          </Stack>
          <Grid item md={12} sm={12} alignItems="center">
            <Typography textAlign="left" m={1}>
              🍂 10 alkalmas
            </Typography>
            <Typography textAlign="left" m={1}>
              🍂 30 napos
            </Typography>
            <Typography textAlign="left" m={1}>
              🍂 Féléves
            </Typography>
            <Typography textAlign="left" m={1}>
              🍂 Éves bérleteinket.
            </Typography>
            <Typography mt={4} ml={1.7} variant="body1" textAlign="left">
              A bérletek érvényessége legkésőbb szeptember 30-án indul, az akció más kedvezményekkel nem vonható össze.
            </Typography>
          </Grid>
        </Grid> */}
        {/* <Grid
          item
          md={12}
          xs={12}
        >
          <Grid container>
            <Grid item
              md={12}
              sx={12}
            >
              <Typography
                mt={5}
                variant="h3"
                color="primary"
                fontWeight="bold"
                mb={5}
                textAlign="center"
              >
                {t("xmasText")}
              </Typography>
              <ul
                style={{
                  listStyle: 'none',
                  marginLeft: '-30px',
                }}
              >
                <li style={{ marginBottom: 5 }}>
                  {t("xmasText2")}
                </li>
                <li style={{ marginBottom: 5 }}>
                  {t("xmasText3")}
                </li>
                <li>
                  {t("xmasText4")}
                </li>
              </ul>
              <Typography variant="caption">
                {t("xmasText5")}
              </Typography>
            </Grid>
            <Stack mt={2}>
              <Typography textAlign="left" sx={{ whiteSpace: "pre-wrap" }}>
                <ul
                  style={{
                    listStyle: 'none',
                    marginLeft: '-30px',
                  }}
                >
                  <li style={{ marginBottom: 5 }}>
                    {t("xmasText6")}
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    {t("xmasText7")}
                  </li>
                  <li>
                    {t("xmasText8")}
                  </li>
                </ul>
                <Typography>{t("xmasText9")}</Typography>
                <Typography>{t("xmasText10")}</Typography>
                <p>{t("xmasText11")}</p>
                <p>{t("xmasText12")}</p>
                <ul
                  style={{
                    listStyle: 'none',
                    marginLeft: '-30px',
                  }}
                >
                  <li style={{ marginBottom: 5 }}>
                    {t("xmasText13")}
                  </li>
                  <li style={{ marginBottom: 5 }}>
                    {t("xmasText14")}
                  </li>
                </ul>
                <p>{t("xmasText15")}</p>
                <p>{t("xmasText16")}</p>
              </Typography>
            </Stack>
          </Grid>
        </Grid> */}
        <Grid item md={12} xs={12} mt={0}>
          <Grid container>
            <Grid item md={12} sx={12}>
              <Typography variant="h3" color="primary" fontWeight="bold" textAlign="center" mb={8}>
                {/* <li> */}
                {/* Hozz magaddal még egy embert! */}
                {t("disc1")}
                {/* </li> */}
              </Typography>
              {/* <Typography
                mt={0}
                variant='body1'
                textAlign="left"
                fontWeight="bold"
              >
                A nagy sikerre való tekintettel a Hozz magaddal még egy embert! akciónkat meghosszabbítottuk!
              </Typography> */}
              <Typography
                mt={1}
                // variant='body1'
                textAlign="left"
                variant="body1"
                fontWeight="bold"
              >
                <li>
                  {/* Azon túl, hogy nálunk nem lesz áremelés, ismét szuper akciókkal jelentkezünk. */}
                  {t("disc2")}
                  {/* Amennyiben hozol magaddal még egy (vagy több:)) embert, aki eddig nem járt nálunk,
                  mindketten fél áron vásárolhattok 5 és 10 alkalmas, illetve havi bérletet. */}
                </li>
              </Typography>
              <Typography mt={1} variant="body1" textAlign="left">
                {/* Azon túl, hogy nálunk nem lesz áremelés, ismét szuper akciókkal jelentkezünk. */}
                {/* Mit is jelent ez pontosan ha sikerül valakit elcsábítanod? */}
                {t("disc3")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            // display="flex"
            alignItems="center"
            mt={0}
            p={lgDown ? 1 : 2}
          >
            <Grid item md={6} sm={6} alignItems="center">
              <Typography textAlign="left" m={1}>
                🔥 {t("disc4")}
              </Typography>
              <Typography textAlign="left" m={1}>
                🔥 {t("disc5")}
              </Typography>
              <Typography textAlign="left" m={1}>
                🔥 {t("disc6")}
              </Typography>
              {/* <Typography
                textAlign="left"
                mb={lgDown && 2}
                m={1}
              >
                🔥 60 napos bérlet       -  39.000 Ft
              </Typography> */}
            </Grid>
            {/* <Grid
              item
              md={6}
              sm={6}
              sx={12}
            >
              <div>
                <img
                  alt='birds-of-paradise-home-logo'
                  src={birdOfParadiseLogoDiscountImg}
                  className={classes.image}
                  style={{ width: lgDown ? '50%' : '40%', height: 'auto', marginBottom: '30px' }}
                />
              </div>
            </Grid> */}
          </Grid>
          <Grid container>
            <Grid item mt={0} alignItems="center" md={12}>
              <Typography variant="body2">
                {/* Ez az extra meglepetés abban az esetben érvényes ha a párod/barátod/ismerősöd  kezdő egy hetes bérletet vesz, így ad esélyt magának beleszeretni a jógába és a stúdióba. (Utána természetesen ő is igénybe veheti ezt a kedvezményt.) */}
                {/* Akciónk abban az esetben érvényes ha a párod/barátod/ismerősöd... több mint 1
                alkalmas belépőt vásárol és így ad esélyt magának beleszeretni a jógába és a
                stúdióba. */}
                {t("disc7")}
              </Typography>
              <Typography variant="body1" fontWeight="bold" mt={3}>
                {/* (Akciós bérleteink a megvásárlás napjától érvényesek, másra át nem ruházhatóak, és fel nem függeszthetőek.) */}
                {/* <li> */}
                {t("disc8")}
                {/* Ha pedagógus vagy, és a közoktatásban dolgozol 30%-os kedvezménnyel vásárolhatod
                  meg teljes áras 5, 10, illetve havi bérleteinket. */}
                {/* </li> */}
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {/* (Akciós bérleteink a megvásárlás napjától érvényesek, másra át nem ruházhatóak, és fel nem függeszthetőek.) */}
                {/* <li> */}
                {t("disc9")}
                {/* Ha érvényes bérleted lejárta előtt vásárolod meg következő bérleted, 20%-os
                  kedvezményt tudunk biztosítani az eredeti, nem akciós árból. */}
                {/* </li> */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container mt={20}>
          <Grid item md={12} sx={12}>
            <Typography variant="h3" color="primary" fontWeight="bold" textAlign="center" mb={8}>
              Bikram Asana Workshop
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              Ha szeretnéd a Bikram gyakorlásod elmélyíteni, tudatosabban jógázni, esetleg bővebben
              hallanál egyes pózok jótékony hatásairól, jelentkezz{" "}
              <span style={{ fontWeight: "bold" }}>április 29-ei </span> Bikram Asana Workshopunkra.
              Ezen személyre szabott <span style={{ fontWeight: "bold" }}>3,5-4 órás</span>{" "}
              gyakorláson Jakab Orsi, és Sas Zsuzsi vezetésével asanáról asanára haladva vesszük
              végig a Bikram sorozat elemeit.
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              A workshopon minden kérdésedre választ kapsz, azokra is, amiket eddig nem volt módod
              feltenni egy normál 90 perces gyakorlást követően.
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              A gyakorlás szombaton 11:00-kor kezdődik, díja: 8.900 Ft/fő
            </Typography>
          </Grid>
        </Grid> */}
        {/* <Grid container mt={20}>
          <Grid item md={12} sx={12}>
            <Typography variant="h3" color="primary" fontWeight="bold" textAlign="center" mb={8}>
              Nyaralj velünk Horvátországban július 2-7. között!
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              Töltődj jógával a mesés Brac szigetén Európa egyik legszebb homokos tengerpartján, all
              inclusive ellátást biztosító vízparti szállodában.
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              Jelentkezz április 30-ig, így early bird áron tudod biztosítani helyed 985 Euro/ fős
              áron.
            </Typography>
            <Typography
              mt={1}
              // variant='body1'
              textAlign="left"
              variant="body1"
            >
              Részletfizetésre is lehetőséged van, illetve csoportos kedvezményt is biztosítunk{" "}
              <span style={{ fontWeight: "bold" }}>2 fő esetén -50€/fő;</span>{" "}
              <span style={{ fontWeight: "bold" }}> 3fő esetén -75€/fő</span>
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Discount;
