import PropTypes from 'prop-types';
import {
  Box,
  CardHeader,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useTranslation } from 'react-i18next';

const LightboxGallery = (props) => {
  const { propertyImages, width, height } = props;
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {t} = useTranslation();

  const handleOpenModal = (index) => {
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const handleOnClose = () => {
    setOpenModal(false);
  };

  const handleMovePrevRequest = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((currentIndex + propertyImages.length - 1) % propertyImages.length);
    } else {
      setCurrentIndex(0);
    }
  };

  const handleMoveNextRequest = () => {
    if (currentIndex !== propertyImages.length - 1) {
      setCurrentIndex((currentIndex + 1) % propertyImages.length);
    } else {
      setCurrentIndex(propertyImages.length - 1);
    }
  };

  return (
    <Box>
      {/* <Card style={{ border: 0 }}> */}
      <CardHeader
        title={`${t("picturesandvideos")} (${propertyImages.length} db)`}
      />
      {/* <Divider /> */}
      {/* <Box sx={{ minWidth: 900 }}> */}
      <Grid
        container
      // display="flex"
      // justifyContent="center"
      >
        {propertyImages.map((image, index) => (
          <Grid
            item
            xs="auto"
            mx={1}
            my={2}
            key={image.thumb}
          >
            <CardMedia
              onClick={() => handleOpenModal(index)}
              component={image.component}
              image={image.thumb}
              controls
              sx={{
                backgroundColor: 'background.default',
                // height: 'auto',
                // maxHeight: 130,
                height: width || 200,
                width: height || 270,
                borderRadius: 1,
              }}
            />
          </Grid>
        ))}
      </Grid>
      {/* </Box> */}
      {/* </Card> */}
      {
        openModal && (
          <Lightbox
            mainSrc={propertyImages[currentIndex].main}
            nextSrc={currentIndex !== propertyImages.length - 1 ? propertyImages[(currentIndex + 1) % propertyImages.length].main : undefined}
            prevSrc={currentIndex !== 0 ? propertyImages[(currentIndex + propertyImages.length - 1) % propertyImages.length].main : undefined}
            onCloseRequest={handleOnClose}
            onMovePrevRequest={handleMovePrevRequest}
            onMoveNextRequest={handleMoveNextRequest}
            enableZoom
            imageCaption={`${currentIndex + 1}/${propertyImages.length} ${propertyImages[currentIndex].caption ? propertyImages[currentIndex].caption : ''}`}
          />
        )
      }
    </Box>
  );
};

LightboxGallery.propTypes = {
  propertyImages: PropTypes.array
};

export default LightboxGallery;
