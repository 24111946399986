import * as React from 'react';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Grid,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

export default function AlertDialog(props) {
  const { open, user, handleClose } = props;
  const {t} = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">
                {t(user.name)}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="delete" onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: "column",
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar
              alt={user.name}
              src={user.src}
              sx={{ width: 200, height: 200, my: 5 }}
            />
          </div>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            <Typography>
              {t(user.description)}
            </Typography>
            <Typography sx={{ fontStyle: 'italic', margiwnTop: '30px' }}>
              {user.quote}
            </Typography>
            <Typography
              textAlign="right"
              sx={{ fontStyle: 'italic', }}
            >
              {user.quoteAuthor}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
