import React, { useState } from 'react'
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TableRowData = (props) => {
  const [isHover, setIsHover] = useState(false);
  const { item } = props;
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        mt={1}
        style={{ height: '100%', width: '100%', cursor: 'default' }}
        key={item.typeName}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Grid
          item
          sm={9}
          xs={8}
        >
          <Box style={{ height: smUp && '49' }}>
            <Grid item>
              <Typography
                textAlign="stat"
                fontWeight={isHover && 'bold'}
              >
                {t(item.typeName)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight={isHover && 'bold'}
                textAlign="stat"
                variant="caption"
              >
                {t(item.caption)}
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          sm={3}
          xs={4}
        >
          <Typography
            textAlign="right"
            fontWeight={isHover && 'bold'}
          >
            {t(item.price)}
          </Typography>
        </Grid>
        <Grid />
      </Grid>
    </>
  )
}


export default TableRowData;
