import { DataGrid } from '@material-ui/data-grid';
import * as React from 'react';

export default function DataTable(props) {
  const {height} = props
  return (
    <div style={{ height: height, backgroundColor: 'rgba(255, 255, 255, .95)', backdropFilter: 'blur(30px'}}>
      <DataGrid rows={props.children[0]} columns={props.children[1]}   pageSize={5} disableSelectionOnClick />
    </div>
  );
}