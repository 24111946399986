import { createTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
// import shadows from './shadows';

const theme = responsiveFontSizes(
createTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    },
  },
  palette: {
    mode: 'light',
    mixins: {
      toolbar: {
        minHeight: 70
      }
    },
    background: {
      default: '#fefefe',
      paper: '#f6f2ef',
    },
    primary: {
      light: '#95436c',
      main: '#95436c',
      dark: '#95436c',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#4ed9d9',
      main: '#07B1AB',
      dark: '#0C887A',
      contrastText: '#ffffff'
    },
    default: {
      light: '#f5f5f5',
      main: '#342c5d',
      dark: '#e0e0e0',
      contrastText: '#242b2e'
    },
    text: {
      primary: '#1f1d42',
      secondary: '#5e5e5e',
    },
    products: {
      businessAndOffice: {
        light: '#ec5b48',
        main: '#e76320',
        dark: '#e76320',
        contrastText: '#ffffff'
      }
    }
  },
  // shadows,
  typography
}));

export default theme;
