import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from 'react-scroll';

const useStyles = makeStyles({
  scrollToTop: {
    position: 'fixed',
    bottom: '0.1rem',
    right: '0.1rem',
    cursor: 'pointer',
    width: '100px',
    height: '50px',
    opacity: 1,
    transition: 'opacity 1s linear'
  },
  hidden: {
    position: 'fixed',
    bottom: '0.1rem',
    right: '0.1rem',
    cursor: 'pointer',
    width: '100px',
    height: '50px',
    visibility: 'hidden',
    transition: 'visibility 0s 1s, opacity 1s linear',
    opacity: 0,

  }
})



function ScrollToTopButton() {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };


  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div onClick={scrollToTop} className={isVisible ? classes.scrollToTop : classes.hidden}>

      <div data-aos='fade-up' data-aos-once='false'>
        <ArrowUpwardIcon style={{ width: '100%' }} data-aos='fade-up' />
      </div>
    </div>
  );
}


export default ScrollToTopButton

