import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import Map from '../../components/Map';

function Contact() {
  const matches = useMediaQuery('(min-width:900px)');
  const { t, } = useTranslation();

  document.title = t('Title.main') + " | " + t('Title.subTitle.contact')

  return (
    <Paper>
      <Box
        className="mainClass"
        sx={{
          minHeight: '100vh',
          pt: 10,
          pb: 30,
        }}
      >
        <Grid
          container>
          <Banner background='#342c5d' marginBottom={10} style={{ height: '20vh' }} >
            <Container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display='flex'
                justifyContent={matches ? 'left' : 'center'}
                alignItems='center'
                marginBottom={0}>
                <Typography
                  variant='h2' fontWeight='bold' color='white'>{t('contact.contact')}
                </Typography>
              </Grid>
            </Container>
          </Banner>
        </Grid>
        <Container>
          <Grid
            container
            display='flex'
            justifyContent='center'
            alignItems='center'>
            <Grid container spacing={10}>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={8}
                xs={12}
              >
                <Grid container display='flex' flexDirection='column' alignItems='space-between'>
                  <Grid item>
                    <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                      {t('contact.companyName2')}
                    </Typography>
                    <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                      {t('contact.address2')}
                    </Typography>
                    <Typography variant='subtitle1' color='primary' fontWeight='bold' marginTop={5}>
                      {t('contact.contactUs')}
                    </Typography>
                    <Typography variant='subtitle1' color='default'>
                      {t('contact.phone')}
                    </Typography>
                    <Typography variant='subtitle1' color='default'>
                      {t('contact.email')}
                    </Typography>
                  </Grid>
                  <Grid item marginTop={5}>
                    <Typography variant='subtitle1' fontWeight='bold' marginBottom={0} color='primary'>
                      {t('contact.help')}
                    </Typography>
                    <Typography variant='subtitle1' color='default'>
                      {t('contact.description01')}
                    </Typography>
                    <Typography variant='subtitle1' color='default' style={{ marginTop: 20 }}>
                      {t('contact.description02')}
                    </Typography>
                    <Typography variant='subtitle1' color='default' style={{ marginTop: 20 }}>
                      {t('contact.description03')}
                    </Typography>
                    <Typography variant='subtitle1' color='primary' fontWeight='bold' style={{ marginTop: 20 }}>
                      {t('contact.openingHour')}
                    </Typography>
                    <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                      {t('contact.closingHour')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                zIndex={2}
              >
                <Map />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Paper>
  )
}

export default Contact
