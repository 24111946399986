import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Hidden, Paper, Typography, useMediaQuery } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import theme from '../../theme/index';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import CookieSettingsModal from '../Cookie/CookieSettingsModal';

const useStyles = makeStyles({
  // boxContainer: {
  //   backgroundColor: '#2c3e50',
  //   // backgroundColor: '#f6f6f6',
  //   // backgroundColor:'inherit',
  //   display: 'flex',
  //   jusitfyContent: 'space-between',
  // },
  footer1Container: {
    width: '100%',
    backgroundColor: '#ebe4df',
    // backgroundColor: 'rgba(52, 44, 93, .2)',
  },
  footer2Container: {
    width: '100%',
    // backgroundColor: '#f6f6f6'
    //backgroundColor: '#f6f6f7',
    backgroundColor: '#1f1d42',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.default.main,
    '&:hover': {
      color: '#c0a066',
    }
  },
  textContainer: {
    marginTop: '0px',
  },
  textTitleGrid: {
    // marginTop: '50px',
  },
  title: {
    // color:'white'
    color: '#95436c'
  },
  text: {
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

function Footer() {
  const { t, } = useTranslation();
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)');

  const navigationLinks = [
    {
      title: t('navigation.companyName'), children: [
        { text: t('navigation.home'), link: '/' },
        { text: t('navigation.introduction'), link: '/introduction' },
        { text: t('navigation.discount'), link: '/discount' },
        { text: t('navigation.timesheet'), link: '/timesheet' },
        { text: t('navigation.instructors'), link: '/instructors' },
        { text: t('navigation.prices'), link: '/prices' },
        { text: t('navigation.gallery'), link: '/gallery' }
      ]
    },
    {
      title: t('footer.company'), children: [
        { text: t('footer.aboutUs'), link: '/about' },
        { text: t('footer.contact'), link: '/contact' },
        { text: t('footer.impress'), link: '/imprint' }
      ]
    },
    // {
    //   title: t('footer.information'), children: [
    //     { text: t('footer.privacyNotice'), href: '/data-management-information' },
    //     { text: t('footer.privacyPolicy'), href: '/privacy-policy' },
    //     { text: t('footer.termsAndConditions'), href: '/terms-and-conditions' },
    //     { text: t('footer.cookie'), href: '/privacy' },
    //   ]
    // },
    {
      title: t('footer.information'), children: [
        { text: t('footer.privacyNotice'), href: '#/data-management-information' },
        // { text: t('footer.privacyPolicy'), href: '' },
        { text: t('footer.termsAndConditions'), href: '#/terms-and-conditions' },
        { text: t('footer.cookie'), href: '' },
      ]
    },
  ]

  const renderLinks = (text, to, href, link, index) => {
    if (text === t('footer.cookie')) {
      return <div key={index}>
        {/* <a className={classes.link} href={href} rel="noreferrer" target='_blank'>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Typography className={classes.text} key={index}>
              {text}
            </Typography>
            <OpenInNewIcon style={{ fontSize: 14, marginLeft: 4, marginBottom: 9 }} />
          </div>
        </a> */}
        <div
          className={classes.link}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <Typography key={index}>
            <CookieSettingsModal footer />
          </Typography>
          <OpenInNewIcon style={{ fontSize: 14, marginLeft: 4, marginBottom: 0 }} />
        </div>
      </div>
    }
    if (to || link) {
      return <ScrollLink key={index} className={classes.listItem} activeClass="active" to={to ? to : ''} isDynamic={true} spy={true} smooth={true} offset={-80} duration={500}>
        <Link className={classes.link} to={link ? link : ''}>
          <Typography className={classes.text} key={index}>
            {text}
          </Typography>
        </Link>
      </ScrollLink>
    }
    else {
      return <a key={index} className={classes.link} href={href} rel="noreferrer" target='_blank'>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <Typography className={classes.text} key={index}>
            {text}
          </Typography>
          <OpenInNewIcon style={{ fontSize: 14, marginLeft: 4, marginBottom: 9 }} />
        </div>
      </a>
    }
  }


  return (
    <React.Fragment>
      <Paper>
        <Grid
          container
        >
          <Box className={classes.footer1Container} display='flex' alignItems='center'>
            <Container>
              <Grid
                container
              >
                <Hidden smDown>
                  <Grid
                    container py={5} justifyContent='center'>
                    {navigationLinks.map(({ title, children }, index) => {
                      return <Grid
                        key={index}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        display='flex' flexDirection='column' alignItems='space-between' justifyContent='space-between'
                        className={classes.textTitleGrid}
                      >
                        <div className={classes.textContainer}>
                          <Typography mb={5} fontWeight='bold' key={index} className={classes.title}>
                            {title}
                          </Typography>
                          {children.map(({ text, to, href, link }, index) => renderLinks(text, to, href, link, index))}
                        </div>
                      </Grid>
                    })}
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden smUp>
                <Grid
                  container mb={5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    lg={2}
                    className={classes.textTitleGrid}
                    py={5}

                  >{navigationLinks.map(({ title, children }, index) => {
                    return <Accordion disableGutters key={index} elevation={0} square style={{ margin: 0, backgroundColor: '#ebe4df', }}>
                      <AccordionSummary expandIcon={<ExpandMore className={classes.title} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography fontWeight='bold' key={index} className={classes.title}>
                          {title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.textContainer}>
                          {children.map(({ text, to, href, link }, index) => renderLinks(text, to, href, link, index))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  })}
                  </Grid>
                </Grid>
              </Hidden>
              <Grid
                container
                mb={5}
              >
                <Grid item>
                  <Grid
                    container
                    justifyContent={matches ? 'flex-start' : 'center'}
                  >
                    <Grid
                      item
                      mr={3}
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        color={theme.palette.default.main}
                      >
                        &copy; {new Date().getFullYear()} {t('footer.copyright')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                    >
                      <a
                        className={classes.link}
                        href={'https://www.instagram.com/birdsofparadisebuda'}
                        rel="noreferrer"
                        target='_blank'
                      >
                        <Typography className={`${classes.row} ${classes.link}`} color={theme.palette.default.main}>
                          <InstagramIcon style={{ marginRight: 5 }} />
                          Instagram
                        </Typography>
                      </a>
                      <a
                        className={classes.link}
                        href={'https://www.facebook.com/BirdsofparadiseBuda'}
                        rel="noreferrer"
                        target='_blank'
                      >
                        <Typography className={`${classes.row} ${classes.link}`} color={theme.palette.default.main}>
                          <FacebookIcon style={{ margin: 5, marginLeft: 20 }} />
                          Facebook
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box className={classes.footer2Container} alignItems='center' display='flex' py={2} >
            <Container>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display='flex'
                  justifyContent={matches ? 'flex-start' : 'center'}
                >
                  <a href="https://aocsystem.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography style={{ fontSize: '0.8em' }} color='white'>by AOC System Zrt.</Typography>
                  </a>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </Paper>
    </React.Fragment >
  )
}

export default Footer
