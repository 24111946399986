import { Box, CardMedia, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Stack, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useCallback } from 'react'
import usePopover from "./usePopover";

export const languageOptions = {
  hu: {
    icon: "/assets/flags/flag-hu.svg",
    label: "hu",
  },
  en: {
    icon: "/assets/flags/flag-gb.svg",
    label: "en",
    disabled: false,
  },
  de: {
    icon: "/assets/flags/flag-de.svg",
    label: "de",
    disabled: true,
  },
  es: {
    icon: "/assets/flags/flag-es.svg",
    label: "es",
    disabled: true,
  },
  fr: {
    icon: "/assets/flags/flag-fr.svg",
    label: "fr",
    disabled: true,
  },
};

export const LANG_STORAGE_KEY = "app.language";
const storeLanguage = (language) => {
  window.localStorage.setItem(LANG_STORAGE_KEY, language);
};

export const LanguagePopOver = () => {
  const { i18n, t } = useTranslation();
  const popover = usePopover();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleChange = useCallback(
    async (language) => {
      storeLanguage(language);
      await i18n.changeLanguage(language);
      popover.handleClose();
    },
    [i18n],
  );
  const flag = languageOptions[i18n.language].icon;
  const label = languageOptions[i18n.language].label;
  return (
    <>
      {/* <Tooltip title={t('languageSwitch')}> */}
      <IconButton
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{ borderRadius: 6, px: lgUp ? 1 : 0.5, py: 0.5 }}
      >
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {lgUp && ((
            <Typography
              color="text.secondary"
              variant="overline"
            >
              {t(`${label}`)}
            </Typography>
          ))}
          <Box
            sx={{
              width: 28,
              "& img": {
                width: "100%",
              },
            }}
          >
            <img
              style={{ borderRadius: 4 }}
              height={18}
              width={28}
              src={flag}
              alt={label}
            />
          </Box>
        </Stack>
      </IconButton>
      {/* </Tooltip> */}
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        disableScrollLock
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={popover.handleClose}
        open={popover.open}
      // PaperProps={{ sx: { width: 220 } }}
      >
        {(Object.keys(languageOptions))
          .filter((language) => ["en", "hu"].includes(language))
          .map((language) => {
            const option = languageOptions[language];

            return (
              <MenuItem
                onClick={() => handleChange(language)}
                key={language}
              >
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      width: 28,
                      "& img": {
                        width: "100%",
                      },
                    }}
                  >
                    <img
                      style={{ borderRadius: 4 }}
                      alt={option.label}
                      src={option.icon}
                    />
                  </Box>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle2"> {t(`${option.label}`)}</Typography>} />
              </MenuItem>
            );
          })}
      </Popover>
    </>
  );
}

export default LanguagePopOver