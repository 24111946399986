import React from 'react';
import { Grid, Typography, Avatar, Container, useMediaQuery, Box, CardMedia } from '@material-ui/core';
import bikramImg from "../static/images/bikram.jpg";
import musicBikramImg from "../static/images/musicBikram.jpg";
import candleBikramImg from "../static/images/candleBikram.jpg";
import bikram60Img from "../static/images/musicBikram2.jpeg";
import infernoBikramImg from "../static/images/inferno.jpg";
import vinyasaFlowImg from "../static/images/vinyasa2.JPG";
import barkanHotMethodImg from "../static/images/barkanHotMethodImg.jpg";
import yinImg from "../static/images/yin.jpg";
import personalYogaImg from "../static/images/perosnalTraining.JPG";
import yogamama from "../static/images/yogamama.jpg";
import hotpower from "../static/images/hotpower.jpg";
import birdOfParadiseLogoTimesheetImg from '../static/images/Birds_of_Paradise_logo_timesheet.png';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  image: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: 'auto',
  },
}))


function Timesheet() {
  const classes = useStyles();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          mt={5}
          data-aos='fade'
        >
          <Grid
            item
            style={{ marginBottom: '50px' }}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              alt='birds-of-paradise-home-logo'
              src={birdOfParadiseLogoTimesheetImg}
              className={classes.image}
              style={{ width: lgDown ? '50%' : '100%', height: 'auto' }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            mt={{ lg: 20, md: 0 }}
          >
            <Typography
              variant="h1"
              color="#95436c"
            >
              {/* Órák */}
              {t('classes1')}
            </Typography>
            <Typography variant="h6">
              {t('classes2')}
              {/* Hiszünk a sokszínűségben, abban, hogy mindenki kipróbálhatja, és megtalálhatja a neki leginkább megfelelő jóga típust. Legyen szó dinamikusabb, magasabb intenzitású vagy lágyabb, relaxáló típusú óráról.
              A rendszeres gyakorlás segítségével elengedheted fizikális, és mentális feszültségedet, erősödsz, formálod tested és lelked. */}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box
          mt={10}
          mb={smDown && 10}
        >
          <Typography
            variant="h2"
            textAlign="center"
            color="primary"
            mb={10}
          >
            {/* Órarend */}
            {t('classes3')}
          </Typography>
          <CardMedia
            component='iframe'
            src="https://www.motibro.com/visitors/reservation_widget?operator=birds-of-paradise"
            width='100%'
            height='1000'
            scrolling="no"
            style={{
              border: 'none',
              borderRadius: '18px',
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
          </CardMedia>
        </Box>
        <Grid
          container
          mt={{ lg: 20, sm: 10, xs: 0 }}
          display="flex"
          spacing={2}
          mb={15}
          className="Bikram/Eng/ 90' Bikram 90'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={bikramImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t('classes4')}

              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('classes5')}
                {/* 90 perc a 38-40 fokos teremben!
                Ha kihívásokkal teli élményre vágysz, imádod a szaunát, egyszerre nyújtanál és erősítenél, mindenképp próbáld ki ezt a 26 ászanából, és 2 légzőgyakorlatból álló sorozatot! A Bikram jóga segíti megőrizni az izmok, ízületek egészségét, miközben serkenti a vérkeringést a belső szervekben. Ezen felül fejleszti a koncentrációs képességet, türelemre, önuralomra és kitartásra tanít.
                Növeli a szellemi frissességet és hatékonyabbá teszi a stresszkezelést. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
          className="Zenés Bikram Zenés Bikram * 90'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={musicBikramImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Zenés Bikram */}
                {t('classes6')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes7')}
                {/* Kora reggel, ha nem vágysz instruált órára, de semmiképp sem hagynád ki a Bikramot, gyere el zenés óránkra. Itt a megszokott sorozatot végezheted, a zene ritmusára mozdulva. Minimum öt vezetett Bikram óra után javasoljuk. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
          className="Gyertyafényes bikram Bikram Gyertyafényes * 90'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={candleBikramImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Gyertyafényes Bikram */}
                {t('classes8')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('classes9')}
                {/* Bikram sorozat gyertyafényben, zenére minimális instrukciókkal. Ha egy fárasztó nap végén teljesen ki akarod zárni a külvilágot mindenképp próbáld ki. Azért, hogy tényleg élvezni tudd, minimum öt vezetett Bikram gyakorlás után tudsz részt venni az órán. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
          className="Bikram 60'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={bikram60Img}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Bikram 60’ */}
                {t('classes10')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes11')}
                {/* Ha nincs időd vagy hangulatod a 90 perces Bikramhoz, válaszd a 60 perces változatát, ahol csak bizonyos ászanákat ismételsz kétszer. Ebédidőben/helyett nagyszerű elfoglaltság. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
          className="Inferno Hot Pilates Inferno Hot Pilates 60'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={infernoBikramImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Inferno Hot Pilates */}
                {t('classes12')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('classes13')}
                {/* Hardcore edzés pokoli hőfokkal, ha szereted a dinamikus, aerobik mozdulatokat. Garantáltan kikapcsol, átmozgat, és erősít. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          flexDirection="row-reverse"
          spacing={2}
          mb={15}
          className="Barkan Hot Method"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={barkanHotMethodImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Barkan<br /> Hot Method */}
                {t('classes14')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes15')}
                {/* Minden izomcsoportot átmozgató, nyújtásokat is magába foglaló gyakorlás fűtött teremben, 40%-os páratartalom mellett.
                A Barkan HM egy Hot Flow típusú, dinamikusabb irányzat, ahol a Bikramnál ismert mozdulatok ötvöződnek vinyasa, és stretching elemekkel.
                Ha zenekedvelő jógi vagy, nem fogsz csalódni! */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
          className="Vinyasa Flow"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={vinyasaFlowImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Vinyasa Flow */}
                {t('classes16')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes17')}
                {/* Ajánljuk, ha szeretnéd megteremteni a harmóniát a tested, és elméd között, ha szereted a változatosságot és a dinamikus gyakorlást.
                Vinyasa flow óráinkon szobahőmérsékleten, zenére, és légzésed ritmusára áramolsz egyik ászanából a másikba.
                Óráinkon mindig más területre helyezheted a fókuszt. Ilyenek: a mellkas-és csípőnyitás, a hátra hajlás, a fordított testhelyzetek, a kartámaszos gyakorlatok, az erősítés, vagy akár a stretching. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          flexDirection="row-reverse"
          mb={15}
          className="Yin Yin 	&#38; Handpan 60'"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={yinImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Yin */}
                {t('classes18')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('classes19')}
                {/* Ezzel a lassú, meditatív stílusú irányzattal teljesen el tudod engedni a testi és lelki feszültséged. Nem is gondolnád de a hosszabban (akár 3-4percig) kitartott nyújtásokkal a test mélyebb kötőszöveteire tudsz hatni, amellett, hogy segít lecsendesíteni elmédet, és megtalálni az utat a belső egyensúlyod felé.         */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
          className="Magán óra"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={personalYogaImg}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {/* Magán- és Kiscsoportos órák */}
                {t('classes20')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes21')}
                {/* Ha szeretnél még intenzívebben elmélyülni egy-egy jógatípusban, magán óráinkon száz százalékig saját ritmusodban fejlődhetsz kedvenc oktatód segítségével.
                Infrapanellel felszerelt kis termünk lehetővé teszi a hot irányzatok egyéni, illetve kiscsoportos gyakorlását is.
                A terem befogadó képessége maximum 3 fő. */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          flexDirection="row-reverse"
          mb={15}
          className="yogamama"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={yogamama}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t('classes22')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
              >
                {t('classes23')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={15}
          display="flex"
          spacing={2}
          mb={15}
          className="hotpower"
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={hotpower}
              style={{ width: smDown ? 200 : 350, height: smDown ? 200 : 350 }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid
              item
              mb={2}
            >
              <Typography
                variant="h2"
                color="#95436c"
                textAlign="left"
              >
                {t('classes24')}
              </Typography>
            </Grid>
            <Grid
              item>
              <Typography
                variant="h6"
              >
                {t('classes25')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Timesheet
