import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import birdOfParadiseLogoTimesheetImg from "../static/images/Birds_of_Paradise_logo_timesheet.png";
// import birdOfParadiseLogoDiscountImg from '../static/images/openingDiscount.png';
import { makeStyles } from "@material-ui/styles";
import TableRowData from "../components/TableRowData";
import { useTranslation } from "react-i18next";
// import Banner from './../components/Banner/Banner';

const useStyles = makeStyles((theme) => ({
  image: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    margin: "auto",
  },
}));


function Prices() {
  const classes = useStyles();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  // const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const data = [
    {
      typeName: "prices9",
      caption:
        "prices10",
      price: "5 000 Ft",
    },
    {
      typeName: "pricesRestart",
      caption:
        "pricesRestartDesc",
      price: "8 000 Ft",
    },
    {
      typeName: "prices11",
      caption: "prices12",
      price: "4 800 Ft",
    },
    {
      typeName: "prices13",
      caption: "prices14",
      price: "22 000 Ft",
    },
    {
      typeName: "prices15",
      caption: "prices16",
      price: "41 000 Ft",
    },
    {
      typeName: "prices17",
      caption: "prices18",
      price: "43 500 Ft",
    },
    {
      typeName: "prices19",
      caption: "prices20",
      price: "205 000 Ft",
    },
    {
      typeName: "prices21",
      caption: "prices22",
      price: "333 000 Ft",
    },
    {
      typeName: "prices23",
      caption: "prices24",
      price: "contactUs",
    },
    {
      typeName: "prices25",
      caption: "prices26",
      price: "contactUs",
    },
    {
      typeName: "prices27",
      caption: "prices28",
      price: "500 Ft",
    },
    {
      typeName: "prices29",
      caption: "",
      price: "600 Ft",
    },
    {
      typeName: "prices30",
      caption: "prices31",
      price: "500 Ft",
    },
    {
      typeName: "prices32",
      caption: "prices33",
      price: "600 Ft",
    },
    {
      typeName: "prices34",
      caption: "prices35",
      price: "500 Ft",
    },
    {
      typeName: "prices36",
      caption: "prices37",
      price: "700 Ft",
    },
    {
      typeName: "prices38",
      caption: "prices39",
      price: "1 100 Ft",
    },
  ];

  const dataDiscount = [
    {
      typeName: "prices11",
      caption: "prices12",
      price: "4 000 Ft",
    },
    {
      typeName: "prices13",
      caption: "prices14",
      price: "18 500 Ft",
    },
    {
      typeName: "prices15",
      caption: "prices16",
      price: "34 500 Ft",
    },
    {
      typeName: "prices17",
      caption: "prices18",
      price: "35 500 Ft",
    },
    {
      typeName: "prices19",
      caption: "prices20",
      price: "174 000 Ft",
    },
    {
      typeName: "prices21",
      caption: "prices22",
      price: "283 000 Ft",
    },
  ];

  const birthdayDataDiscount = [
    {
      typeName: "prices15",
      caption: "prices16",
      price: "36 000 Ft",
    },
    {
      typeName: "prices17",
      caption: "prices18",
      price: "39 000 Ft",
    },
    {
      typeName: "prices19",
      caption: "prices20",
      price: "160 000 Ft",
    },
    {
      typeName: "prices21",
      caption: "prices22",
      price: "280 000 Ft",
    },
  ];
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          // display="flex"
          // flexDirection="row"
          mt={10}
          data-aos="fade"
        >
          <Grid
            item
            style={{ marginBottom: "50px" }}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              alt="birds-of-paradise-home-logo"
              src={birdOfParadiseLogoTimesheetImg}
              className={classes.image}
              style={{ width: lgDown ? "50%" : "70%", height: "auto" }}
            />
            {/* <img
              alt='birds-of-paradise-home-logo'
              src={birdOfParadiseLogoDiscountImg}
              className={classes.image}
              style={{ width: lgDown ? '50%' : '70%', height: 'auto' }}
            /> */}
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            // mt={37}
            mt={{ lg: 10, md: 0 }}
          >
            <Typography variant="h1" color="#95436c">
              {/* Áraink */}
              {t("prices1")}
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              {/* Ha szeretted az óráinkat, és egy napot sem hagynál ki a
              gyakorlásból, új bérleted az aktuális lejárta előtt 20%
              kedvezménnyel vásárolhatod meg. Ezt csak készpénzes vásárlással
              tudod megtenni. */}
              {t("prices2")}
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              {/* Bérleteink névre szólnak, csak neked, az adott vásárlónak! */}
              {t("prices3")}
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              {/* A bérletek meghosszabbítására nincs lehetőség. */}
              {t("prices4")}
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              {t("prices5")}
              {/* Aktuális bérleteddel a stúdió összes óráját látogathatod. */}
            </Typography>
            <Typography variant="h6" style={{ marginTop: "10px" }}>
              {t("prices6")}
              {/* Stúdiónkban OTP, MKB, és K&H SZÉP kártyával is tudsz fizetni. SZÉP
              kártyás fizetésedkor akcióinkat nem tudod igénybe venni. */}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {/* <Grid container>
        <Banner
          background='-webkit-linear-gradient(60deg, rgba(31,29,66,1) 0%, rgba(52,44,93,1) 30%, rgba(149,67,108,1) 70%, rgba(192,160,102,1) 100%)'
          marginBottom={10}
          marginTop={10}
          paddingTop={10}
          paddingBottom={10}
        >
          <Container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Typography
                variant='h2'
                fontSize={smDown && '2.1em'}
                color="#f6f2ef"
                textAlign="center"
                mb={5}
              >
                Nyári extra bérletek!
              </Typography>
              <Typography
                variant='h6'
                color="#f6f2ef"
                textAlign="center"
                fontWeight="bold"
              >
                Summer Savings
              </Typography>
              <Typography
                variant="h6"
                color="#f6f2ef"
                textAlign="center"
                m={2}
              >
                2 hónap (60 nap), 49.000 Ft (kezdés legkésőbb július 15.)
              </Typography>
              <Typography
                variant='h6'
                color="#f6f2ef"
                textAlign="center"
                fontWeight="bold"
                mt={5}
              >
                Summer Dozen
              </Typography>
              <Typography
                variant="h6"
                color="#f6f2ef"
                textAlign="center"
                m={2}
              >
                12 alkalom, 38.000 Ft (megvásárolható július 31-ig)
              </Typography>
            </Grid>
          </Container>
        </Banner>
      </Grid> */}
      <Container>
        <Grid
          container
          mt={10}
          mb={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={10} xs={12}>
            <Card style={{ background: "#f6f2ef" }}>
              <CardHeader
                title={
                  <Typography color="#95436c" variant="h3">
                    {/* Árak */}
                    {t("prices8")}
                  </Typography>
                }
              />
              <CardContent>
                <Divider />
                {data.map((item) => (
                  <React.Fragment key={item.typeName}>
                    <TableRowData item={item} />
                    <Divider />
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          mt={10}
          mb={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={10} xs={12}>
            <Card style={{ background: "#f6f2ef" }}>
              <CardHeader
                title={
                  <Typography color="#95436c" variant="h3">
                    {/* Diák és nyugdíjas árak */}
                    {t("studentPrices")}
                  </Typography>
                }
                subheader={
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">
                      {/* A diák kedvezményünket a nappali tagozaton tanuló,
                      huszonhatodik életévüket be nem töltött személyek vehetik
                      igénybe. */}
                      {t("studentPrices1")}
                    </Typography>
                    <Typography variant="subtitle2">
                      {/* Nyugdíjas kedvezményünket az érvényes magyar nyugdíjas
                      igazolvánnyal rendelkezők vehetik igénybe. */}
                      {t("studentPrices2")}
                    </Typography>
                  </Box>
                }
              />
              <CardContent>
                <Divider />
                {dataDiscount.map((item) => (
                  <React.Fragment key={item.typeName}>
                    <TableRowData item={item} />
                    <Divider />
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          mt={10}
          mb={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={10} xs={12}>
            <Card style={{ background: "#f6f2ef" }}>
              <CardHeader
                title={
                  <Typography color="#95436c" variant="h3">
                    {t("bdiscount")}
                    {/* Születésnapi kedvezmény */}
                  </Typography>
                }
                subheader={
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">
                      {t("bdiscount1")}
                      {/* Mi is veled örülünk!{" "}
                      <b> Születésnapodon annyi százalékkal olcsóbban </b> tudod
                      megvásárolni 10 alkalmas, havi, fél-ill éves bérleteinket,{" "}
                      <b> ahány éves lettél </b> (max 40%-ig). Akciónk más
                      kedvezményeinkkel nem vonható össze. */}
                    </Typography>
                    {/* <Typography variant="subtitle2">
                      Akciónk más kedvezményeinkkel nem vonható össze.
                    </Typography> */}
                  </Box>
                }
              />
              {/* <CardContent>
                <Divider />
                {birthdayDataDiscount.map((item) => (
                  <React.Fragment key={item.typeName}>
                    <TableRowData item={item} />
                    <Divider />
                  </React.Fragment>
                ))}
              </CardContent> */}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Prices;
