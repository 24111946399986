import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicTable from '../../components/BasicTable/BasicTable';



const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '80px',
    fontWeight: 'bold',
    color: '#000'
  },
  subTitle: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '50px',
    fontWeight: 'bold',
  },
  subTitle2: {
    textAlign: 'left',
    marginTop: '40px',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'justify',
    marginBottom: '30px',
    marginTop: '30px'
  },
  button: {
    textTransform: 'none'
  },
  tableTitle: {
    color: '#000',
    textAlign: 'center',
    marginBottom: '10px',
    marginTop: '80px'
  }
})


const registrationTableRows = [
  {
    name: 'Cél',
    details: ['az érintett adatainak rögzítése, hozzáférésének megadása, érvényesítése, ellenőrzése, bejelentkezés és így az órákra történő bejelentkezés megkönnyítése, valamint a kapcsolattartás']
  },
  {
    name: 'Érintettek: ',
    details: ['Minden természetes személy, aki a regisztráció során megadott adatokkal beazonosítható, beazonosított ']
  },
  {
    name: 'Forrás: ',
    details: ['érintettek']
  },
  {
    name: 'Adatfeldolgozó: ',
    details: [
      'Adatkezelő adatfeldolgozót alkalmaz a regisztráció végrehajtása, az adatok kezelése tekintetében. \n Szolgáltató: MotiBro Kft. \n Székhely: 4482 Kótaj, Kert utca 1. \n Képviselő: Huncsik Péter \n Email: support@motibro.com. \n Bővebb információ: https://motibro.s3.eu-central-1.amazonaws.com/data/MotiBro_Felhasznalasi_Feltetelek_20200408.pdf ']
  },
  {
    details: ['Adatkör', 'Cél', 'Jogalap', 'Tárolási Idő']
  },
  {
    details: ['név*', 'azonosítás, megszólítás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    details: ['e-mail cím*', 'kapcsolattartás, megerősítés ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    details: ['telefonszám*', 'kapcsolattartás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    details: ['jelszó*', 'későbbi bejelentkezéshez, azonosításhoz szükséges ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    details: ['regisztráció időpontja*', 'azonosítás, bizonyítás ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    details: ['regisztráció során használt IP cím*', 'későbbi bizonyítás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja)', 'regisztráció törléséig, ennek hiányában maximum 3 évig']
  },
  {
    name: 'Hogyan történik az adatkezelés? ',
    details: [
      "Az adatkezeléssel érintett tevékenység és folyamat: \n a. Érintett a webhely egy meghatározott felületén keresztül a fent meghatározott adatait megadhatja és azokat (kattintással) eljuttathatja az adatfeldolgozó részére. \n b. A megadott adatok a weboldalt kiszolgáló szerverre titkosított csatornán keresztül jutnak el. \n c. Adatfeldolgozó a regisztráció megerősítéséhez a megadott e-mail címre kódot küld meg, ennek a webhelyre történő berögzítését követően a regisztráció véglegessé válik. \n d. A belépéshez szükséges adatok egy ezt a célt szolgáló elektronikus nyilvántartási rendszerbe automatikusan rögzítésre kerülnek."]
  },
  {
    name: 'Egyéb: ',
    details: ['A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy azok az adatkezeléshez szükségesek ']
  },
]

const loginTableRows = [
  {
    name: 'Cél',
    details: ['Az adott jóga órára történő bejelentkezés, helyfoglalás. ']
  },
  {
    name: 'Érintettek: ',
    details: ['Minden természetes személy, aki a bejelentkezés során megadott adatokkal beazonosítható, beazonosított ']
  },
  {
    name: 'Forrás: ',
    details: ['érintettek']
  },
  {
    name: 'Adatfeldolgozó: ',
    details: [
      'Adatkezelő adatfeldolgozót alkalmaz a regisztráció végrehajtása, az adatok kezelése tekintetében. \n Szolgáltató: MotiBro Kft. \n Székhely: 4482 Kótaj, Kert utca 1. \n Képviselő: Huncsik Péter \n Email: support@motibro.com. \n Bővebb információ: https://motibro.s3.eu-central-1.amazonaws.com/data/MotiBro_Felhasznalasi_Feltetelek_20200408.pdf']
  },
  {
    details: ['Adatkör', 'Cél', 'Jogalap', 'Tárolási Idő']
  },
  {
    details: ['Amennyiben érintett már regisztrált az adatfeldolgozó MotiBro oldalán keresztül, úgy a kiválasztott órára történő jelentkezés a következő adatok kezelésével történik: ']
  },
  {
    details: ['e-mail cím*', 'MotiBro oldalra történő belépés ', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig ']
  },
  {
    details: ['jelszó*', 'MotiBro oldalra történő belépés', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig ']
  },
  {
    details: ['kiválasztott óra időpontja* ', 'bejelentkezés órára ', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig']
  },
  {
    details: ['bejelentkezés időpontja* ', 'későbbi bizonyítás', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig']
  },
  {
    details: ['Amennyiben az érintett a https://birdsofparadise.hu oldal bejelentkezés gombjára történő kattitással kívána a kiválasztott órára jelentkezni és nem regisztrált felhasználó a MotBro rendszerében, úgy a jelentkezés a következő adatok kezelésével történik: ']
  },
  {
    details: ['e-mail cím*', 'azonosítás, információ kiküldés, bejelentkezés visszamondása (kilépés)', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig ']
  },
  {
    details: ['név*', 'azonosítás ', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig']
  },
  {
    details: ['telefonszám*', 'kapcsolattartás', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig ']
  },
  {
    details: ['kiválasztott óra időpontja* ', 'bejelentkezés órára ', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig']
  },
  {
    details: ['bejelentkezés időpontja* ', 'későbbi bizonyítás', 'GDPR 6. cikk (1) b) pontja, megállapodás megkötése, teljesítése ', '5 évig']
  },
  {
    name: 'Egyéb: ',
    details: ['Érintettnek lehetősége van a küldött e-mail alapján a jelentkezését visszamondani (kilépni) az ÁSZF-ben meghatározottakat betartva, vagy akár a  MotiBro rendszerbe regisztrálni. \n\r\n Éintett a jelentkezéssel kijelenti, hogy elolvasta, megértette és elfogadta az Adatkezelő jóga- és pilates foglalkozásaira és tanfolyamaira vonatkozó jelentkezési, lemondási, részvételi, a szolgáltatásra vonatkozó szerződési feltételeket, valamint az adatkezelési szabályokat. \n\r\n A *-gal jelölt adatokkal kapcsolatban az Adatkezelő felhívja a figyelmet arra, hogy azok az adatkezeléshez szükségesek  ']
  },
]

const accountTableRows = [
  {
    name: 'Kiegészítő információ: ',
    details: ['A MotiBro rendszerbe történő bejelentést követően a fiókadatok bármikor módosíthatók.']
  },
  {
    name: 'Cél',
    details: ['Pontos, naprakész adatok kezelése a bejelentkezett felhasználókról az adatmódosítások végrehajtásával, a bérletek nyomonkövetése, számlázás ']
  },
  {
    name: 'Érintettek: ',
    details: ['Minden természetes személy, aki a bejelentkezés során megadott adatokkal beazonosítható, beazonosított']
  },
  {
    name: 'Forrás: ',
    details: ['érintettek']
  },
  {
    details: ['Adatkör', 'Cél', 'Jogalap', 'Tárolási Idő']
  },
  {
    details: ['e-mail cím*', 'MotiBro oldalra történő belépés ', 'GDPR 6. cikk (1) a) pontja, önkéntes hozzájárulás ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében. ']
  },
  {
    details: ['jelszó*', 'MotiBro oldalra történő belépés  ', 'GDPR 6. cikk (1) a) pontja, önkéntes hozzájárulás ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['fiókadatok \n(név \nbecenév \ne-mail cím, telefonszám \nnem \nszületési dátum) módosítása *', 'helyesbítés joggyakorlásának lehetővé tétele ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['számlázási adatok megadása, módosítása ', 'számlakiállítás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['értesítések beállítása ', 'a rendszer által küldendő értesítések beállítása ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['bejelentkezések módosítása  ', 'azonosítás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['bérlet nyomonkövetése ', 'kapcsolattartás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['aktivitás nyomonkövetése', 'statisztika', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['bejelentkezés időpontja*', 'azonosítás, bizonyítás ', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
  {
    details: ['regisztráció során használt IP cím*', 'későbbi bizonyítás', 'önkéntes hozzájárulás (GDPR 6. cikk (1) a) pontja) \n(számlázási adatok megadása kötelező a számviteli jogszabályok alapján) ', 'regisztráció törléséig, de legfeljebb az utolsó belépéstől számított 2 évig, amennyiben nem történik olyan aktivitás, amely miatt számlakiállítás lenne szükséges. \n Érvényes bejelentkezés esetén a számlázási adatok 8 évig kerülnek eltárolása függetlenül a fiókadatok esetleges törlésétől a számviteli jogszabályokban meghatározottak teljesítése érdekében.']
  },
]


const lawTableColumns = [
  { title: '', variant: 'h7', align: 'left' },
  { title: 'Előzetes tájékozódáshoz való jog', variant: 'h7', align: 'right' },
  { title: 'Hozzáférés joga', variant: 'h7', align: 'right' },
  { title: 'Helyesbítés joga', variant: 'h7', align: 'right' },
  { title: 'Törlés joga', variant: 'h7', align: 'right' },
  { title: 'Korlátozás', variant: 'h7', align: 'right' },
  { title: 'Adat-hordozhatóság', variant: 'h7', align: 'right' },
  { title: 'Tiltakozás', variant: 'h7', align: 'right' },
  { title: 'Hozzájárulás visszavonása', variant: 'h7', align: 'right' },
]

const lawTableRows = [
  {
    name: 'Hozzájárulás',
    details: [true, true, true, true, true, true, false, true]
  },
  {
    name: 'Megállapodás',
    details: [true, true, true, true, true, true, false, false]
  },
  {
    name: 'Jogi kötelezettség',
    details: [true, true, true, false, true, false, false, false]
  },
  {
    name: 'Létfontosságú érdek',
    details: [true, true, true, true, true, false, false, false]
  },
  {
    name: 'Közfeladat, közhatalmi jog.',
    details: [true, true, true, false, true, false, true, false]
  },
  {
    name: 'Jogos érdek',
    details: [true, true, true, true, true, false, true, false]
  },
]


function DataManagementInformation() {
  const classes = useStyles()
  const { t, } = useTranslation();

  document.title = t('Title.main') + " | " + t('Title.subTitle.dataManagement')

  return (
    <React.Fragment>
      <Paper>
        <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
          <Container>
            <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>Adatkezelési tájékoztató </Typography>
          </Container>
        </Box>
        <Box
          sx={{
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Grid
            container>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <Container>
                <Typography className={classes.text}>Az Adatkezelő Birds of Paradise Kft. (székhely: 1015 Budapest, Batthyány utca 48. 4. em. 7., adószám: 28846839-2-41, cégjegyzékszám: 01-09-376675, képviseli: Huszlicska Gábor Dániel ügyvezető, e-mail cím: info@birdsofparadise.hu, tel.: +36 30 432 1086, a továbbiakban: Adatkezelő) ezúton tájékoztatja Önt a weboldalon és online történő alábbi adatkezelésekről és egyéb tényekről:</Typography>
                <ul>
                  <li className={classes.text} style={{ fontWeight: 'bold' }}>Weboldalra történő regisztráció során megadott adatokkal kapcsolatos adatkezelés</li>
                  <li className={classes.text} style={{ fontWeight: 'bold' }}>Bejelentkezés kiválasztott órára</li>
                  <li className={classes.text} style={{ fontWeight: 'bold' }}>Fiókadatokkal kapcsolatos adatkezelések a MotiBro rendszerében </li>
                  <li className={classes.text} style={{ fontWeight: 'bold' }}>Weboldalon – sütikkel és más nyomkövetőkkel – végzett egyéb adatkezelések, valamint közösségi médiával kapcsolatos adatkezelések</li>
                </ul>
                <Typography className={classes.subTitle2}>Az adatkezelések közös jellemzői az alábbiak:</Typography>
                <ul>
                  <li className={classes.text}>Ön, mint érintett jogaival (a hozzáférés joga, a helyesbítés joga, a törléshez való jog és az „elfeledtetéshez” való jog, az adatok zároláshoz/korlátozáshoz való jog, tiltakozáshoz való jog, az adathordozhatósághoz való jog, hozzájárulás visszavonásának joga, lásd a jogok részletes leírásait a tájékoztató végén) az  info@birdsofparadise.hu e-mail címre, vagy az Adatkezelő más elérhetőségre küldött nyilatkozattal teheti meg, továbbá panasszal fordulhat a hatósághoz (mindenkori elérhetőséget lásd: NAIH, ugyfelszolgalat@naih.hu; 1363 Bp., Pf. 9., <a href='https://www.naih.hu/' style={{ textDecoration: 'none', color: '#000' }}>www.naih.hu</a>;), és ha megítélésük szerint megsértették jogait, fordulhatnak a lakóhelyük szerint illetékes bírósághoz. Az Adatkezelő felhívja az érintettek figyelmét, hogy joggyakorlásuknak lehetnek feltételei, valamint korlátai egy-egy adatkezeléssel kapcsolatban, amely tényezőket az Adatkezelő vizsgálni köteles az érintettek joggyakorlása esetén. Abban az esetben, ha egy jogával az érintett adott adatkezelés kapcsán nem élhet, úgy a joggyakorlást kizáró/korlátozó ténybeli és/vagy jogi indokokról az Adatkezelő írásban (ide értve az elektronikus utat is) tájékoztatja az érintettet és arról nyilvántartást vezet. </li>
                  <li className={classes.text}>az Adatkezelő az informatikai védelemmel kapcsolatos feladatai körében gondoskodik különösen:
                    <ul>
                      <li>az adatkezeléshez használt eszközök (a továbbiakban: adatkezelő rendszer) jogosulatlan személyek általi hozzáférésének megtagadásáról,</li>
                      <li>az adathordozók jogosulatlan olvasásának, másolásának, módosításának vagy eltávolításának megakadályozásáról,</li>
                      <li>az adatkezelő rendszerbe a személyes adatok jogosulatlan bevitelének, valamint az abban tárolt személyes adatok jogosulatlan megismerésének, módosításának vagy törlésének megakadályozásáról,</li>
                      <li>az adatkezelő rendszerek jogosulatlan személyek általi, adatátviteli berendezés útján történő használatának megakadályozásáról,</li>
                      <li>arról, hogy az adatkezelő rendszer használatára jogosult személyek kizárólag a hozzáférési engedélyben meghatározott személyes adatokhoz férjenek hozzá,</li>
                      <li>arról, hogy ellenőrizhető és megállapítható legyen, hogy a személyes adatokat adatátviteli berendezés útján mely címzettnek továbbították vagy továbbíthatják, illetve bocsátották vagy bocsáthatják rendelkezésére</li>
                      <li>arról, hogy utólag ellenőrizhető és megállapítható legyen, hogy mely személyes adatokat, mely időpontban, ki vitt be az adatkezelő rendszerbe</li>
                      <li>a személyes adatoknak azok továbbítása során vagy az adathordozó szállítása közben történő jogosulatlan megismerésének, másolásának, módosításának vagy törlésének megakadályozásáról</li>
                      <li>arról, hogy üzemzavar esetén az adatkezelő rendszer helyreállítható legyen.</li>
                      <li>arról, hogy az adatkezelő rendszer működőképes legyen, a működése során fellépő hibákról jelentés készüljön, továbbá a tárolt személyes adatokat a rendszer hibás működtetésével sem lehessen megváltoztatni.</li>
                    </ul>
                  </li>
                  <li className={classes.text} style={{ fontWeight: 'bold' }}>Adatkezelő végez más adatkezeléseket is, amelyekről a jelen tájékoztatótól formailag elkülönülő tájékoztatókban találhat az érintett bővebb információkat.</li>
                </ul>
                <Typography variant='h6' className={classes.tableTitle}>Egyszeri információkérés és -adás során megadott adatokhoz kapcsolódó adatkezelés összefoglaló táblázata</Typography>
                <Typography className={classes.subTitle2}>Weboldalra történő regisztráció során megadott adatokkal kapcsolatos adatkezelés </Typography>
                <BasicTable
                  rows={registrationTableRows}
                >
                </BasicTable>
                <Typography className={classes.subTitle2}>Bejelentkezés kiválasztott órára</Typography>
                <BasicTable
                  rows={loginTableRows}
                >
                </BasicTable>
                <Typography className={classes.subTitle2}>Fiókadatokkal kapcsolatos adatkezelés</Typography>
                <BasicTable
                  rows={accountTableRows}
                >
                </BasicTable>
                <Typography className={classes.title}>
                  Weboldalon – sütikkel és más nyomkövetőkkel – végzett egyéb adatkezelések, valamint közösségi médiával kapcsolatos adatkezelések
                </Typography>
                <Typography className={classes.subTitle2}>
                  Sütik
                </Typography>
                <Typography className={classes.text}>
                  A sütik apró, IT-adatokat tartalmazó szövegfájlok, melyek a Webhely megfelelő működéséről gondoskodnak. A végponti eszközök tárolják őket. A fájlok felismerik az eszközt, és a preferenciáihoz igazítják a Webhely megjelenését. A sütik elengedhetetlenek ahhoz, hogy bejelentkezzen a Webhelyre, és a funkciók zavartalan működését is ezek biztosítják.
                  A sütikkel kapcsolatos további információk a láblécben található menüpontban találhatók.
                </Typography>
                <Typography className={classes.subTitle2}>
                  Facebook-plugin
                </Typography>
                <Typography className={classes.text}>
                  A Webhely tartalmaz egy Facebook-plugint (Meta Inc., 1601 Willow Road, Menlo Park, California, 94025, USA). A Webhelyünkön található Facebook-plugint a Facebook-embléma jelzi. A plugin közvetlen kapcsolatot teremt Ön és a szerveren található Facebook-profil között. A Facebook ezután az IP-címéből tudomást szerez róla, hogy felkereste a Webhelyünket.
                  Ha úgy kerese fel a Webhelyünket, hogy be van jelentkezve a Facebook-profiljába, akkor a Meta rögzíteni fogja a látogatással kapcsolatos információkat. A Meta nem informálja az Adatkezelőt az általa gyűjtött adatokról és azok használatának módjáról.
                  Ha többet szeretne megtudni az adatvédelmi irányelvekről, vegye fel a kapcsolatot a Meta-val, vagy olvassa el a felhasználási feltételeit: https://www.facebook.com/about/privacy/.
                </Typography>
                <Typography className={classes.subTitle2}>
                  Facebook Pixel
                </Typography>
                <Typography className={classes.text}>
                  Webhely Facebook Pixelt használ – ennek segítségével az érintettek számára az órákkal kapcsolatos reklámokat jeleníthet meg az Adatkezelő valahányszor a Facebookra vagy más olyan weboldalra látogat, amely szintén ezt az eszközt alkalmazza. Emiatt testreszabott reklámokkal is találkozhat, melyek célja, hogy Webhely és kínálata feltétlenül felkeltse az érdeklődését, és a lehető legjobban tükrözze az igényeit.
                  Ha többet szeretne megtudni a személyes adatai védelméről, látogass el a Meta vonatkozó aloldalára: https://www.facebook.com/about/privacy/.
                </Typography>
                <Typography className={classes.subTitle2}>
                  Információk a közösségimédia-profilról
                </Typography>
                <Typography className={classes.text}>
                  A következő közösségi oldalakon rendelkezik az Adatkezelő profillal: Facebook, Instagram, (a továbbiakban „közösségi média”); ezeken a felületeken információkat tesz, tehet közzé. A közösségimédia-adminisztrátorok sütikkel és egyéb hasonló technológiákkal rögzítik az érintettek viselkedését a profilokkal végzett tevékenységei során is. A közösségi médiában feldolgozott személyes adatok teljes hatókörét és céljait az adott közösségi oldal adminisztrátorai határozzák meg.
                  Amikor meglátogatja a profilt, a közösségimédia-adminisztrátorok általános statisztikai képet alkotnak érdeklődéséről és demográfiai adatairól (például az életkorról, nemről és régióról), melyhez az Adatkezelő is hozzáfér.
                  Az Adatkezelő teljes mértékben felelős a közösségi média profiljainak kezeléséért és a bejegyzésekért, a felhasználók közzétett információiért, valamint a privát üzenetekért és a specifikus közösségimédia-oldalakon zajló kommunikációért.
                  <br />A Facebookot és az Instagramot a Meta Ltd., 4 Grand Canal Square, Dublin 2, Ireland („Meta”) kezeli.
                  <br />Ha egy adott közösségimédia-webhelyen fiókkal rendelkik, akkor az adott webhely egyéb felhasználóival azonos feltételek szerint fér hozzá az Adatkezelő az Ön által nyilvánosként megjelölt adataihoz.
                  A webhelystatisztikák létrehozásához kapcsolódó adatvédelmi jogairól és az ilyen jogok közvetlenül a közösségi médiával való gyakorlásának lehetőségéről a következő hivatkozásokra kattintva még részletesebben tájékozódhat:
                  <br />1. Facebook
                  https://www.facebook.com/legal/terms/information_about_page_insights_data
                  <br />2. Instagram:
                  https://help.instagram.com/5195221251078753
                </Typography>
                <Typography className={classes.subTitle2}>
                  A Google Analytics adatvédelmével kapcsolatos nyilatkozat
                </Typography>
                <Typography className={classes.text}>
                  A Webhely a Google Inc. („Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA”) webszolgáltatás-elemzési mechanizmusait használja: Google Analytics, Google Double Click és Google Tag Manager. A Google Analytics, Google Double Click és Google Tag Manager sütiket használ a webhelyek használati módjának elemzéséhez. A sütik által gyűjtött információkat a rendszer elküldi a Google Egyesült Államokban található szervereire, ahol archiválják őket.
                  <br />Ha az IP-anonimizálási funkció aktiválva van, miközben felkeresi a Webhelyet, a felhasználói IP-címedet rövidíti a Google. Ez az Európai Unió tagállamaira és az EGT-ről szóló megállapodásban felsorolt országokra vonatkozik. A teljes IP-cím csak különleges esetekben kerül a Google Egyesült Államokban található szervereire, akkor is rövidített formában. Így az IP-címanonimizálási funkció aktív a Webhelyen.
                  <br />A webhely üzemeltetőjének kérelme alapján a Google a gyűjtött információkat a webhelyforgalom elemzésére és a webhely használatáról, valamint a Webhely használatához kapcsolódó szolgáltatásokról szóló jelentések előkészítésére használja. A böngésződ által a Google Analyticsen belül átadott IP-címet más Google-adataidtól elkülönítve tárolják.
                  <br />A sütibeállításokat a webböngészőben módosíthatja. Előfordulhat, hogy a döntése miatt bizonyos funkciókat egyáltalán nem érhet majd el a webhelyen. A következő plugin letöltésével és telepítésével letilthatja a sütik által gyűjtött webhelyadatok (többek között az IP-cím) mentését és azok Google-nak való átadását, valamint a Google által történő megosztásukat: https://tools.google.com/dlpage/gaoptout.
                </Typography>
                <Typography className={classes.subTitle2}>
                  Google Ads
                </Typography>
                <Typography className={classes.text}>
                  A Google Adset az Adatkezelő arra használja, hogy a webhelyet külső webhelyeken, Google-hirdetések formájában népszerűsíthesse. A reklámkampányokból gyűjtött adatok alapján mérhető a promóciós tevékenységek hatékonysága. Amikor a Webhelyet egy Google-hirdetésen keresztül látogatja meg, eszköze ugyan süti formájában tárolja ezt az információt, ám ez önmagában nem elegendő az azonosításhoz. Az összegyűjtött adatok között a webhelyeken és a Google keresőmotorjában megjelenített, valamint a Google partnereitől származó, specifikus reklámokra vonatkozó információk gyűjtésére is sor kerülhet. Ha regisztrált Google-felhasználó, előfordulhat, hogy a Google a látogatása tényét a fiókkal összekapcsolva tárolja. Ha nem regisztrált Google-felhasználó vagy nem jelentkezett be, a Google az IP-címét is elmentheti.
                </Typography>
                <Typography className={classes.text}>Ha nem kíváncsi a hirdetésekre, a következőt teheti:
                  <ol type="a">
                    <li>a böngészőjében a sütibeállítások megfelelő módosításával letilthatja a harmadik féltől származó sütik használatát; </li>
                    <li>letilthatja le a konverziókövetési, vagyis a „www.googleadservices.com” domainről származó sütiket a böngészőben, a https://www.google.de/settings/ads oldalon – a beállítások a sütik eltávolítását követően szintén törlődnek; </li>
                    <li>az „About Ads” kampány részét képező, személyre szabott hirdetéseket a http://www.aboutads.info/choices oldalon tilthatja le – a beállítások a sütik eltávolítását követően szintén törlődnek; </li>
                    <li>a Firefox, Internet Explorer vagy Google Chrome böngészőkben akár végérvényesen is letilthatja a sütiket – a részletekért látogasson el a http://www.google.com/settings/ads/plugin. oldalra.  </li>
                  </ol>
                </Typography>
                <Typography className={classes.text}>
                  Ha többet szeretne megtudni a Google adatvédelmi irányelveiről, látogasson el a következő oldalra: https://policies.google.com/privacy.
                </Typography>
                <Typography className={classes.text} style={{ textDecoration: 'underline' }}>Az érintett jogai</Typography>
                <Typography className={classes.text}>Az érintett jogai és jogalapok viszonyát a következő táblázat mutatja be, hogy az érintett számára egyértelmű legyen, hogy az alkalmazott jogalap esetén milyen jogaival élhet.</Typography>
                <BasicTable
                  columns={lawTableColumns}
                  rows={lawTableRows}
                ></BasicTable>
                <Typography className={classes.subTitle2}>Hozzáféréshez való jog (GDPR 15. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és az adatkezelés körülményeivel kapcsolatos információkhoz hozzáférést kapjon. Ha személyes adatoknak harmadik országba vagy nemzetközi szervezet részére történő továbbítására kerül sor, az érintett jogosult arra, hogy tájékoztatást kapjon a továbbításra vonatkozóan a 46. cikk szerinti megfelelő garanciákról. Az Adatkezelő az adatkezelés tárgyát képező személyes adatok másolatát az érintett rendelkezésére bocsátja, ha azokat érintett kéri.</Typography>
                <Typography className={classes.subTitle2}>Hozzájárulás visszavonásának joga (GDPR 7. cikk)</Typography>
                <Typography className={classes.text}>Érintett jogosult arra, hogy a hozzájárulását bármikor visszavonja. A hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a visszavonás előtti adatkezelés jogszerűségét.</Typography>
                <Typography className={classes.subTitle2}>Helyesbítéshez való jog (GDPR 16. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat.</Typography>
                <Typography className={classes.subTitle2}>Tiltakozáshoz való jog (GDPR 21. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak a GDPR 6. cikk (1) bek. e) vagy f) pontján alapuló kezelése ellen.</Typography>
                <Typography className={classes.text}>Ebben az esetben az Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha bizonyítja, hogy az adatkezelést olyan jogos okok indokolják, amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és szabadságaival szemben.</Typography>
                <Typography className={classes.subTitle2}>Adatkezelés korlátozásához való jog (GDPR 18. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy kérésére az Adatkezelő korlátozza az adatkezelést, ha a GDPR-ban meghatározott feltételek bármelyike megvalósul, és ebben az esetben az Adatkezelő a tároláson kívül más műveletet az adattal ne végezzen.</Typography>
                <Typography className={classes.text}>Ha az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.</Typography>
                <Typography className={classes.subTitle2}>Törléshez (elfeledtetéshez) való jog (GDPR 17. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, ha az adatkezelésnek nincs célja, hozzájárulását visszavonta és nincsen egyéb jogalapja, tiltakozás esetén nincs elsőbbséget élvező jogszerű ok az adatkezelésre, vagy ha az adatokat eleve jogellenesen kezelték, továbbá az adatokat jogi kötelezettség teljesítéséhez törölni kell. Ha az Adatkezelő nyilvánosságra hozta a személyes adatot, és azt törölni köteles, az elérhető technológia és a megvalósítás költségeinek figyelembevételével megteszi az ésszerűen elvárható lépéseket – ideértve technikai intézkedéseket – annak érdekében, hogy tájékoztassa az adatokat kezelő adatkezelőket, hogy az érintett kérelmezte tőlük a szóban forgó személyes adatokra mutató linkek vagy e személyes adatok másolatának, illetve másodpéldányának törlését.</Typography>
                <Typography className={classes.subTitle2}>Adathordozhatósághoz való jog (GDPR 20. cikk)</Typography>
                <Typography className={classes.text}>Az érintett jogosult arra, hogy a rá vonatkozó, általa az Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes adatokat a rendelkezésére bocsátotta, ha jogszabályi feltételek (automatizált adatkezelés és hozzájárulás vagy megállapodás jogalap) fennállnak.</Typography>
                <Typography className={classes.subTitle2}>Érintett hol és hogyan kérhet részletes tájékoztatást az adatok kezeléséről, továbbításáról, valamint hol és hogyan élhet jogaival?</Typography>
                <Typography className={classes.text}>Adatkezelő felhívja az érintettek figyelmét, hogy az érintettek tájékoztatás kérésüket, hozzáférési jogukat, valamint egyéb jogaik gyakorlását Adatkezelő postai vagy e-mail-es elérhetőségre küldött nyilatkozattal tehetik meg. Az Adatkezelő a nyilatkozatot a beérkezéstől számított legrövidebb időn belül megvizsgálja és megválaszolja, valamint megteszi a szükséges lépéseket a kérelemben és a jogszabályokban foglaltak alapján.</Typography>
                <Typography className={classes.subTitle2}>A hatóság elérhetősége panasz (GDPR 77. cikk) esetén:</Typography>
                <Typography className={classes.text}>Nemzeti Adatvédelmi és Információszabadság Hatóság</Typography>
                <Typography className={classes.text}>Cím: 1055 Budapest, Falk Miksa utca 9-11.</Typography>
                <Typography className={classes.text}>Levelezési cím: 1363 Budapest, Pf. 9.</Typography>
                <Typography className={classes.text}>Telefon: +36 (1) 391-1400</Typography>
                <Typography className={classes.text}>Fax: +36 (1) 391-1410</Typography>
                <Typography className={classes.text}>www: <a href='https://www.naih.hu/' style={{ textDecoration: 'none', color: '#000' }}>www.naih.hu</a></Typography>
                <Typography className={classes.text}>e-mail: ugyfelszolgalat@naih.hu</Typography>
                <Typography className={classes.text} marginTop='50px'>Jogaival és a hatósághoz benyújtandó panasz részleteivel kapcsolatos további információért keresse fel az alábbi honlapot: <a href='http://naih.hu/panaszuegyintezes-rendje.html' style={{ textDecoration: 'none', color: '#000' }}>http://naih.hu/panaszuegyintezes-rendje.html</a></Typography>
                <Typography className={classes.text} marginTop='50px'>Az érintett a jogainak megsértése esetén a lakóhelye szerint illetékes bírósághoz is fordulhat és többek között sérelemdíjat követelhet.</Typography>
                <Typography className={classes.text} marginTop='50px'>A lakóhelye szerint illetékes bíróságot itt tudja megkeresni: <a href='https://birosag.hu/birosag-kereso' style={{ textDecoration: 'none', color: '#000' }}>https://birosag.hu/birosag-kereso</a></Typography>
                <Typography className={classes.text}>Előfordulhat, hogy a Webhely szolgáltatásai és funkciói a jövőben megváltoznak, ennek megfelelően az Adatkezelési tájékoztatót is módosíthatja az Adatkezelő. </Typography>
                <Typography className={classes.text}>Lezárva: v1, 2022. 04. 04. </Typography>
                <Typography marginBottom='20px' textAlign='right'>Huszlicska Gábor Dániel ügyvezető</Typography>
                {/* <Typography marginBottom='20px' textAlign='center'>AOC System Zrt.</Typography> */}
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default DataManagementInformation
