import React, { useEffect, useState } from "react";
import { AppBar, Box, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ListItemButton from '@material-ui/core/ListItemButton';
import {
  styled
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ChevronLeftRounded } from '@material-ui/icons/';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Logo from '../Logo';
import MenuDropdown from './Menu';
import Birds_of_Paradise_logo from "../../static/images/Birds_of_Paradise_logo.png"
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import LangaugeChip from "../LangaugeChip";
import LanguagePopOver from "./LanguagePopOver";

// TODO: styles
const useStyles = makeStyles(({
  main: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    textDecoration: 'none',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 20px',
    textDecoration: 'none',
    color: '#5e5e5e',
    cursor: 'pointer',
  },
  listItemMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 0px',
    textDecoration: 'none',
    color: '#5e5e5e',
    cursor: 'pointer',
  },
  listItemDropDown: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 0px',
    textDecoration: 'none',
    color: '#5e5e5e',
    cursor: 'pointer',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  divider: {
    width: '100%',
  },
  button: {
    padding: '10px 20px',
    borderRadius: 30
  },
  glass: {
    backgroundColor: 'rgba(255, 255, 255, .4)',
    backdropFilter: 'blur(20px)',
    zIndex: 3
  },
  // glassMobile: {
  //   backgroundColor: 'rgba(255, 255, 255, .75)',
  //   backdropFilter: 'blur(30px)',
  // },
  toolbar: {
    height: 80,
    transition: '0.25s',
  },
  small: {
    height: 70,
    transition: '0.25s',
    // lavender,
    // backgroundColor: 'rgba(149, 67, 108, .2)',
    // space cadet,
    // backgroundColor: 'rgba(52, 44, 93, .2)',
    // dark blue,
    // backgroundColor: 'rgba(31, 29, 66, .4)',
    // mine,
    backgroundColor: 'rgba(224, 200, 182, .3)',
    backdropFilter: 'blur(20px)',
  },
  displayLogo: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  displayLogoNone: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 1s, opacity 0.5s linear',
  },
  center: {
    // display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '50%',
  },
  // selectedListItem: {
  //   backgroundColor: 'red',
  //   '&selected': {
  //     backgroundColor: 'black'
  //   }
  // }
}));

const drawerWidth = 320;

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(10),
  }),
);

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


export default function Navigation(props) {
  // const matches = useMediaQuery('(min-width:1085px)');
  const matches = useMediaQuery('(min-width:1320px)');
  const { t, } = useTranslation();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const location = useLocation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleListItemClick = (event, index) => {
    if (index === selectedIndex) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(index);
    }
  };

  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedIndex(0);
  };

  const renderMenu = (text, href, children, to, index) => {
    if (children) {
      return <MenuDropdown key={index} items={children} button={text} className={classes.listItemDropDown} />
    }
    return <ScrollLink key={index} className={classes.listItem} activeClass="active" to={to} isDynamic={true} spy={true} smooth={true} offset={-80} duration={500}>
      <Link to={href} color="default" className={classes.listItem}>
        <Typography variant='subtitle2' style={{ color: location.pathname === href && '#c0a066' }}>
          {text}
        </Typography>
      </Link>
    </ScrollLink>
  }

  const navigationLinks = [
    { text: t('navigation.home'), href: '/', to: 'topHome' },
    { text: t('navigation.introduction'), href: '/introduction', to: 'introduction' },
    { text: t('navigation.discount'), href: '/discount', to: 'discount' },
    { text: t('navigation.timesheet'), href: '/timesheet', to: 'timesheet' },
    { text: t('navigation.instructors'), href: '/instructors', to: 'instructors' },
    { text: t('navigation.houseRule'), href: '/rules', to: 'rules' },
    { text: t('navigation.prices'), href: '/prices', to: 'prices' },
    { text: t('navigation.gallery'), href: '/gallery', to: 'gallery' },
    { text: t('navigation.contact'), href: '/contact', to: 'contact' },
    // {
    //   text: t('navigation.about'), href: '/about', children: [
    //     { text: t('navigation.aboutUs'), href: '/about' },
    //     { text: t('navigation.imprint'), href: '/imprint' },
    //   ]
    // }
  ]

  const navigationLinksMsize = [
    { text: t('navigation.home'), href: '/', to: 'topHome' },
    { text: t('navigation.introduction'), href: '/introduction', to: 'introduction' },
    { text: t('navigation.timesheet'), href: '/timesheet', to: 'timesheet' },
    { text: t('navigation.instructors'), href: '/instructors', to: 'instructors' },
    { text: t('navigation.houseRule'), href: '/rules', to: 'rules' },
    { text: t('navigation.prices'), href: '/prices', to: 'prices' },
    { text: t('navigation.gallery'), href: '/gallery', to: 'gallery' },
    // { text: t('navigation.contact'), href: '/contact', to: 'contact' },
    // {
    //   text: t('navigation.about'), href: '/about', children: [
    //     { text: t('navigation.aboutUs'), href: '/about' },
    //     { text: t('navigation.imprint'), href: '/imprint' },
    //   ]
    // }
  ]

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
    }
  }, []);

  return (
    <StyledBox sx={{ flexGrow: 1 }} id='topHome'>
      <CssBaseline />
      <StyledAppBar open={open} color="primary" elevation={0} className={classes.glass}>
        <Toolbar className={`${classes.toolbar} ${small ? classes.small : ""}`} style={{ background: !small && '#f6f2ef' }} id="top">
          <Hidden lgDown>
            <Grid item sx={{ flexGrow: 1 }}>
              <ScrollLink activeClass="active" to="topHome" spy={true} smooth={true} offset={-50} duration={500}>
                <Link to="/">
                  <Logo
                    height={50}
                    logo={Birds_of_Paradise_logo}
                    className={location.pathname === '/' ? small ? classes.displayLogo : classes.displayLogoNone : classes.displayLogo}
                  />
                </Link>
              </ScrollLink>
            </Grid>
            {matches
              ?
              <Grid item sx={{ flexGrow: 20 }}>
                <Grid container className={classes.main}>
                  {navigationLinks.map(({ text, href, children, to }, index) => renderMenu(text, href, children, to, index))}
                </Grid>
              </Grid>
              :
              <Grid item sx={{ flexGrow: 20 }}>
                <Grid container className={classes.main}>
                  {navigationLinksMsize.map(({ text, href, children, to }, index) => renderMenu(text, href, children, to, index))}
                </Grid>
              </Grid>}
            <Grid item sx={{ flexGrow: 1 }} >
              <Grid container justifyContent='flex-end'>
                <a className={classes.listItem} href="https://www.motibro.com/login?club=birds-of-paradise" target="_blank" rel="noreferrer" >
                  <Button size={matches ? "medium" : "small"} style={{ borderRadius: '18px', textTransform: 'none' }}>
                    {t('navigation.registration')}
                  </Button>
                </a>
                <a className={classes.listItem} color='default' href="https://www.motibro.com/visitors/reservation_widget?operator=birds-of-paradise" target="_blank" rel="noreferrer" >
                  <Button variant='contained' size={matches ? "medium" : "small"} style={{ background: '#95436c', borderRadius: '18px' }}>
                    {/* {t('navigation.login')} */}
                    {/* Órarend */}
                    {t("classes3")}
                  </Button>
                </a>
                {/* <LoginSingUPButton /> */}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <IconButton
                  color="default"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item sx={{ mr: lgDown ? -1.5 : 0 }}>
                <Link to="/" className={classes.center}>
                  <Logo
                    height={50}
                    logo={Birds_of_Paradise_logo}
                    className={location.pathname === '/' ? small ? classes.displayLogo : classes.displayLogoNone : classes.displayLogo}
                  />
                </Link>
              </Grid>
              <Grid item>
                {/* <Button className={classes.button} disableElevation variant='contained' color='secondary' href="https://aocsystem.eu/registration" target="_blank" >{t('navigation.registration')}</Button> */}
              </Grid>
            </Grid>
          </Hidden>
          {/* <LangaugeChip /> */}
          <LanguagePopOver />
        </Toolbar>
      </StyledAppBar>
      <Drawer
        classes={{ paper: classes.glassMobile }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        //variant="temporary"
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ position: 'absolute', right: 10 }}>
            {<ChevronLeftRounded />}
          </IconButton>
        </DrawerHeader>
        {/* <Divider />
        <List>
          <Link to={{ pathname: "https://my.aocsystem.com/login" }} target="_blank" className={classes.listItemMobile}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={<Typography variant="h3">{t('navigation.login')}</Typography>} />
            </ListItem>
          </Link>
          <Link to={{ pathname: "https://my.aocsystem.com/login" }} target="_blank" className={classes.listItemMobile}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={<Typography variant="h3">{t('navigation.registration')}</Typography>} />
            </ListItem>
          </Link>
        </List>
        <Divider /> */}
        <List>
          {navigationLinks.map(({ text, href, children, to }, index) => <div key={index}>
            {!children
              ? <ScrollLink key={index} activeClass="active" to={to} isDynamic={true} spy={true} smooth={true} offset={-80} duration={500}>
                <Link to={href} className={classes.listItemMobile}>

                  <ListItem button onClick={handleDrawerClose}>
                    <ListItemText primary={<Typography style={{ color: location.pathname === href && '#c0a066' }} variant="h3">{text}</Typography>} />
                  </ListItem>
                </Link>
              </ScrollLink>
              : <div key={index}>
                <ListItemButton
                  // className={classes.selectedListItem}
                  style={{ backgroundColor: 'white' }}
                  // onClick={handleClick}
                  onClick={(event) => handleListItemClick(event, index)}
                  selected={selectedIndex === index}>
                  <ListItemText primary={<Typography variant="h3">{text}</Typography>} />
                  {selectedIndex === index ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {children.map((child) => <Link key={child.text} className={classes.listItemMobile} to={child.disabled ? '#' : child.href}>
                      <ListItem disabled={child.disabled} button onClick={handleDrawerClose}>
                        {child.logo && <Logo src={child.logo} height={20} />}
                        <ListItemText primary={child.text} style={{ 'marginLeft': 10 }} />
                      </ListItem>
                    </Link>
                    )}
                  </List>
                </Collapse>
              </div>}
          </div>)}
        </List>
        <Divider />
        <List>
          <a href="https://www.motibro.com/login?club=birds-of-paradise" target="_blank" rel="noreferrer" className={classes.listItemMobile}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={<Typography variant="h3">{t('navigation.registration')}</Typography>} />
            </ListItem>
          </a>
          <a href="https://www.motibro.com/visitors/reservation_widget?operator=birds-of-paradise" target="_blank" rel="noreferrer" className={classes.listItemMobile}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={<Typography variant="h3">{t('navigation.login')}</Typography>} />
            </ListItem>
          </a>
        </List>
      </Drawer>
    </StyledBox >
  );
}

Navigation.propTypes = {
  className: PropTypes.string,
}