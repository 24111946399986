import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent"
import { useTranslation } from 'react-i18next';
import CookieSettingsModal from './CookieSettingsModal';
import { initGA } from '../../ga-utils'
import { useState } from 'react';

function Cookie() {
  const { t, } = useTranslation();
  const [isAnaliticsEnabled, setIsAnaliticsEnabled] = useState(true);

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      initGA('G-7DPX4DQQD6');
    }
  }, []);

  const handleAcceptCookie = () => {
    if (isAnaliticsEnabled) {
      initGA('G-7DPX4DQQD6');
    } else {
      Cookies.set('CookieConsent', false);
    }
  };

  return (
    <CookieConsent
      location="bottom"
      buttonStyle={{
        color: '#fff',
        background: '#95436c',
        padding: '10px 20px',
        borderRadius: 30
      }}
      buttonText={t('cookie.accept')}
      style={{
        backgroundColor: '#FFF',
        textAlign: 'left',
        color: 'black',
        boxShadow: '10px 12px 84px -3px rgba(0,0,0,0.24)',
      }}
      expires={365}
      onAccept={handleAcceptCookie}
    >
      <Typography variant='subtitle2'>
        {t('cookie.content-01')}
        <span>
          <CookieSettingsModal setIsAnaliticsEnabled={setIsAnaliticsEnabled} />
        </span>
        {t('cookie.content-02')}
      </Typography>
    </CookieConsent>
  )
}

export default Cookie;
