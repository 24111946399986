import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginTop: '80px',
    marginBottom: '50px',
    fontWeight: 'bold'
  },
  text: {
    textAlign: 'justify',
    marginBottom: '30px',
    marginTop: '30px'
  }
}))

function TermsAndConditions() {
  const classes = useStyles()
  const { t, } = useTranslation();


  document.title = t('Title.main') + " | " + t('Title.subTitle.termsAndConditions')

  return (
    <React.Fragment>
      <Paper>
        <Box style={{ height: '30vh', marginBottom: '5vh' }} backgroundColor='#212c38' display='flex' alignItems='center'>
          <Container>
            <Typography fontWeight='bold' variant='h3' textAlign='left' style={{ color: 'white' }}>Általános Szerződési Feltételek</Typography>
          </Container>
        </Box>
        <Box
          sx={{
            // background: 'linear-gradient(114deg, rgba(255,255,255,0.5) 0%, rgba(110,171,220,0.5) 52%, rgba(97,181,183,0.5) 64%, rgba(242,143,97,0.5) 100%)',
            // minHeight: '100vh',
            py: 10
          }}>
          <Container>
            <Grid
              container>
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}>
                <Typography className={classes.text}>
                  A jelen Általános Szerződési Feltételek (ÁSZF) célja, hogy a Birds of Paradise Kft. (a továbbiakban: Szolgáltató) által üzemeltetett Birds of Paradise jógastúdió jóga és pilates foglalkozásaira
                  és tanfolyamaira történő jelentkezési, lemondási, részvételi feltételeket, a szolgáltatásokra vonatkozó szerződési feltételeket rögzítse.
                </Typography>
                <Typography className={classes.title}>I. A Szolgáltató adatai</Typography>
                <ul style={{ listStyleType: 'none' }}>
                  <li>Cégnév: Birds of Paradise Kft. </li>
                  <li>Székhely: 1015 Budapest, Batthyány utca 48., 4. emelet </li>
                  <li>Adószám: 28846839-2-41 </li>
                  <li>Cégjegyzékszám: 01-09-376675</li>
                  <li style={{ marginTop: '10px' }}>A Szolgáltató Jógatermének címe: </li>
                  <li style={{ marginLeft: '144px' }}>1015 Budapest, Hattyú utca 14., Hattyúház, 4. emelet </li>
                </ul>
                <Typography className={classes.title}>II. Részvételi feltételek </Typography>
                <Typography className={classes.text}>
                  A jóga foglalkozásokra, tanfolyamokra való jelentkezés a MotiBro szoftver rendszeren keresztül, regisztrációt követően,
                  továbbá személyes egyeztetés útján lehetséges. Ön a MotiBro oldal használatával, az azon történő regisztrációval, az internetes felületen keresztül történő jelentkezéssel,
                  illetve személyes jelentkezésének rögzítésével kijelenti, hogy elolvasta, megértette és elfogadta a Szolgáltató jóga- és pilates foglalkozásaira és tanfolyamaira vonatkozó jelentkezési,
                  lemondási, részvételi, a szolgáltatásra vonatkozó szerződési feltételeket, valamint az adatkezelési szabályokat.
                </Typography>
                <Typography className={classes.text}>
                  Amennyiben bármely foglalkozás vagy tanfolyam a jelentkezők érdekkörén kívül eső okból nem kerül megtartásra, a már befizetett részvételi díj bármely szabad létszámmal rendelkező
                  foglalkozáson vagy tanfolyamon felhasználható. Amennyiben a jelentkező nem kíván az ily módon biztosított részvételi lehetőséggel élni, erre vonatkozó igénye közlésétől számított
                  30 napon belül a részvételi díját visszafizetjük.
                </Typography>
                <Typography>
                  A foglalkozásra vagy tanfolyamra jelentkezés menetének az online foglalási rendszeren keresztül az alábbiak szerint kell történnie:
                </Typography>
                <Typography style={{ marginLeft: '144px' }}>
                  A jelentkező kiválasztja a foglalkozást és annak időpontját, rákattint a „Foglalás/Bejelentkezés” gombra. Ezután megadja személyes adatait,
                </Typography>
                <Typography style={{ marginLeft: '144px' }}>
                  A jelentkezésről a rendszer egy automatikus e-mailben tájékoztatja, ezzel a megrendelés élővé vált.
                </Typography>
                <Typography className={classes.text}>
                  Emailben illetve személyesen történő foglalás esetén is szüksége van megadni a telefonszámát és email címét.
                </Typography>
                <Typography className={classes.text}>
                  A lefoglalt foglalkozást a helyszínen készpénzben, bankkártyával vagy banki átutalással, előre kell kifizetni.
                </Typography>
                <Typography className={classes.title}>
                  3. A jóga foglalkozások menete
                </Typography>
                <Typography className={classes.text}>
                  A foglalkozások 60, 75, illetve 90 perc hosszúak. Célszerű a kezdést megelőzően legalább 15 perccel a helyszínen megjelenni. A jelentkező késése esetén nincs módja kapcsolódni az adott foglalkozáshoz.
                  Késés esetén nincs lehetőségünk a foglalkozás időtartamának meghosszabbítására.
                </Typography>
                <Typography className={classes.text}>
                  A résztvevő a jógatanfolyamokon saját eszközt használhat, de a Szolgáltató díj ellenében biztosítja a szükséges eszközöket.
                  A foglalkozáshoz kényelmes, mozgást biztosító ruha szükséges. Lehetőség szerint 2 órával a foglalkozás megkezdése előtt már ne egyen.
                </Typography>
                <Typography className={classes.title}>
                  4. Kapcsolattartás
                </Typography>
                <Typography className={classes.text}>
                  A résztvevőkkel való kapcsolattartás az online regisztráció vagy a személyes jelentkezés során megadott e-mail címen és telefonszámon történik. A Szolgáltató nem felel a megadott telefonszám vagy e-mail cím elérhetőségéért, az e-mail szolgáltatás működéséért. A jelentkezővel való kapcsolatfelvétel ezen okból történő meghiúsulásából eredő károkért a Szolgáltató nem felel.
                </Typography>
                <Typography className={classes.title}>
                  5. Részvételi díjak
                </Typography>
                <Typography className={classes.text}>
                  A részvételi díj tartalmazza a szakképzett jóga- és pilates oktató által vezetett elméleti és gyakorlati tudásanyagot. Az aktuális árak a birdsofparadise.hu oldalon érhetők el. A részvételi díjként a bruttó árat tüntetjük fel.
                </Typography>
                <Typography className={classes.text}>
                  Bérlet vásárlása esetén a bérletnek lejárata van. A lejárat dátuma nem meghosszabbítható és lejárat miatt el nem használt foglalkozások díja vissza nem térítendő. A bérlet névre szól és nem átruházható.
                </Typography>
                <Typography className={classes.title}>
                  6. Fizetés módja
                </Typography>
                <Typography className={classes.text}>
                  <span style={{ fontWeight: 'bold' }}>a. Banki átutalás</span>
                  <Typography>
                    A jelentkező jogosult a kiválasztott foglalkozás vagy tanfolyam részvételi díját banki átutalással vagy bankkártyával megfizetni. Banki átutalás esetén a regisztráció a Szolgáltató alább megjelölt bankszámláján történő jóváírását követően aktiválódik. Ennek megfelelően célszerű az átutalást úgy kezdeményezni, hogy a részvételi díj jóváírására legkésőbb a kurzus időpontját megelőző munkanapon sor kerüljön.
                  </Typography>
                </Typography>
                <Typography className={classes.text}>A banki átutalás adatai:</Typography>
                <ul style={{ listStyleType: 'none' }}>
                  <li>Cégnév: Birds of Paradise Kft. </li>
                  <li>Bankszámlaszám: 11702012-21451528-00000000 </li>
                  <li>Bank neve: OTP Bank Nyrt. </li>
                </ul>
                <Typography className={classes.text}>
                  <span style={{ fontWeight: 'bold' }}>b. Bankkártyás fizetés</span>
                  <Typography>
                    A részvételi díj bankkártyával történő teljesítésére lehetőség van a foglalkozás vagy tanfolyam megkezdése előtt, érkezéskor a helyszínen.
                  </Typography>
                </Typography>
                <Typography className={classes.text}>
                  <span style={{ fontWeight: 'bold' }}>c. Készpénzes fizetés </span>
                  <Typography>
                    A részvételi díj készpénzes teljesítésére lehetőség van a foglalkozás vagy tanfolyam megkezdése előtt, érkezéskor a helyszínen.
                  </Typography>
                </Typography>
                <Typography className={classes.title}>
                  7. Részvételi szabályok és felelősségvállalás
                </Typography>
                <Typography className={classes.text}>
                  A jóga- és pilates foglalkozásokon és tanfolyamokon való részvétel lehetősége a jelen ÁSZF-ben foglaltak szerint bárki részére nyitva áll a következő megszorításokkal.
                  A foglalkozáson vagy tanfolyamon való részvételből a Szolgáltató jogosult kizárni azt, aki az alapvető higiéniai feltételeknek nem felel meg, azaz:
                  a jógatanfolyamon a jógaközpont által biztosított lehetőség ellenére a kötelező tisztálkodást megtagadja; egészségi állapota,
                  személyes higiénéje a foglalkozás többi résztvevőjének egészségét nyilvánvalóan veszélyezteti. Kizárható továbbá az,
                  aki a foglalkozást befogadó stúdióban kötelező, a foglalkozást megelőzően közölt biztonsági szabályokat szándékosan vagy ismételten megszegi;
                  magatartásával a foglalkozáson résztvevők testi épségét vagy szándékos, vagy súlyosan gondatlan magatartásával veszélyezteti; viselkedésével a társas együttműködés alapvető etikai szabályait áthágja,
                  a foglalkozás menetét szándékosan hátráltatja vagy megakadályozza. A Szolgáltató fenntartja a jogot, hogy amennyiben a résztvevőt a jógatanfolyamon való részvételből a fentiek miatt kizárta,
                  a további foglalkozásokra és tanfolyamokra való jelentkezés lehetőségét tőle előre megtagadja.
                </Typography>
                <Typography className={classes.text}>
                  A résztvevők a jógatermet, valamint annak felszerelési tárgyait, a rendelkezésre bocsátott eszközöket kizárólag a jelen szerződésben rögzített célokra és rendeltetésszerűen jogosultak
                  használni, és felelősek az azokban a magatartásukkal összefüggésben felmerült károkért.
                </Typography>
                <Typography className={classes.text}>
                  A résztvevő saját hibájából vagy a tanfolyamok más résztvevőjének hibájából bekövetkezett balesetekért a Szolgáltató felelősséget nem vállal.
                  A személyi sérüléssel járó balesetről valamennyi esetben a helyszínen jegyzőkönyvet veszünk fel, amelyet a jelenlévő oktató, valamint szükség esetén más,
                  a foglalkozáson részt vevő, jelenlévő személy az aláírásával hitelesít.
                </Typography>
                <Typography className={classes.text}>
                  A résztvevő elfogadja, hogy a jóga- és pilates foglalkozás nem helyettesíti a felmerülő szükséges orvosi vizsgálatot, diagnózist és kezelést.
                  Bármilyen fizikai vagy mentális kondíció esetén kikéri a kezelőorvos véleményét a jóga és/vagy pilates alkalmazásával kapcsolatban.
                  Elfogadja, hogy saját felelőssége az oktatót minden óra előtt informálni aktuális betegségéről és sérüléséről.
                  Követi az oktató instrukcióit, azoktól nem tér el, valamint figyeli a testének jelzéseit és mindent megtesz a sérülések elkerülése érdekében.
                </Typography>
                <Typography className={classes.title}>
                  8. Tanfolyam elmaradása, módosítása
                </Typography>
                <Typography className={classes.text}>
                  A Szolgáltató fenntartja a jogot arra, hogy a foglalkozások és tanfolyamok időpontját vagy előre meghirdetett tematikáját módosítsa.
                  A foglalkozás vagy tanfolyam esetleges elmaradásáról, az oktató vagy az időpont változásáról a Szolgáltató legalább 24 órával a foglalkozás előtt
                  a jelentkezés során megadott e-mail címre küldött levélben értesíti a jelentkezőket. Az elmaradt foglalkozásért vagy tanfolyamért
                  befizetett részvételi díj bármely későbbi időpontban meghirdetett foglalkozásra vagy tanfolyamra felhasználható.
                  Amennyiben a jelentkező nem kíván az ily módon biztosított részvételi lehetőséggel élni, erre vonatkozó igénye közlésétől számított 30 napon belül a részvételi díját visszafizetjük.
                  Az órarend változásának jógát fenntartjuk.
                </Typography>
                <Typography className={classes.title}>
                  9. Lemondási feltételek
                </Typography>
                <Typography className={classes.text}>
                  A jógatermi órákra a bejelentkezés az órakezdésig, lemondás az órakezdést megelőző 1 óráig lehetséges.
                  Ha a foglalás nem kerül lemondásra és a jelentkező nem jelenik meg az adott jógaórán, akkor az az alkalom levonásra kerül a bérletéből.
                  Ha a jelentkezőnek korlátlan bérlete van és két héten belül kétszer nem jön el a bejelentkezett időpontra és nem is mondja le, akkor 1 hétig az online órafoglalás számára nem lesz lehetséges.
                </Typography>
                <Typography className={classes.text}>
                  A jelentkezők a MotiBro felületén törölhetik foglalásukat.
                </Typography>
                <Typography className={classes.title}>
                  Workshopok / külső rendezvények
                </Typography>
                <Typography className={classes.text}>
                  Ezekre a foglalkozásokra személyesen a stúdióban, illetve a MotiBron keresztül lehet jelentkezni.
                  A részvételt 48 órával az esemény előttig le lehet mondani, ez esetben a befizetett összeg visszatérítésre kerül.
                  48 órán belüli lemondás, illetve az eseményen való meg nem jelenés esetén az összeg visszatérítésére nincs lehetőség.
                </Typography>
                <Typography className={classes.text}>
                  A jelentkezők személyesen, telefonon, e-mailen vagy a MotiBro felületén törölhetik foglalásukat.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default TermsAndConditions
